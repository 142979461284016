import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.FSM_WARNING_START_POINT]: {
        id: TranslationKey.FSM_WARNING_START_POINT,
        defaultMessage: ru[TranslationKey.FSM_WARNING_START_POINT],
    },
    [TranslationKey.FSM_STATE_ACTION]: {
        id: TranslationKey.FSM_STATE_ACTION,
        defaultMessage: ru[TranslationKey.FSM_STATE_ACTION],
    },
    [TranslationKey.FSM_STATE_CONDITION]: {
        id: TranslationKey.FSM_STATE_CONDITION,
        defaultMessage: ru[TranslationKey.FSM_STATE_CONDITION],
    },
    [TranslationKey.FSM_STATE_TITLE]: {
        id: TranslationKey.FSM_STATE_TITLE,
        defaultMessage: ru[TranslationKey.FSM_STATE_TITLE],
    },
});
