import { PayloadAction } from '@reduxjs/toolkit';

import { calculateBlockHeight } from '@repeat/constants';
import { usePortsCreator } from '@repeat/hooks';
import {
    ElemParams,
    ElemProps,
    IElementInitializationData,
    IWorkspaceState,
    LibraryTypes,
    Statuses,
    TSchemaElementWithParams,
    TSchemaHandle,
    TSchemaNode,
} from '@repeat/models';
import { TUploadedFmuFile } from '@repeat/services';

import { initialState } from '../schemaSlice';

export const ElementInitializationSlices = {
    resetElementInitialization: (state: IWorkspaceState) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                elementInitialization: {
                    ...initialState.elementInitialization,
                },
            },
        };
    },
    uploadElementSourcesFileRequest: (
        state: IWorkspaceState,
        action: PayloadAction<{ elementId: number; projectId: number; fileSizeBytes: number }>
    ) => ({
        ...state,
        schema: {
            ...state.schema,
            elementInitialization: {
                ...state.schema.elementInitialization,
                elementId: action.payload.elementId,
                projectId: action.payload.projectId,
                uploadElementSourcesFile: {
                    ...state.schema.elementInitialization.uploadElementSourcesFile,
                    fileSizeBytes: action.payload.fileSizeBytes,
                    status: Statuses.LOADING,
                },
            },
        },
    }),
    uploadElementSourcesFileProgress: (state: IWorkspaceState, action: PayloadAction<{ bytes: number }>) => ({
        ...state,
        schema: {
            ...state.schema,
            elementInitialization: {
                ...state.schema.elementInitialization,
                uploadElementSourcesFile: {
                    ...state.schema.elementInitialization.uploadElementSourcesFile,
                    uploadedBytes: action.payload.bytes,
                },
            },
        },
    }),
    uploadElementSourcesFileSuccess: (state: IWorkspaceState, action: PayloadAction<TUploadedFmuFile>) => ({
        ...state,
        schema: {
            ...state.schema,
            elementInitialization: {
                ...state.schema.elementInitialization,
                uuid: action.payload.uuid,
                originalName: action.payload.originalName,
                filesize: action.payload.size,
                uploadElementSourcesFile: {
                    ...state.schema.elementInitialization.uploadElementSourcesFile,
                    status: Statuses.SUCCEEDED,
                },
            },
        },
    }),
    uploadElementSourcesFileFailed: (state: IWorkspaceState, { payload }: PayloadAction<{ error: string }>) => ({
        ...state,
        schema: {
            ...state.schema,
            elementInitialization: {
                ...state.schema.elementInitialization,
                uploadElementSourcesFile: {
                    ...state.schema.elementInitialization.uploadElementSourcesFile,
                    status: Statuses.FAILED,
                    error: payload.error,
                },
            },
        },
    }),
    initializeElementByFileRequest: (state: IWorkspaceState) => ({
        ...state,
        schema: {
            ...state.schema,
            elementInitialization: {
                ...state.schema.elementInitialization,
                initializeElementByFile: {
                    ...state.schema.elementInitialization.initializeElementByFile,
                    status: Statuses.LOADING,
                },
            },
        },
    }),
    initializeElementByFileSuccess: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ data: IElementInitializationData }>
    ) => {
        return {
            ...state,
            schema: {
                ...state.schema,
                elementInitialization: {
                    ...state.schema.elementInitialization,
                    data: payload.data,
                    initializeElementByFile: {
                        ...state.schema.elementInitialization.initializeElementByFile,
                        status: Statuses.SUCCEEDED,
                    },
                },
            },
        };
    },
    initializeElementByFileFailed: (state: IWorkspaceState, { payload }: PayloadAction<{ error: string }>) => ({
        ...state,
        schema: {
            ...state.schema,
            elementInitialization: {
                ...state.schema.elementInitialization,
                initializeElementByFile: {
                    ...state.schema.elementInitialization.initializeElementByFile,
                    status: Statuses.FAILED,
                    error: payload.error,
                },
            },
        },
    }),
    initializeElementSuccess: (
        state: IWorkspaceState,
        { payload: { name, elemParams, elemProps, uuid } }: PayloadAction<IElementInitializationData>
    ) => {
        const selectedElement = state.schema.selectedItems.elements[0];
        const { elementId } = state.meta;
        const { elements } = state.schema.schemaItems;
        const libraryPortTypes = state.libraryPortTypes.items;
        const groups = state.schema.schemaItems.groups || [];

        const elementsWithParams = state.schema.elementsWithParams;

        const updateElements = (elements: TSchemaNode[]) => {
            return elements.map((el: TSchemaNode) => {
                if (selectedElement && el.data.id === selectedElement.data.id) {
                    const { ports } = usePortsCreator(elemParams, [LibraryTypes.AUTO], libraryPortTypes);

                    const newWidth = el.width;
                    const newHeight = calculateBlockHeight(ports);

                    return {
                        ...el,
                        width: newWidth,
                        height: newHeight,
                        data: {
                            ...el.data,
                            name,
                            elemParams,
                            availablePorts: [...ports],
                            uuid,
                            portsAmount: elemParams.length,
                            elemProps,
                        },
                    };
                }
                return el;
            });
        };

        if (elementId !== null) {
            const newGroups = groups.map((group) => {
                if (group.id.toString() === elementId) {
                    return { ...group, elements: updateElements(group.elements) };
                }
                return group;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, groups: newGroups },
                },
            };
        }

        const newElements = updateElements(elements);
        const newElementsWithParams = elementsWithParams.map((element: TSchemaElementWithParams) => {
            if (selectedElement && element.id === selectedElement.data.id) {
                return { ...element, elemParams };
            }
            return element;
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements: newElements },
                elementsWithParams: newElementsWithParams,
                selectedItems: {
                    ...state.schema.selectedItems,
                    elements: [{ ...selectedElement, data: { ...selectedElement.data, elemProps } }],
                },
            },
        };
    },

    initializeElementByTextFileSuccess: (
        state: IWorkspaceState,
        {
            payload: { userName, systemName, uuid, elemParams, availablePorts, inputPorts, outputPorts, height },
        }: PayloadAction<{
            userName: string;
            systemName: string;
            uuid: string;
            elemParams?: ElemParams[];
            availablePorts?: TSchemaHandle[];
            inputPorts?: number;
            outputPorts?: number;
            height?: number;
        }>
    ) => {
        const selectedElement = state.schema.selectedItems.elements[0];
        const { elements } = state.schema.schemaItems;
        const props: { [key: string]: string } = { userName, systemName };
        let updatedProps: ElemProps[] = [];

        const newElements = elements.map((el: TSchemaNode) => {
            if (selectedElement && el.data.id === selectedElement.data.id) {
                const elemProps = el.data.elemProps.map((prop) => {
                    if (props[prop.name]) {
                        const value = props[prop.name];
                        return { ...prop, value };
                    }
                    if (prop.name === 'Input_ports' && inputPorts) {
                        return { ...prop, value: inputPorts };
                    }
                    if (prop.name === 'Output_ports' && outputPorts) {
                        return { ...prop, value: outputPorts };
                    }
                    return prop;
                });
                updatedProps = elemProps;

                const ports = availablePorts ? availablePorts : el.data.availablePorts;
                const params = elemParams ? elemParams : el.data.elemParams;
                const name = userName.substring(0, userName.lastIndexOf('.'));
                return {
                    ...el,
                    height: height ? height : el.height,
                    data: {
                        ...el.data,
                        name,
                        uuid,
                        elemProps,
                        availablePorts: ports,
                        elemParams: params,
                    },
                };
            }
            return el;
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements: newElements },
                selectedItems: {
                    ...state.schema.selectedItems,
                    elements: [{ ...selectedElement, data: { ...selectedElement.data, elemProps: updatedProps } }],
                },
            },
        };
    },
};
