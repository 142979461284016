import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
    [TranslationKey.PROJECT_HISTORY_LOAD_VERSION]: {
        id: TranslationKey.PROJECT_HISTORY_LOAD_VERSION,
        defaultMessage: ru[TranslationKey.PROJECT_HISTORY_LOAD_VERSION],
    },
    [TranslationKey.PROJECT_HISTORY_PREVIEW_VERSION]: {
        id: TranslationKey.PROJECT_HISTORY_PREVIEW_VERSION,
        defaultMessage: ru[TranslationKey.PROJECT_HISTORY_PREVIEW_VERSION],
    },
    [TranslationKey.EMPTY_PROJECT_HISTORY]: {
        id: TranslationKey.EMPTY_PROJECT_HISTORY,
        defaultMessage: ru[TranslationKey.EMPTY_PROJECT_HISTORY],
    },
    [TranslationKey.EMPTY_COMMENT_PROJECT_HISTORY]: {
        id: TranslationKey.EMPTY_COMMENT_PROJECT_HISTORY,
        defaultMessage: ru[TranslationKey.EMPTY_COMMENT_PROJECT_HISTORY],
    },
    [TranslationKey.SAVE]: {
        id: TranslationKey.SAVE,
        defaultMessage: ru[TranslationKey.SAVE],
    },
    [TranslationKey.CANCEL]: {
        id: TranslationKey.CANCEL,
        defaultMessage: ru[TranslationKey.CANCEL],
    },
    [TranslationKey.ERROR_PROJECT_HISTORY]: {
        id: TranslationKey.ERROR_PROJECT_HISTORY,
        defaultMessage: ru[TranslationKey.ERROR_PROJECT_HISTORY],
    },
});
