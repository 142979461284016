import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
    [TranslationKey.GROUP_NEW]: {
        id: TranslationKey.GROUP_NEW,
        defaultMessage: ru[TranslationKey.GROUP_NEW],
    },
    [TranslationKey.GROUP_SAVE_AS_BLOCK]: {
        id: TranslationKey.GROUP_SAVE_AS_BLOCK,
        defaultMessage: ru[TranslationKey.GROUP_SAVE_AS_BLOCK],
    },
    [TranslationKey.USER_BLOCK_TRANSFORM_TO_GROUP]: {
        id: TranslationKey.USER_BLOCK_TRANSFORM_TO_GROUP,
        defaultMessage: ru[TranslationKey.USER_BLOCK_TRANSFORM_TO_GROUP],
    },
});
