import styled, { css } from 'styled-components';

import { useElementStyle } from '@repeat/common-hooks';
import { TLibraryType } from '@repeat/models';
import { pxToRem, uiColors } from '@repeat/ui-kit';

const INPUT_WIDTH = 255;

interface ISContextSearchProps {
    currentIconModeValue?: string | undefined;
    currentNodeLibrary?: TLibraryType | null;
    currentNodeSubLibrary?: string | null;
}

export const SContextSearchOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(128, 128, 128, 0.3);
    z-index: 100;
`;
export const SContextSearchWrapper = styled.div<{ top: number | null; left: number | null }>`
    position: relative;
    top: ${(props) => (props.top ? `${props.top}px` : `${INPUT_WIDTH}px`)};
    left: ${(props) => (props.left ? `${props.left}px` : '800px')};
    z-index: 1000;
    display: inline-block;
`;
export const SSuggestionItem = styled.div<ISContextSearchProps>`
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 0.5rem;

    img {
        ${({ currentIconModeValue, currentNodeLibrary, currentNodeSubLibrary }) => {
            if (currentIconModeValue === 'new') {
                const { styles } = useElementStyle(currentIconModeValue, currentNodeLibrary, currentNodeSubLibrary);
                return css`
                    ${styles};
                    width: 32px;
                    height: 32px;
                    margin: auto auto;
                    transform: scale(1.2);
                `;
            }
            return ``;
        }}
        width: 32px;
    }
`;
export const SSuggestionItemInfo = styled.div`
    display: grid;
    grid-template-rows: min-content min-content;
`;
export const SSuggestionItemTitle = styled.div`
    color: ${uiColors.black};
    font-size: ${pxToRem(12)};
`;
export const SSuggestionItemSubTitle = styled.div`
    color: ${uiColors.darkGrey};
    font-size: ${pxToRem(10)};
`;
