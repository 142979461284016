import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.CODE_EDITOR_NODE_NOT_FOUND]: {
        id: TranslationKey.CODE_EDITOR_NODE_NOT_FOUND,
        defaultMessage: ru[TranslationKey.CODE_EDITOR_NODE_NOT_FOUND],
    },
    [TranslationKey.CODE_EDITOR_PROPERTY_NOT_FOUND]: {
        id: TranslationKey.CODE_EDITOR_PROPERTY_NOT_FOUND,
        defaultMessage: ru[TranslationKey.CODE_EDITOR_PROPERTY_NOT_FOUND],
    },
    [TranslationKey.JYTHON_CODE_TAB_INIT]: {
        id: TranslationKey.JYTHON_CODE_TAB_INIT,
        defaultMessage: ru[TranslationKey.JYTHON_CODE_TAB_INIT],
    },
    [TranslationKey.JYTHON_CODE_TAB_BODY]: {
        id: TranslationKey.JYTHON_CODE_TAB_BODY,
        defaultMessage: ru[TranslationKey.JYTHON_CODE_TAB_BODY],
    },
    [TranslationKey.JYTHON_CODE_TAB_UNINIT]: {
        id: TranslationKey.JYTHON_CODE_TAB_UNINIT,
        defaultMessage: ru[TranslationKey.JYTHON_CODE_TAB_UNINIT],
    },
});
