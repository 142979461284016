import { ChangeEvent, forwardRef } from 'react';

import { SInputError, SInputWrapper, SLabel, STextAreaField } from './SInputField';

interface ITextAreaField {
    value?: string;
    defaultValue?: string | number;
    placeholder?: string;
    maxLength?: number;
    errorText?: string | boolean;
    error?: boolean;
    required?: boolean;
    disabled?: boolean;
    label?: string;
    id?: string;
    height?: number;
    resize?: 'both' | 'horizontal' | 'vertical' | 'inherit';
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    onDoubleClick?: (event: MouseEvent) => void;
    onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextAreaField = forwardRef<HTMLTextAreaElement, ITextAreaField>((props, ref) => {
    const { resize, height, error, errorText, label, id, ...rest } = props;
    const { onDoubleClick, ...SProps } = rest;

    return (
        <SInputWrapper error={Boolean(error)}>
            {label && (
                <SLabel error={Boolean(error)} htmlFor={id}>
                    {label}
                </SLabel>
            )}
            <STextAreaField
                {...SProps}
                ref={ref}
                id={id}
                resize={resize}
                height={height}
                error={error}
                onBlur={(event: any) => {
                    if (rest.onBlur) {
                        rest.onBlur(event);
                    }
                }}
            />
            {error && typeof errorText === 'string' && <SInputError>{errorText}</SInputError>}
            {error &&
                Array.isArray(errorText) &&
                errorText.map(
                    (item, index) => item && <SInputError key={`error-text--${id}-${index}`}>{item}</SInputError>
                )}
        </SInputWrapper>
    );
});
