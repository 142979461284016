import { useEffect, useState, RefObject } from 'react';

export const useCanvasMousePosition = (wrapper: RefObject<HTMLDivElement>, inst: any) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const setMousePosition = (event: MouseEvent) => {
        const reactFlowBounds = wrapper.current && wrapper.current.getBoundingClientRect();
        let newPosition = position;
        if (inst && reactFlowBounds) {
            newPosition = inst.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });
            setPosition(newPosition);
            localStorage.setItem('mousePosition', JSON.stringify(newPosition));
        }
    };

    useEffect(() => {
        const canvas = document.querySelector('[data-name="canvasContainer"]');
        if (canvas) {
            window.addEventListener('mousemove', setMousePosition);
        }
        return () => window.removeEventListener('mousemove', setMousePosition);
    }, []);

    return {
        position,
    };
};
