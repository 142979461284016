import { AxiosResponse } from 'axios';
import { TProxyMap } from 'libs/models/src/lib/element';

import {
    ElemParams,
    ElemProps,
    IChartList,
    IProjectInfo,
    IProjectSettingsOptions,
    ISchemaGoToMap,
    Project,
    TLibraryType,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaNode,
    TSolverType,
    TTask,
} from '@repeat/models';
import { TOnMovePayload } from '@repeat/ui-kit';

import FileDownloadService, { IDownloadFileParams } from './FileDownloadService';
import UploadService from './UploadService';
import { $api } from './http';

type ReturnProjectsContract = Array<Project>;
interface IGetProjects {
    (signal?: AbortSignal): Promise<AxiosResponse<ReturnProjectsContract>>;
}

type ReturnRenameProjectContract = {
    result: string;
};
type TRenameProjectPayload = {
    projectId: number;
    projectName: string;
};
interface IRenameProject {
    (payload: TRenameProjectPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnRenameProjectContract>>;
}

type ReturnDeleteProjectContract = {
    result: string;
};
type TDeleteProjectPayload = {
    projectId: number;
};
type TDeleteProjectsPayload = {
    projectIds: number[];
};
interface IDeleteProject {
    (payload: TDeleteProjectPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnDeleteProjectContract>>;
}
interface IDeleteProjects {
    (payload: TDeleteProjectsPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnDeleteProjectContract>>;
}

type ReturnCreateProjectContract = {
    projectId: string;
    projectName: string;
};
type TCreateProjectPayload = {
    folderId: number;
    projectName: string;
    libraryType: TLibraryType;
    solverType: TSolverType;
};
interface ICreateProject {
    (payload: TCreateProjectPayload & { folderId: number }, signal?: AbortSignal): Promise<
        AxiosResponse<ReturnCreateProjectContract>
    >;
}

type ReturnLoadProjectContract = IProjectInfo;
type ReturnLoadProjectsContract = IProjectInfo[];

interface ILoadProject {
    (id: number, signal?: AbortSignal): Promise<AxiosResponse<ReturnLoadProjectContract>>;
}

interface ILoadProjects {
    (ids: string[], signal?: AbortSignal): Promise<AxiosResponse<ReturnLoadProjectsContract>>;
}

interface IExportProject {
    (id: number, params?: IDownloadFileParams): void;
}

type TUploadProjectFilePayload = {
    file: File;
    onChunkUploaded: (uploadedBytes: number) => void;
    onUploaded: (project: TImportedProject) => void;
};

export type TImportedProject = {
    projectId: number;
    projectName: string;
};

interface IImportProjectFile {
    (payload: TUploadProjectFilePayload): void;
}

export default class ProjectsService {
    // получение всех проектов пользователя
    static getProjects: IGetProjects = async (signal?: AbortSignal) => {
        return await $api.get('/api/v1/project/getAll', { signal });
    };

    static getDemoProjects: IGetProjects = async (signal?: AbortSignal) => {
        return await $api.get('/api/v1/examples', { signal });
    };

    static setToDemoProjects = async (payload: { projectId: number }, signal?: AbortSignal) => {
        return await $api.post('/api/v1/examples', {}, { headers: { projectId: payload.projectId }, signal });
    };

    static removeFromDemoProjects = async (payload: { exampleId: number }, signal?: AbortSignal) => {
        return await $api.delete('/api/v1/examples', { headers: { ...payload }, signal });
    };

    static getFavProjects = async (signal?: AbortSignal) => {
        return await $api.get('/api/v1/folders/getFavorites', { signal });
    };

    static addFavProject = async (payload: { projectId: number; status: boolean }, signal?: AbortSignal) => {
        return await $api.patch('/api/v1/folders/markFavorites', { ...payload, signal });
    };

    static addFavProjects = async (payload: { projects: number[]; status: boolean }, signal?: AbortSignal) => {
        return await $api.post('/api/v1/folders/markMultipleFavorite', { ...payload, signal });
    };

    // создание нового проекта
    static createProject: ICreateProject = async (payload: TCreateProjectPayload, signal?: AbortSignal) => {
        return await $api.post(`/api/v1/project/create?folderId=${payload.folderId}`, { ...payload, signal });
    };

    static renameProject: IRenameProject = async (payload: TRenameProjectPayload, signal?: AbortSignal) => {
        return await $api.put(
            '/api/v1/project/rename',
            { ...payload },
            { headers: { projectId: payload.projectId }, signal }
        );
    };

    // удаление проекта
    static deleteProject: IDeleteProject = async (payload: TDeleteProjectPayload, signal?: AbortSignal) => {
        return await $api.delete('/api/v1/project/delete', { headers: { projectId: payload.projectId }, signal });
    };

    static deleteProjects: IDeleteProjects = async (payload: { projectIds: number[] }, signal?: AbortSignal) => {
        return await $api.delete('/api/v1/project/multipleDelete', {
            data: { projectIds: payload.projectIds },
            signal,
        });
    };

    static saveProjectPreview = async (payload: { projectId: number; dataUrl: string }, signal?: AbortSignal) => {
        return await $api.post(
            '/api/v1/project/savePreview',
            { preview: payload.dataUrl },
            { headers: { projectId: payload.projectId }, signal }
        );
    };

    // загрузка проекта
    static loadProject: ILoadProject = async (id: number, signal?: AbortSignal) => {
        return await $api.get(`/api/v1/project/get`, { headers: { projectId: id }, signal });
    };

    static loadDemoProject: ILoadProject = async (id: number, signal?: AbortSignal) => {
        return await $api.get(`/api/v1/examples/open`, { headers: { exampleId: id }, signal });
    };
    static loadProjects: ILoadProjects = async (ids: string[], signal?: AbortSignal) => {
        return await $api.get(`/api/v1/projects/list?id=${ids.join('&id=')}`, { signal });
    };

    // сохранение проекта
    static async saveProject(
        elements: TSchemaNode[],
        wires: TSchemaConnection[],
        groups: TSchemaGroup[],
        userBlocks: { [blockId: string]: number },
        hash: string,
        id: number,
        header: TTask & { version?: string },
        libraryType: string,
        solverType: string,
        version: string | null,
        externalInfo: { properties: ElemProps[]; parameters: ElemParams[] } | null,
        proxyMap: TProxyMap | null,
        goToMap: ISchemaGoToMap | null,
        meta?: IProjectSettingsOptions & {
            charts: IChartList;
        },
        signal?: AbortSignal
    ) {
        if (version) {
            header = {
                ...header,
                ...(meta && { ...meta }),
                version,
            };
        }

        if (hash) {
            header = { ...header, hash };
        }

        return await $api.put(
            '/api/v1/project/save',
            {
                data: { elements: [...elements], wires, externalInfo, proxyMap, goToMap, groups, userBlocks },
                projectId: Number(id),
                header,
                libraryType,
                solverType,
            },
            { headers: { projectId: id }, signal }
        );
    }
    static async saveNewProject(project: Omit<IProjectInfo, 'id'>) {
        return $api.post('/api/v1/project/save', project);
    }
    static async duplicateProject(projectId: number, signal?: AbortSignal) {
        return $api.post('/api/v1/project/duplicate', {}, { headers: { projectId }, signal });
    }

    static async duplicateDemoProject(exampleId: number, signal?: AbortSignal) {
        return $api.get('/api/v1/examples/get', { headers: { exampleId }, signal });
    }

    static exportProject: IExportProject = async (id: number, downloadParams?: IDownloadFileParams) => {
        const method = 'get';
        const config = {
            headers: {
                projectId: id,
            },
        };

        const downloadFileParams = downloadParams || {};

        await FileDownloadService.downloadFile('/api/v1/project/export', downloadFileParams, method, config);
    };

    static importProject: IImportProjectFile = async (payload: TUploadProjectFilePayload) => {
        const headers = {};
        const chunkSize = 1048576; // 1Mb

        const uploadService = new UploadService('/api/v1/project/import', payload.file, headers, chunkSize);
        await uploadService.uploadFile<TImportedProject>(payload.onChunkUploaded, payload.onUploaded);
    };

    static getFilesAndFolders = async (payload: { folderId: number }, signal?: AbortSignal) => {
        return $api.get('/api/v1/folders', { params: { folderId: payload.folderId }, signal });
    };

    static getDemoFilesAndFolders = async (payload: { folderId: number }, signal?: AbortSignal) => {
        return $api.get('/api/v1/examples/folders', { params: { folderId: payload.folderId }, signal });
    };

    static createFolder = async (payload: { folderId: number; name: string }, signal?: AbortSignal) => {
        return $api.post('/api/v1/folders', { name: payload.name, ancestor: payload.folderId }, { signal });
    };

    static createDemoFolder = async (payload: { folderId: number; name: string }, signal?: AbortSignal) => {
        return $api.post('/api/v1/examples/folders', { name: payload.name, ancestor: payload.folderId }, { signal });
    };

    static editFolder = async (payload: { folderId: number; name: string }, signal?: AbortSignal) => {
        return $api.patch('/api/v1/folders', { name: payload.name, folderId: payload.folderId }, { signal });
    };

    static editDemoFolder = async (payload: { folderId: number; name: string }, signal?: AbortSignal) => {
        return $api.patch('/api/v1/examples/folders', { name: payload.name, folderId: payload.folderId }, { signal });
    };

    static deleteFolder = async (payload: { folderIds: number[]; projectIds: number[] }, signal?: AbortSignal) => {
        return $api.delete('/api/v1/folders/multipleDelete', {
            data: { folderIds: payload.folderIds, projectIds: payload.projectIds },
            signal,
        });
    };

    static deleteDemoFolder = async (payload: { folderIds: number[]; exampleIds: number[] }, signal?: AbortSignal) => {
        return $api.delete('/api/v1/examples', {
            data: { folders: payload.folderIds, examples: payload.exampleIds },
            signal,
        });
    };

    static moveFileAndFolder = async (payload: TOnMovePayload, signal?: AbortSignal) => {
        return $api.patch('/api/v1/folders/packageMove', { ...payload }, { signal });
    };

    static moveDemoFileAndFolder = async (payload: TOnMovePayload, signal?: AbortSignal) => {
        return $api.patch('/api/v1/examples/folders/move', { ...payload }, { signal });
    };

    static getProjectVersions = async (
        payload: { projectId: number; offset?: number; count?: number },
        signal?: AbortSignal
    ) => {
        return $api.get('/api/v1/project/versions', {
            params: { pageIndex: payload?.offset || 1, pageSize: payload?.count || 10 },
            headers: { projectId: payload.projectId },
            signal,
        });
    };

    static changeProjectVersionsComment = async (
        payload: { projectId: number; versionId: number; comment: string },
        signal?: AbortSignal
    ) => {
        return $api.put(
            '/api/v1/project/versions',
            {
                comment: payload.comment,
            },
            {
                params: { version: payload.versionId },
                headers: { projectId: payload.projectId },
                signal,
            }
        );
    };

    static setProjectVersion = async (payload: { projectId: number; versionId: number }, signal?: AbortSignal) => {
        return $api.post(
            '/api/v1/project/versions',
            {},
            {
                params: { version: payload.versionId },
                headers: { projectId: payload.projectId },
                signal,
            }
        );
    };

    static viewProjectVersion = async (payload: { projectId: number; versionId: number }, signal?: AbortSignal) => {
        return $api.get('/api/v1/project/versions', {
            params: { version: payload.versionId },
            headers: { projectId: payload.projectId },
            signal,
        });
    };
}
