import { FC, memo, useEffect, useRef, useState } from 'react';
import Moveable, { OnResize, OnResizeEnd, OnRotate, OnRotateEnd } from 'react-moveable';
import { NodeToolbar, Position } from 'reactflow';

import { CANVAS, libraryElementImageSrc } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { TElement, TSchemaNode, TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { appUserSelectors, elementsSelectors, workspaceActions, workspaceSelectors } from '@repeat/store';
import { IconButton, uiColors } from '@repeat/ui-kit';

import { useReadOnlyMode } from '../../../../../hooks/useReadOnlyMode';
import { useInitNodeViewData } from '../../hooks/useInitNodeViewData';
import { invert, setAngle } from '../Element/Element';
import { SImageNodeButton, SImageNodeWrapper, SImageWrapper } from '../SComponents';

const convertFileToDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

export const ImageElement: FC<{ id: string; data: TElement; selected: boolean }> = memo(({ id, data, selected }) => {
    const dispatch = useAppDispatch();

    const currentNodeMain = useAppSelector(elementsSelectors.getElementById(id)) as TSchemaNode;
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const prevWorkspaceMode = useAppSelector((state) => state.workspace.meta.previousMode);
    const { elements: selectedElements } = useAppSelector(elementsSelectors.getSelectedElements);
    const currentIconModeValue = useAppSelector(appUserSelectors.currentUserIconMode)!;
    const currentNodeSubmodel = useAppSelector(workspaceSelectors.getSubmodelElementById(id)) as TSchemaNode;
    const currentNode = workspaceMode === WorkspaceModes.SUBMODEL ? currentNodeSubmodel : currentNodeMain;

    const { isReadOnlyMode } = useReadOnlyMode(workspaceMode, prevWorkspaceMode as TWorkspaceMode);

    const [changedValues, setChangedValues] = useState<{
        width: number;
        height: number;
        rotated: boolean;
        rotation: number;
    }>({
        width: currentNode?.width ? currentNode.width : 0,
        height: currentNode?.height ? currentNode.height : 0,
        rotated: currentNode?.rotation ? !!currentNode.rotation : false,
        rotation: currentNode?.rotation ? currentNode.rotation : 0,
    });

    const elementImageRef = useRef<HTMLImageElement | null>(null);
    const moveableRef = useRef<Moveable | null>(null);
    const imageElementRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const { view, solver: solverType, library, picId } = data;

    const [size, setSize] = useState({
        width: currentNode?.width ? currentNode.width : CANVAS.GRID_STEP * 10,
        height: currentNode?.height ? currentNode.height : CANVAS.GRID_STEP * 4,
    });

    const elementImage = libraryElementImageSrc(`/assets`, solverType, library, `${picId}`, currentIconModeValue);
    const [image, setImage] = useState<File | null>(null);
    const [imageURL, setImageURL] = useState<string | null>(null);

    const { isInitialized } = useInitNodeViewData(currentNode, changedValues);

    useEffect(() => {
        if (image && image instanceof File) {
            convertFileToDataURL(image).then((dataURL) => {
                setImageURL(dataURL);
                dispatch(workspaceActions.changeElementCustomImage({ id: data.id, image: dataURL }));
            });
        }
    }, [image]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImage(file);
        }
    };

    const handleInputClick = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.click();
        }
    };

    if (!isInitialized || !currentNode) {
        return null;
    }

    return (
        <SImageNodeWrapper
            ref={imageElementRef}
            width={size.width}
            height={size.height}
            isOutlined={selected}
            iconType={currentIconModeValue}
        >
            <SImageWrapper
                rotation={view.isImageRotatable ? 0 : invert(currentNode.rotation)}
                ref={elementImageRef}
                width={currentNode.width}
                height={currentNode.height}
            >
                <img src={imageURL || currentNode?.customImage || elementImage} alt='' />
                {isReadOnlyMode && (
                    <NodeToolbar isVisible={selected} align={'start'} offset={8} position={Position.Right}>
                        <SImageNodeButton>
                            <input
                                ref={inputRef}
                                style={{ display: 'none' }}
                                id='file'
                                type='file'
                                accept='image/*'
                                onChange={handleImageChange}
                            />
                            <IconButton fill={uiColors.darkGrey} noHover onClick={handleInputClick} name={'plus'} />
                        </SImageNodeButton>
                    </NodeToolbar>
                )}
            </SImageWrapper>
            {view &&
                selected &&
                selectedElements.length === 1 &&
                workspaceMode !== WorkspaceModes.DEMO &&
                workspaceMode !== WorkspaceModes.PREVIEW &&
                workspaceMode !== WorkspaceModes.SUBMODEL && (
                    <Moveable
                        ref={moveableRef}
                        persistData={{ rotation: invert(changedValues.rotation) }}
                        edge={true}
                        origin={true}
                        throttleRotate={90}
                        throttleResize={CANVAS.GRID_STEP}
                        target={imageElementRef}
                        rotatable={view.isRotatable || false}
                        resizable={view.isResizable || false}
                        draggable={true}
                        rotationPosition={'top'}
                        onRotate={(e: OnRotate) => {
                            e.target.style.transform = e.drag.transform;
                            setChangedValues({
                                ...changedValues,
                                rotated: true,
                                rotation: setAngle(e.absoluteRotation),
                            });
                        }}
                        onRotateEnd={(event: OnRotateEnd) => {
                            const { lastEvent } = event;
                            if (lastEvent && lastEvent?.rotate !== undefined) {
                                const { rotate } = lastEvent;
                                dispatch(
                                    workspaceActions.changeElementRotation({ id: data.id, rotation: setAngle(rotate) })
                                );
                            }
                        }}
                        onResize={({ target, width, height, delta }: OnResize) => {
                            const dataWidth = data.view.minWidth;
                            const dataHeight = data.view.minHeight;

                            if (dataWidth && width < dataWidth) {
                                return false;
                            }

                            if (dataHeight && height < dataHeight) {
                                return false;
                            }

                            if (width >= CANVAS.ELEMENT_MIN_WIDTH) {
                                delta[0] && (target!.style.width = `${width}px`);
                            }
                            if (height >= CANVAS.ELEMENT_MIN_HEIGHT) {
                                delta[1] && (target!.style.height = `${height}px`);
                            }
                            setChangedValues({ ...changedValues, width, height });
                            return;
                        }}
                        onResizeEnd={(event: OnResizeEnd) => {
                            const { lastEvent } = event;
                            if (lastEvent?.width && lastEvent?.height) {
                                const { width, height } = lastEvent;
                                dispatch(workspaceActions.changeElementSize({ id: data.id, size: { width, height } }));
                            }
                        }}
                    />
                )}
        </SImageNodeWrapper>
    );
});
