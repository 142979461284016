import { memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Moveable, { OnResize } from 'react-moveable';
import { NodeToolbar, Position, useStore } from 'reactflow';

import { CANVAS, getUsedTitles } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ElemProps, TElement, TSchemaNode, WorkspaceModes } from '@repeat/models';
import { workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton } from '@repeat/ui-kit';

import { messages } from '../FSMTranslations';
import {
    SNodeActionWrapper,
    SNodeTitleWrapper,
    SFSMStateElementWrapper,
    FSMStateElementBody,
    FSMStateCustomBody,
    SFSMHandle,
    FSMStateToolbarIcons,
} from '../SFSMStyles';

interface IFSMStateElement {
    id: string;
    data: TElement;
    selected: boolean;
}

const connectionNodeIdSelector = (state: any) => state.connectionNodeId;

const getValues = (currentNode: TSchemaNode) => {
    const initialValues = {};
    if (currentNode) {
        currentNode.data.elemProps.forEach((prop: ElemProps) => {
            if (prop.name === 'title') {
                Object.assign(initialValues, { title: prop.value.toString() });
            }
            if (prop.name === 'action') {
                Object.assign(initialValues, { action: prop.value.toString() });
            }
        });
    }
    return initialValues;
};

export const FSMStateElement = memo((props: IFSMStateElement) => {
    const { id, selected } = props;

    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();

    const connectionNodeId = useStore(connectionNodeIdSelector);

    const currentSubModelItems = useAppSelector(workspaceSelectors.currentSubmodelItems);
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const currentNode = useAppSelector(workspaceSelectors.getSubmodelElementById(id)) as TSchemaNode;

    const [values, setValue] = useState<{ title?: string; action?: string }>(getValues(currentNode));
    const [titleError, setTitleError] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [size, setSize] = useState({
        width: currentNode?.width || CANVAS.GRID_STEP * 10,
        height: currentNode?.height || CANVAS.GRID_STEP * 4,
    });

    const FSMStateElementRef = useRef(null);
    const FSMStateInput = useRef<HTMLInputElement>(null);
    const FSMStateTextarea = useRef<HTMLTextAreaElement>(null);

    const isConnecting = !!connectionNodeId;
    const isTarget = connectionNodeId && connectionNodeId !== id;

    const handleValidateUniqueTitle = (value: string) => {
        const usedTitles = new Set<string>();

        currentSubModelItems?.elements.forEach((block) => {
            const titleProp = block.data.elemProps.find((prop) => prop.name === 'title');
            if (titleProp) {
                usedTitles.add(titleProp.value as string);
            }
        });

        if (usedTitles.has(value)) {
            setTitleError(true);
        } else {
            setTitleError(false);
        }

        return usedTitles.has(value);
    };

    const handleEditMode = async (event: React.MouseEvent) => {
        await setEditMode((state) => !state);
        if (FSMStateInput && FSMStateInput.current) {
            FSMStateInput.current.focus();
        }
    };

    let debounceTimeout: NodeJS.Timeout | null = null;
    const handleOnChange = async (event: React.ChangeEvent) => {
        const target = event.target as HTMLInputElement | HTMLTextAreaElement;
        setValue({ ...values, [target.name]: target.value });

        handleValidateUniqueTitle(target.value);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        debounceTimeout = setTimeout(() => {
            handleSave(target.value, target.name);
        }, 1000);
    };

    const handleSave = (value: string, type: string) => {
        const newValues = { [type]: value };
        dispatch(workspaceActions.setElementPropertiesValues({ id: currentNode.data.id, elemProps: newValues }));
    };

    useEffect(() => {
        const currentTitle = values.title;
        const hasDuplicate = currentSubModelItems?.elements.some((block) => {
            const titleProp = block.data.elemProps.find((prop) => prop.name === 'title');
            return titleProp && titleProp.value === currentTitle && block.data.id.toString() !== id;
        });

        setTitleError(Boolean(hasDuplicate));
    }, [currentSubModelItems, values.title, id]);

    return (
        <SFSMStateElementWrapper
            width={size.width}
            height={size.height}
            isOutlined={selected}
            ref={FSMStateElementRef}
            onDoubleClick={handleEditMode}
        >
            <NodeToolbar isVisible={true} align={'start'} position={Position.Right} offset={12}>
                <FSMStateToolbarIcons>
                    {editMode && (
                        <IconButton fill={'var(--ui-icons)'} noHover name={'done'} onClick={() => setEditMode(false)} />
                    )}
                    {!editMode && (
                        <IconButton fill={'var(--ui-icons)'} noHover name={'edit'} onClick={() => setEditMode(true)} />
                    )}
                </FSMStateToolbarIcons>
            </NodeToolbar>
            <FSMStateElementBody className={'drag-handle__custom'}>
                <FSMStateCustomBody>
                    <SNodeTitleWrapper isEditMode={false} isError={titleError}>
                        <input
                            name={'title'}
                            ref={FSMStateInput}
                            value={values.title}
                            type='text'
                            placeholder={formatMessage(messages[TranslationKey.FSM_STATE_TITLE])}
                            disabled={!editMode}
                            onChange={handleOnChange}
                        />
                    </SNodeTitleWrapper>
                    <SNodeActionWrapper isEditMode={false}>
                        <textarea
                            name={'action'}
                            ref={FSMStateTextarea}
                            value={values.action}
                            placeholder={formatMessage(messages[TranslationKey.FSM_STATE_ACTION])}
                            disabled={!editMode}
                            onChange={handleOnChange}
                        />
                    </SNodeActionWrapper>
                </FSMStateCustomBody>
                {!isConnecting && !editMode && (
                    <SFSMHandle
                        className='customHandle'
                        position={Position.Right}
                        type='source'
                        style={{ zIndex: 1 }}
                    />
                )}

                {!editMode && <SFSMHandle className='customHandle' position={Position.Left} type='target' />}
            </FSMStateElementBody>

            {selected && workspaceMode !== WorkspaceModes.SUBMODEL && (
                <Moveable
                    edge={true}
                    throttleResize={CANVAS.GRID_STEP}
                    target={FSMStateElementRef}
                    resizable={true}
                    onResize={({ target, width, height, delta }: OnResize) => {
                        if (width >= CANVAS.GRID_STEP * 10) {
                            delta[0] && (target!.style.width = `${width}px`);
                        }
                        if (height >= CANVAS.GRID_STEP * 4) {
                            delta[1] && (target!.style.height = `${height}px`);
                        }
                    }}
                    onResizeEnd={(event) => {
                        if (event && event.lastEvent) {
                            const size = { width: event.lastEvent.width, height: event.lastEvent.height };
                            dispatch(workspaceActions.changeElementSize({ id: currentNode.data.id, size }));
                        }
                    }}
                />
            )}
        </SFSMStateElementWrapper>
    );
});
