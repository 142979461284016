import React, { useState, FC, useCallback } from 'react';
import { Tabs as ReactTabs, Tab as ReactTab, TabList as ReactTabList, TabPanel as ReactTabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { uiColors } from '@repeat/ui-kit';

import { SIconTab, STabsWrapper, STabName } from './STabs';

export type TTabHeader = {
    name: string;
    title: string;
    onSelect?: (e: any) => void;
};

export interface ITabs {
    tabHeader: TTabHeader[];
    tabContent: React.ReactNode[];
}

export const CustomTabs: FC<ITabs> = ({ tabHeader, tabContent }) => {
    const [tabIndex, setTabIndex] = useState(0);

    const tabStyles = useCallback(
        (index: number) => ({
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            color: `${index === tabIndex ? uiColors.mainBlue : uiColors.darkGrey}`,
        }),
        [tabIndex]
    );

    return (
        <STabsWrapper>
            <ReactTabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <ReactTabList style={{ display: 'flex' }}>
                    {tabHeader.map((tab, index) => {
                        const { onSelect } = tab;
                        const props = {
                            style: tabStyles(index),
                            key: `react-tab-header-key-${index}`,
                            ...(onSelect && { onClick: (event: any) => onSelect(event) }),
                        };
                        return (
                            <ReactTab {...props}>
                                <SIconTab
                                    fill={`${index === tabIndex ? 'var(--ui-text)' : 'var(--ui-label)'}`}
                                    name={tab.name}
                                />
                                <STabName isActive={index === tabIndex}>{tab.title}</STabName>
                            </ReactTab>
                        );
                    })}
                </ReactTabList>
                {tabContent.map((content, index) => (
                    <ReactTabPanel key={`react-tab-panel-key-${index}`}>{content}</ReactTabPanel>
                ))}
            </ReactTabs>
        </STabsWrapper>
    );
};
