import { Handle } from 'reactflow';

import styled from 'styled-components';

import { border, pxToRem, radius, uiColors } from '@repeat/ui-kit';

export const SFSMStateElementWrapper = styled.div<{ width: number; height: number; isOutlined: boolean }>`
    background: var(--ui-sidebar);
    border-radius: ${radius.default};
    border: ${({ isOutlined }) => (isOutlined ? `1px solid ${uiColors.mainBlue}` : border.sidebar)};
    height: ${({ height }) => `${height}px`};
    width: ${({ width }) => `${width}px`};
    position: relative;
    display: flex;
    align-items: center;
    pointer-events: all;

    .react-flow__resize-control.handle {
        height: ${pxToRem(8)};
        width: ${pxToRem(8)};
    }

    * {
        pointer-events: all;
    }
`;

export const FSMStateCustomBody = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const SNodeTitleWrapper = styled.div<{ isEditMode: boolean; isError: boolean }>`
    display: flex;
    border-bottom: 1px solid var(--ui-border);
    margin-bottom: 4px;
    align-items: center;
    width: 100%;

    input {
        background: transparent;
        border: 0;
        font-size: 12px;
        text-align: center;
        cursor: inherit;
        width: 100%;
        ${({ isError }) => isError && `color: red`};

        &:focus-visible {
            outline: 0;
        }
    }
`;
export const SNodeActionWrapper = styled.div<{ isEditMode: boolean }>`
    font-size: ${pxToRem(12)};
    height: 100%;
    width: 100%;

    textarea {
        background: transparent;
        font-size: 12px;
        border: none;
        cursor: inherit;
        height: 100%;
        resize: none;
        width: 100%;

        &:focus-visible {
            outline: 0;
        }
    }
`;

export const FSMStateElementBody = styled.div`
    margin: ${pxToRem(12)};
    height: calc(100% - 24px);
    width: calc(100% - 24px);
    position: relative;
`;

export const SFSMHandle = styled(Handle)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    transform: none;
    border: none;
    opacity: 0;
`;

export const SFSMLabel = styled.div`
    position: absolute;
    border-radius: 2px;
    display: inline-block;
    background: var(--ui-background);
    border: ${border.default};
    font-size: 10px;

    input {
        color: var(--ui-text);
        font-size: 10px;
        border: 0;
        background: none;

        &:focus-visible {
            outline: 0;
            background: var(--ui-input);
        }
    }
`;

export const FSMStateToolbarIcons = styled.div`
    padding: 2px;
    background: var(--ui-sidebar);
    border-radius: 2px;
`;
