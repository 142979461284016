/* eslint-disable react/jsx-no-useless-fragment */
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { libraryElementImageSrc } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { ILibraryItem, TLibraryType, TSolverType } from '@repeat/models';
import { appUserSelectors, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { messages } from './translation';

import { Element } from '../../../../components/Elements/Element';
import { SLibraryItemsAccordion } from '../LibraryItems';
import { LibraryItemsAccordionItem } from '../LibraryItemsBlock/LibraryItemsAccordionItem';
import { SWrapper } from '../LibraryItemsBlock/SLibraryItemsBlock';

interface ILibraryItemsBlockProps {
    items: { [key: string]: ILibraryItem[] };
    solverType: TSolverType;
    libraryType: TLibraryType;
    onDragStart: (e: React.DragEvent<HTMLDivElement>, options: ILibraryItem) => void;
    opened: boolean;
}

export const LibraryItemsSubLibrary: FC<ILibraryItemsBlockProps> = (props) => {
    const { formatMessage } = useIntl();

    const { items, solverType, libraryType, onDragStart, opened } = props;
    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);
    const currentIconModeValue = useAppSelector(appUserSelectors.currentUserIconMode)!;

    const subLibrariesNames: { [key: string]: string } = {
        rotationalMotion: `${formatMessage(messages[TranslationKey.SUBLIBRARY_ROTATIONAL_MOTION])}`,
        translationalMotion: `${formatMessage(messages[TranslationKey.SUBLIBRARY_TRANSLATIONAL_MOTION])}`,
        baseElements: `${formatMessage(messages[TranslationKey.SUBLIBRARY_BASE_ELEMENTS])}`,
        converters: `${formatMessage(messages[TranslationKey.SUBLIBRARY_CONVERTERS])}`,
        electricMachines: `${formatMessage(messages[TranslationKey.SUBLIBRARY_ELECTRIC_MACHINES])}`,
        suppliers: `${formatMessage(messages[TranslationKey.SUBLIBRARY_SUPPLIERS])}`,
        functions: `${formatMessage(messages[TranslationKey.SUBLIBRARY_FUNCTIONS])}`,
        operations: `${formatMessage(messages[TranslationKey.SUBLIBRARY_OPERATIONS])}`,
        logic: `${formatMessage(messages[TranslationKey.SUBLIBRARY_LOGIC])}`,
        switch: `${formatMessage(messages[TranslationKey.SUBLIBRARY_SWITCH])}`,
        transferfunctions: `${formatMessage(messages[TranslationKey.SUBLIBRARY_TRANSFERFUNCTIONS])}`,
        controllers: `${formatMessage(messages[TranslationKey.SUBLIBRARY_CONTROLLERS])}`,
        delays: `${formatMessage(messages[TranslationKey.SUBLIBRARY_DELAYS])}`,
        relay: `${formatMessage(messages[TranslationKey.SUBLIBRARY_RELAY])}`,
        nonlinear: `${formatMessage(messages[TranslationKey.SUBLIBRARY_NONLINEAR])}`,
        signalProcessing: `${formatMessage(messages[TranslationKey.SUBLIBRARY_SIGNAL_PROCESSING])}`,
        structuralAndSupport: `${formatMessage(messages[TranslationKey.SUBLIBRARY_STRUCTURAL_AND_SUPPORT])}`,
        loads: `${formatMessage(messages[TranslationKey.SUBLIBRARY_LOADS])}`,
        transformers: `${formatMessage(messages[TranslationKey.SUBLIBRARY_TRANSFORMERS])}`,
        sources: `${formatMessage(messages[TranslationKey.SUBLIBRARY_SOURCES])}`,
        powerConverters: `${formatMessage(messages[TranslationKey.SUBLIBRARY_POWER_CONVERTERS])}`,
        longitudinalElements: `${formatMessage(messages[TranslationKey.SUBLIBRARY_LONGITUDINAL_ELEMENTS])}`,
        electronicComponentsAndPowerConverters: `${formatMessage(
            messages[TranslationKey.SUBLIBRARY_ELECTRONIC_COMPONENTS_AND_POWER_CONVERTERS]
        )}`,
        passiveElements: `${formatMessage(messages[TranslationKey.SUBLIBRARY_PASSIVE_ELEMENTS])}`,
        basicComponents: `${formatMessage(messages[TranslationKey.SUBLIBRARY_BASIC_COMPONENTS])}`,
        measuringElements: `${formatMessage(messages[TranslationKey.SUBLIBRARY_MEASURING_ELEMENTS])}`,
        cms: `${formatMessage(messages[TranslationKey.SUBLIBRARY_CMS])}`,
        transport: `${formatMessage(messages[TranslationKey.SUBLIBRARY_TRANSPORT])}`,
        radioFrequencyAnalysis: `${formatMessage(messages[TranslationKey.SUBLIBRARY_RADIO_FREQUENCY_ANALYSIS])}`,
    };

    const getImageSrc = useCallback((solverType: TSolverType, libraryType: TLibraryType, picId: number) => {
        const assetsPath = `/assets`;
        return libraryElementImageSrc(assetsPath, solverType, libraryType, `${picId}`, currentIconModeValue);
    }, []);

    return (
        <SLibraryItemsAccordion>
            {Object.keys(items)
                .sort((x: string, y: string) => subLibrariesNames[x]?.localeCompare(subLibrariesNames[y]))
                .map((subLibrary) => (
                    <LibraryItemsAccordionItem
                        opened={opened}
                        isSubLibrary={true}
                        key={subLibrary}
                        title={subLibrariesNames[subLibrary] || subLibrary}
                        disabled={false}
                    >
                        <SWrapper>
                            {items[subLibrary].map(
                                (el: ILibraryItem) =>
                                    el.isActive && (
                                        <Element
                                            data-tour={'library-items-element'}
                                            solverType={solverType}
                                            libraryType={libraryType}
                                            options={el}
                                            key={`${el.picId}-${el.blockId || ''}`}
                                            onDragStart={(e) => {
                                                onDragStart(e, el);
                                            }}
                                            imageSrc={
                                                el.type === 'userBlock' && el.image
                                                    ? el.image
                                                    : getImageSrc(solverType, libraryType, el.picId)
                                            }
                                            newTitle={formatMessage(messages[TranslationKey.LIBRARY_NEW_BLOCK])}
                                        />
                                    )
                            )}
                        </SWrapper>
                    </LibraryItemsAccordionItem>
                ))}
        </SLibraryItemsAccordion>
    );
};
