import React, { FC, useState, useEffect, useCallback } from 'react';

import { SProjectHistoryList } from '@components/ProjectHistory/SProjectHistory';
import { IProjectHistoryItem, IProjectHistoryList } from '@components/ProjectHistory/types';

import { ProjectHistoryItem } from './ProjectHistoryItem';

export const ProjectHistoryList: FC<IProjectHistoryList<IProjectHistoryItem>> = ({
    data,
    total,
    scrollRef,
    onOpen,
    onPreview,
    onDelete,
    onEditComment,
    onGetData,
}) => {
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(total / itemsPerPage);

    const loadMore = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        if (page >= totalPages) {
            setHasMore(false);
            setIsLoading(false);
            return;
        }

        try {
            const newPageIndex = page + 1;
            const newItems = await onGetData(newPageIndex, itemsPerPage);
            if (newItems && newItems.length > 0) {
                data.push(...(newItems as IProjectHistoryItem[]));
                await setPage(newPageIndex);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [page, isLoading, data]);

    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if (scrollHeight - scrollTop === clientHeight && hasMore && !isLoading) {
                loadMore();
            }
        }
    };

    useEffect(() => {
        const container = scrollRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollRef, hasMore, isLoading]);

    useEffect(() => {
        setPage(1);
        setHasMore(data.length < total);
    }, [data, total]);

    return (
        <SProjectHistoryList>
            {data.map((item: IProjectHistoryItem, index: number) => {
                return (
                    <ProjectHistoryItem
                        key={`project-history-item-${index}-${item.uuid}`}
                        {...item}
                        onPreview={onPreview}
                        onDelete={onDelete}
                        onOpen={onOpen}
                        onEditComment={onEditComment}
                    />
                );
            })}
        </SProjectHistoryList>
    );
};
