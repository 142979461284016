import { FC } from 'react';

import { TWorkspaceMode, WorkspaceModes } from '@repeat/models';

import { Charts } from './Charts/Charts';
import { ChartsEditor } from './ChartsEditor/ChartsEditor';

export const Visualization: FC<{ mode: TWorkspaceMode }> = ({ mode }) => {
    return (
        <>
            <Charts />
            {mode !== WorkspaceModes.DEMO && mode !== WorkspaceModes.PREVIEW && <ChartsEditor />}
        </>
    );
};
