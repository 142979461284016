import styled, { css } from 'styled-components';

import { border, pxToRem, uiColors } from '@repeat/ui-kit';

const InputStyles = css`
    background-color: var(--ui-sidebar);
    border: ${border.default};
    border-radius: 4px;
    font-size: ${pxToRem(12)};
    padding: ${pxToRem(4)} ${pxToRem(8)};
    width: 100%;
    outline: none;

    &:focus,
    &:active {
        border-color: var(--ui-border-hover);
    }
`;

export const SFSMTableWrapper = styled.div`
    position: relative;

    [data-name='FSM-list-add-button'] {
        background-color: var(--ui-primary);
        bottom: -${pxToRem(10)};
        right: -${pxToRem(10)};
        position: absolute;
        opacity: 1;

        i {
            svg {
                fill: white;
            }
        }
    }
`;

export const SFSMTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: ${pxToRem(8)};
`;

export const SFSMTableHead = styled.thead`
    background-color: var(--ui-table);
`;

export const SFSMTableRow = styled.tr`
    padding: ${pxToRem(4)};

    td:first-child {
        width: 30%;
    }
`;

export const SFSMTableHeader = styled.th`
    border: 1px solid var(--ui-label);
    padding: ${pxToRem(4)} ${pxToRem(4)};
    text-align: left;
`;

export const SFSMTableCell = styled.td`
    padding: ${pxToRem(4)};
    border: 1px solid var(--ui-label);
`;

export const SFSMSelect = styled.select`
    ${InputStyles};
    padding: ${pxToRem(4)} 0;
    width: 100%;
`;

export const SFSMInput = styled.input`
    ${InputStyles};

    &[data-error='true'] {
        border-color: ${uiColors.red};
    }
`;

export const SFSMAddButton = styled.button`
    padding: 10px 15px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;

export const SFSMTableLabel = styled.label`
    color: var(--ui-label);
    display: flex;
    align-items: center;
    padding: 0 ${pxToRem(8)} ${pxToRem(4)} 0;
    justify-content: space-between;
    font-size: ${pxToRem(12)};
    width: 100%;
    position: relative;
`;
