import styled from 'styled-components';

import { SShowMore } from 'libs/ui-kit/src/lib/ShowMore/ShowMore';

import { InputField, pxToRem, uiColors } from '@repeat/ui-kit';

export const SGroupNameBlock = styled.div<{ isInput: boolean }>`
    position: relative;
    margin-right: ${({ isInput }) => isInput && pxToRem(16)};
`;

export const SGroupName = styled.div`
    align-items: center;
    font-size: 14px;
    color: ${uiColors.mainBlue};
    border: 0;
    background-color: transparent;
`;

export const SButtons = styled.div`
    position: absolute;
    top: 0;
    right: -${pxToRem(16)};
`;

export const SInput = styled(InputField)``;

export const SElementId = styled.div`
    font-size: ${pxToRem(10)};
    color: ${uiColors.grey};
`;

export const SElementName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${pxToRem(14)};
    color: var(--ui-link-default);
    border: 0;
    background-color: transparent;
    margin-top: ${pxToRem(16)};
`;

export const SOptionsWrapper = styled.div`
    font-size: ${pxToRem(14)};
    padding-bottom: ${pxToRem(32)};
`;

export const SBlockWrapper = styled.div`
    ${SShowMore} {
        padding: 0 !important;
    }

    form {
        padding: 0;
    }

    button {
        padding: ${pxToRem(4)} ${pxToRem(8)} !important;
        margin: 0 !important;
    }
`;
