import { Edge, Node, XYPosition } from 'reactflow';

import { ElemParams, ElemProps, IElementConfiguration, IElementPort, TProxyMap } from '../element';
import {
    ILibraryItem,
    ILibraryItemValidationRule,
    ILibraryItemViewData,
    TLibraryItemPort,
    TLibraryType,
    TPortTypeComplex,
    TSolverType,
} from '../libraryItem';
import { IModulesCounter, IProjectInfo } from '../project';
import { Status } from '../status';
import { IProjectSettingsOptions } from '../workspace';

export enum SchemaItemTypes {
    NODE = 'node',
    NODE_DC = 'nodeDC',
    CONNECTION = 'connection',
}
export type TSchemaItemType = SchemaItemTypes.NODE | SchemaItemTypes.CONNECTION;

export enum WireTypes {
    WIRE = 'wire',
}
export type TWire = WireTypes.WIRE;

// make TElement instead of IElement
export type TElement = {
    id: number;
    picId: number;
    name: string;
    shortName: string;
    type: string;
    subtype?: string;
    library: TLibraryType;
    subLibrary?: string;
    description: string;
    solver: TSolverType;
    elemParams: ElemParams[];
    elemProps: ElemProps[];
    isIndicator?: boolean;
    isActive: boolean;
    isDisabled: boolean;
    view: ILibraryItemViewData;
    rules: ILibraryItemValidationRule[];
    hasConfigurations: boolean;
    selectedConfiguration: any;
    stateParameters: ElemParams[];
    availablePorts: TSchemaHandle[];
    parametersToDisplay: string[] | null;
    propertiesToDisplay: string[] | null;
    isFromFile?: boolean;
    index: string;
    // TODO remove props below
    indicator?: { connectedElementId: number; parameter: string; unitsPropValue: string }; // TODO - is used only for USDS - REMOVE IT
    portsAmount: number;
    hasPorts: boolean;
    uuid?: string; // is for FMU file identification
    isViewOnly: boolean;
    hasManagedPorts: boolean;
    isNeedToUpdate: boolean;
    proxyMap?: TProxyMap | null;
    submodelItems?: { elements: TSchemaNode[]; wires: TSchemaConnection[]; groups?: TSchemaGroup[] };
    diff?: {
        connectedProject: boolean;
        isErrorGettingProject: boolean;
        userBlockIsChanged?: boolean;
        userBlockIsDeleted?: boolean;
        isErrorGettingUserBlock?: boolean;
    };
    hash?: string | null;
    isDeprecated?: boolean;
    maintainedTo?: string;
    blockId?: string | null;
    userId?: number | null;
    image?: string | null;
    modules?: IModulesCounter;
};
export type TConnection = {
    id: number;
    index: string;
    type: TWire;
    wireProps: ElemProps[];
    wireParams: ElemParams[];
    isValidConnection?: boolean;
    condition?: string | null;
    action?: string | null;
    positionHandlers?: any;
};

export type TModelElement = Pick<
    TElement,
    'id' | 'type' | 'elemParams' | 'elemProps' | 'uuid' | 'proxyMap' | 'blockId'
> & {
    position: XYPosition;
    fileName?: string;
};
export type TModelConnection = {
    id: number;
    firstElement: number;
    firstPort: string;
    secondElement: number;
    secondPort: string;
    type: TWire;
    wireParams: ElemParams[];
    wireProps: ElemProps[];
};

export interface IProjectData {
    projectId: number;
    solverType: TSolverType;
    libraryType: TLibraryType;
    header: {
        systemType: string;
        taskNumber: number;
    } & IProjectSettingsOptions;
    data: {
        elements: TSchemaNode[];
        wires: TSchemaConnection[];
        groups: TSchemaGroup[];
    };
}

export interface IModelData {
    projectId: number;
    solverType: TSolverType;
    libraryType: TLibraryType;
    header: {
        systemType: string;
        taskNumber: number;
    };
    fileName?: string;
    data: {
        elements: TModelElement[];
        wires: TModelConnection[];
        groups: TModelGroup[];
    };
}

export interface ICodeGeneratingData extends IModelData {
    codeGenType: string;
    projectName: string;
}

export interface IProjectHashData {
    data: {
        elements: TModelElement[];
        wires: TModelConnection[];
    };
    proxyMap: TProxyMap | null;
    externalInfo: { properties: ElemProps[]; parameters: ElemParams[] } | null;
}

export enum ElementNotificationTypes {
    NEED_TO_UPDATE = 'need_to_update',
    IS_DEPRECATED = 'block_is_deprecated',
    PROJECT_IS_CHANGED = 'project_is_changed',
    IS_ERROR_GETTING_PROJECT = 'is_error_getting_project',
    GOTO_WITHOUT_PAIR = 'is_go_block_without_pair',
    GOTO_RELATED_BLOCK = 'is_go_related_block',
    GOTO_NOT_UNIQUE = 'is_goto_block_not_unique',
    USER_BLOCK_IS_CHANGED = 'user_block_is_changed',
    USER_BLOCK_IS_DELETED = 'user_block_is_deleted',
    USER_BLOCK_IS_ERROR = 'user_block_is_error',
}
export type TElementNotificationType =
    | ElementNotificationTypes.NEED_TO_UPDATE
    | ElementNotificationTypes.IS_DEPRECATED
    | ElementNotificationTypes.PROJECT_IS_CHANGED
    | ElementNotificationTypes.IS_ERROR_GETTING_PROJECT
    | ElementNotificationTypes.GOTO_WITHOUT_PAIR
    | ElementNotificationTypes.GOTO_RELATED_BLOCK
    | ElementNotificationTypes.GOTO_NOT_UNIQUE
    | ElementNotificationTypes.USER_BLOCK_IS_CHANGED
    | ElementNotificationTypes.USER_BLOCK_IS_DELETED
    | ElementNotificationTypes.USER_BLOCK_IS_ERROR;

// TODO remove this interface
export interface IElement extends Omit<ILibraryItem, 'shortName' | 'index' | 'isActive' | 'id'> {
    tint: string;
    x: number;
    y: number;
    id: number;
    indicator: { connectedElementId: number; parameter: string; unitsPropValue: string };
    size: number;
    value: number | string;
    valueTask: number;
    valueMin: number;
    valueMax: number;
    new?: boolean;
    angle: number;
    warning: boolean;
    state: string;
    indexDisplay: string;
    ports: IElementPort[];
    wireId?: number;
    library: TLibraryType;
    solver: TSolverType;
    isIndicator?: boolean;
    availableConfigurations?: IElementConfiguration[];
    selectedConfiguration?: IElementConfiguration;
    fileName?: string;
}

export type TTask = {
    systemType: string;
    taskNumber: number;
    version?: string;
    hash?: string;
};

export type TSchemaNodeMeta = {
    isViewInitialized: boolean;
    isDataInitialized: boolean;
};
export type INodeExtraProperties = {
    meta: TSchemaNodeMeta;
    width: number;
    height: number;
    rotation: number;
    customName?: string;
    customImage?: string;
};
export type TSchemaElementWithParams = Pick<TElement, 'id' | 'index' | 'name' | 'elemParams'> & {
    blockId?: string | null;
};
export type TSchemaNode = Node<TElement> & INodeExtraProperties;
export type TSchemaNodeWithoutParams = Node<Pick<TElement, Exclude<keyof TElement, 'elemParams'>>> &
    INodeExtraProperties;
export type TSchemaConnection = Edge<TConnection> & { data: TConnection; sourceHandle: string; targetHandle: string };
export type TSchemaFSMConnection = Edge<TConnection> & { data: TConnection };
export type TSchemaHandle = TLibraryItemPort & { isConnected: boolean } & Omit<TPortTypeComplex, 'type'>;
export type TSchemaGroup = {
    id: number;
    elements: TSchemaNode[];
    wires: TSchemaConnection[];
    parentGroupId: string | null;
    name: string;
    blockId?: string;
};
export type TModelGroup = { id: number; elements: TModelElement[]; wires: TModelConnection[] };

export enum SchemaUpdateTypes {
    UPGRADE = 'upgrade',
    UNDOREDO = 'undo-redo',
}
export type TSchemaUpdateType = SchemaUpdateTypes.UPGRADE | SchemaUpdateTypes.UNDOREDO;

export enum SchemaSelectionModes {
    PROJECT = 'project',
    NODE = 'node',
    CONNECTION = 'connection',
    MULTIPLE_OBJECTS = 'multiple_objects',
}

export type TSchemaSelectionMode =
    | SchemaSelectionModes.PROJECT
    | SchemaSelectionModes.NODE
    | SchemaSelectionModes.CONNECTION
    | SchemaSelectionModes.MULTIPLE_OBJECTS;

export interface IElementInitializationData {
    name: string;
    uuid: string;
    elemParams: ElemParams[];
    elemProps: ElemProps[];
}

export enum BlockGoToTypes {
    GOTO = 'GoTo',
    GOFROM = 'GoFrom',
}
export type TBlockGoToType = BlockGoToTypes.GOTO | BlockGoToTypes.GOFROM;

export interface ISchemaGoToMapItem {
    title: string;
    goToId: string | null;
    goFromIds: string[];
    notUniqueGoToIds?: string[];
}
export interface ISchemaGoToMap {
    [uuid: string]: ISchemaGoToMapItem;
}

export interface ISchemaBlocksNotifications {
    [blockId: string]: TElementNotificationType;
}

export interface ISchemaSelectedBlockProperties {
    [propertyName: string]: Pick<ElemProps, 'availableValues'>;
}

export interface ISchemaState {
    version: string | null;
    libraryType: TLibraryType | null;
    solverType: TSolverType | null;
    rawSchemaItems: {
        elements: unknown[];
        wires: unknown[];
    };
    schemaItems: {
        elements: TSchemaNode[];
        wires: TSchemaConnection[];
        groups?: TSchemaGroup[];
        userBlockGroups?: TSchemaGroup[];
    };
    userBlocksCount?: { [blockId: string]: number };
    elementsWithParams: TSchemaElementWithParams[];
    selectedItems: {
        elements: TSchemaNode[];
        wires: TSchemaConnection[];
    };
    selectedItemProperties: ISchemaSelectedBlockProperties | null;
    itemsToCopy: {
        elements: TSchemaNode[];
        wires: TSchemaConnection[];
    };
    goToMap: ISchemaGoToMap | null;
    blockNotifications: ISchemaBlocksNotifications | null;
    itemsForSubmodel: {
        elements: TSchemaNode[];
        wires: TSchemaConnection[];
    };
    getProject: {
        status: Status;
        error: string | null;
    };
    saveProject: {
        status: Status;
        error: string | null;
    };
    getElementPropertiesSets: {
        status: Status;
        error: string | null;
        elementType: string | null;
        libraryType: TLibraryType | null;
        solverType: TSolverType | null;
        configurations: IElementConfiguration[];
    };
    getSubmodelsSchemaItems: {
        status: Status;
        error: string | null;
    };
    getProjectsForProjectsBlock: {
        status: Status;
        error: string | null;
    };
    getProjectBlock: {
        status: Status;
        error: string | null;
        project: IProjectInfo | null;
    };
    elementInitialization: {
        elementId: number | null;
        projectId: number | null;
        uuid: string | null;
        originalName: string | null;
        filesize: number;
        data: IElementInitializationData | null;
        uploadElementSourcesFile: {
            fileSizeBytes: number;
            uploadedBytes: number;
            status: Status;
            error: string | null;
        };
        initializeElementByFile: {
            status: Status;
            error: string | null;
        };
    };
    externalInfo: { properties: ElemProps[]; parameters: ElemParams[] } | null;
    proxyMap: TProxyMap | null;
}

export type TModelParameter = {
    elementName: string;
    elementId: number;
    title: string;
    code: string;
    modelName: string;
    unit: string;
};
export type TWatchSchemaItemParameter = {
    key: string;
    elementTitle: string;
    parameterTitle: string;
    title: string;
};

export interface IWatchingParameterOption {
    key: string;
    elementTitle: string;
    parameterTitle: string;
    title: string;
}

export interface ITrendDataMapItem {
    index: number;
    lateTimeStart: number;
    isReady: boolean;
}
export interface ITrendDataMap {
    [key: string]: ITrendDataMapItem;
}

export type VariableType = 'internal' | 'out' | 'in';

export enum EFSMVariableType {
    IN = 'in',
    OUT = 'out',
    INTERNAL = 'internal',
}
export interface IFSMVariable {
    type: VariableType;
    name: string;
    value: string;
    typeJava: 'double';
}
