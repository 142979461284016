import { PayloadAction } from '@reduxjs/toolkit';

import { TPortTypeComplex } from 'libs/models/src/lib/libraryItem';

import {
    CANVAS,
    calculatePortsLength,
    countMaxOfInputsAndOutputs,
    findMissingNumbers,
    setDefaultPort,
    setPorts,
    sortParameters,
    sortParametersByParamNames,
} from '@repeat/constants';
import {
    EFSMVariableType,
    ElemParams,
    IFSMVariable,
    IWorkspaceState,
    PortConnectionTypes,
    PortPositions,
    PortTypes,
    TLibraryItemPort,
    TSchemaConnection,
    TSchemaHandle,
    TSchemaNode,
    VariableType,
    WorkspaceModes,
} from '@repeat/models';

import { actions } from '../..';
import { AppDispatch, RootStateFn } from '../../../../store';

const calculateNewHeight = (ports: TSchemaHandle[]) => {
    let newHeight = CANVAS.ELEMENT_MIN_HEIGHT;
    const handleMargin = CANVAS.PORT_MARGIN;

    const maxPortsLength = calculatePortsLength(countMaxOfInputsAndOutputs(ports), handleMargin);

    newHeight = Math.max(maxPortsLength, newHeight);
    return newHeight;
};

export const setElementParametersSlice = {
    addParameter: (state: IWorkspaceState, action: PayloadAction<{ id: string; type: string }>) => {
        const { id, type } = action.payload;
        const libraryItem = state.libraryItems.items.find((item) => item.type === 'jython');
        const libraryPortTypes = state.libraryPortTypes.items;
        const { groupId, elementId, mode } = state.meta;
        const groups = state.schema.schemaItems.groups || [];
        let defaultInputPort: TLibraryItemPort;
        let defaultOutputPort: TLibraryItemPort;
        let defaultInputPortDetails: TPortTypeComplex;
        let defaultOutputPortDetails: TPortTypeComplex;

        if (libraryItem) {
            const ports = libraryItem.availablePorts;
            defaultInputPort = ports.filter((p) => p.type === PortTypes.INPUT)[0];
            defaultOutputPort = ports.filter((p) => p.type === PortTypes.OUTPUT)[0];
            defaultInputPortDetails = libraryPortTypes.find(
                (type) => type.type === defaultInputPort.typeConnection
            ) || { ...setDefaultPort(defaultInputPort.name, libraryPortTypes), type: defaultInputPort.typeConnection };
            defaultOutputPortDetails = libraryPortTypes.find(
                (type) => type.type === defaultOutputPort.typeConnection
            ) || {
                ...setDefaultPort(defaultOutputPort.name, libraryPortTypes),
                type: defaultOutputPort.typeConnection,
            };
        }

        let newIndex = 0;

        const updateElements = (elements: TSchemaNode[]) => {
            return elements.map((el) => {
                if (el.id.toString() === id) {
                    const params = el.data.elemParams;
                    const missedIndexes = findMissingNumbers(params, type);
                    newIndex =
                        missedIndexes.length === 0
                            ? params.filter((p) => p.name.includes(type))?.length + 1
                            : missedIndexes[0];

                    const paramsNew = [
                        ...el.data.elemParams,
                        {
                            description: `${type}_${newIndex}`,
                            name: `${type}_${newIndex}`,
                            modelName: '',
                            unit: '',
                            value: '',
                        },
                    ];

                    const newPorts = setPorts(
                        sortParameters(paramsNew),
                        defaultInputPort,
                        defaultOutputPort,
                        el.data.availablePorts,
                        defaultInputPortDetails,
                        defaultOutputPortDetails
                    );

                    const newHeight = calculateNewHeight(newPorts);

                    return {
                        ...el,
                        height: newHeight,
                        data: { ...el.data, availablePorts: newPorts, elemParams: sortParameters(paramsNew) },
                    };
                }
                return el;
            });
        };

        if (mode === WorkspaceModes.CODE_EDITOR && groupId) {
            const newGroups = groups.map((group) => {
                if (group.id.toString() === groupId) {
                    return { ...group, elements: updateElements(group.elements) };
                }
                return group;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: { ...state.schema.schemaItems, groups: newGroups },
                },
            };
        }

        const elementsWithParams = state.schema.elementsWithParams.map((el) => {
            if (el.id.toString() === id) {
                const params = el.elemParams;

                const missedIndexes = findMissingNumbers(params, type);

                newIndex =
                    missedIndexes.length === 0
                        ? params.filter((p) => p.name.includes(type))?.length + 1
                        : missedIndexes[0];

                const paramsNew = [
                    ...el.elemParams,
                    {
                        description: `${type}_${newIndex}`,
                        name: `${type}_${newIndex}`,
                        modelName: '',
                        unit: '',
                        value: '',
                    },
                ];

                return { ...el, elemParams: sortParameters(paramsNew) };
            }
            return el;
        });

        const elements = updateElements(state.schema.schemaItems.elements);

        return {
            ...state,
            schema: {
                ...state.schema,
                elementsWithParams,
                schemaItems: { ...state.schema.schemaItems, elements },
            },
        };
    },
    setFSMParameters: (
        state: IWorkspaceState,
        action: PayloadAction<{
            id: string | null;
            elemParams: ElemParams[];
            availablePorts: TSchemaHandle[];
            height: number;
            wires: TSchemaConnection[];
        }>
    ) => {
        const { id: elementId, elemParams, availablePorts, height, wires } = action.payload;
        const elements = state.schema.schemaItems.elements.map((el) => {
            if (el.id === elementId) {
                return { ...el, data: { ...el.data, elemParams, availablePorts }, height };
            }
            return el;
        });
        const elementsWithParams = state.schema.elementsWithParams.map((el) => {
            if (el.id.toString() === elementId) {
                return { ...el, elemParams };
            }
            return el;
        });
        return {
            ...state,
            schema: {
                ...state.schema,
                elementsWithParams,
                schemaItems: { ...state.schema.schemaItems, elements, wires },
            },
        };
    },
};

export const deleteParameter =
    (payload: { id: string; parameterName: string }) => async (dispatch: AppDispatch, getState: RootStateFn) => {
        const { id, parameterName } = payload;
        const state = getState().workspace;
        const { mode: worspaceMode, groupId } = state.meta;
        const groups = state.schema.schemaItems.groups || [];

        if (groupId) {
            // !!!! СЧИТАЮ, ЧТО ЕСЛИ ЕСТЬ GROUP ID, ТО НАХОДИМСЯ В РЕЖИМЕ ГРУППЫ. например режим редактора кода в режиме группы
            const group = groups.find((group) => group.id.toString() === groupId);
            if (group) {
                // TODO вынести в функцию обновление элементов и связей
                const elements = group.elements.map((el) => {
                    if (el.id.toString() === id) {
                        console.log({ elId: id, GROUP: group.id });

                        const params = el.data.elemParams.filter((p) => p.name !== parameterName);
                        const newPorts = el.data.availablePorts.filter((p) => p.name !== parameterName);

                        const newHeight = calculateNewHeight(newPorts);

                        return {
                            ...el,
                            height: newHeight,
                            data: { ...el.data, availablePorts: newPorts, elemParams: params },
                        };
                    }
                    return el;
                });
                const wires = group.wires;
                const wiresForDelete = wires.filter(
                    (wire) =>
                        (wire.sourceHandle.includes(id) && wire.sourceHandle.includes(parameterName)) ||
                        (wire.targetHandle.includes(id) && wire.targetHandle.includes(parameterName))
                );

                const newWires = wires.filter((x) => !wiresForDelete.some((y) => x.id === y.id));

                dispatch(actions.updateNodesAndConnections({ nodes: elements, connections: newWires }));
            }
        } else {
            const elementsWithParams = state.schema.elementsWithParams.map((el) => {
                if (el.id.toString() === id) {
                    const params = el.elemParams.filter((p) => p.name !== parameterName);
                    return { ...el, elemParams: params };
                }
                return el;
            });

            const elements = state.schema.schemaItems.elements.map((el) => {
                if (el.id.toString() === id) {
                    const params = el.data.elemParams.filter((p) => p.name !== parameterName);
                    const newPorts = el.data.availablePorts.filter((p) => p.name !== parameterName);

                    const newHeight = calculateNewHeight(newPorts);

                    if (worspaceMode !== WorkspaceModes.SUBMODEL) {
                        return {
                            ...el,
                            height: newHeight,
                            data: { ...el.data, availablePorts: newPorts, elemParams: params },
                        };
                    }
                    const submodelItems = el.data.submodelItems;
                    const submodelElements = submodelItems ? submodelItems.elements : [];

                    const currentSubmodelElement = submodelElements.find((el) => {
                        const param = el.data.elemProps.find((p) => p.name === 'parentParameter')?.value;

                        return param === parameterName;
                    });

                    const newProxyMapParameters = el.data.proxyMap
                        ? el.data.proxyMap.params.filter((p) => p.name !== parameterName)
                        : [];
                    const newProxyMap = el.data.proxyMap
                        ? { ...el.data.proxyMap, params: newProxyMapParameters }
                        : { props: [], params: [], stateParameters: [] };

                    if (currentSubmodelElement) {
                        const newSubmodelElements =
                            el.data.submodelItems?.elements.filter((el) => el.id !== currentSubmodelElement.id) || [];
                        const newSubmodelWires =
                            el.data.submodelItems?.wires.filter(
                                (wire) =>
                                    wire.source !== currentSubmodelElement.id &&
                                    wire.target !== currentSubmodelElement.id
                            ) || [];
                        return {
                            ...el,
                            height: newHeight,
                            data: {
                                ...el.data,
                                availablePorts: newPorts,
                                elemParams: params,
                                submodelItems: { elements: newSubmodelElements, wires: newSubmodelWires },
                                proxyMap: newProxyMap,
                            },
                        };
                    }
                }
                return el;
            });
            const wires = state.schema.schemaItems.wires;

            const wiresForDelete = wires.filter(
                (wire) =>
                    (wire.sourceHandle.includes(id) && wire.sourceHandle.includes(parameterName)) ||
                    (wire.targetHandle.includes(id) && wire.targetHandle.includes(parameterName))
            );

            const newWires = wires.filter((x) => !wiresForDelete.some((y) => x.id === y.id));

            dispatch(actions.updateNodesAndConnections({ nodes: elements, connections: newWires, elementsWithParams }));
            dispatch(actions.markConnectionsPortsAsUnconnected(wiresForDelete));
        }
    };

const setFSMPorts = (
    libraryPortTypes: TPortTypeComplex[],
    elemParams: ElemParams[],
    availablePorts: TSchemaHandle[]
) => {
    const defaultInputPort = {
        name: 'in_1',
        position: PortPositions.LEFT,
        libraries: [],
        type: PortTypes.INPUT,
        typeConnection: PortConnectionTypes.AUTO_IN,
    };

    const defaultOutputPort = {
        name: 'out_1',
        position: PortPositions.RIGHT,
        libraries: [],
        type: PortTypes.OUTPUT,
        typeConnection: PortConnectionTypes.AUTO_OUT,
    };

    const defaultInputPortDetails = libraryPortTypes.find((type) => type.type === defaultInputPort.typeConnection) || {
        ...setDefaultPort(defaultInputPort.name, libraryPortTypes),
        type: defaultInputPort.typeConnection,
    };
    const defaultOutputPortDetails = libraryPortTypes.find(
        (type) => type.type === defaultOutputPort.typeConnection
    ) || {
        ...setDefaultPort(defaultOutputPort.name, libraryPortTypes),
        type: defaultOutputPort.typeConnection,
    };
    return setPorts(
        sortParametersByParamNames(elemParams),
        defaultInputPort,
        defaultOutputPort,
        availablePorts,
        defaultInputPortDetails,
        defaultOutputPortDetails
    );
};

const setWires = (wires: TSchemaConnection[], parameterName: string, id: string) => {
    const wiresForDelete = wires.filter(
        (wire) =>
            (wire.sourceHandle.includes(id) && wire.sourceHandle.includes(parameterName)) ||
            (wire.targetHandle.includes(id) && wire.targetHandle.includes(parameterName))
    );

    const newWires = wires.filter((x) => !wiresForDelete.some((y) => x.id === y.id));
    return { wiresForDelete, newWires };
};
const findDuplicates = (arr: IFSMVariable[], prop: keyof IFSMVariable) => {
    const counts: { [key: VariableType | string | 'double']: number } = {};

    arr.forEach((obj) => {
        const value = obj[prop];
        counts[value] = (counts[value] || 0) + 1;
    });

    return Object.entries(counts)
        .filter(([_, count]) => count > 1)
        .map(([value]) => value);
};

export const setFSMParameters = (payload: IFSMVariable[]) => async (dispatch: AppDispatch, getState: RootStateFn) => {
    const state = getState().workspace;
    const { elementId } = state.meta;
    const libraryPortTypes = state.libraryPortTypes.items;
    const oldParams = state.schema.schemaItems.elements.find((el) => el.id === elementId)?.data.elemParams || [];
    const availablePorts =
        state.schema.schemaItems.elements.find((el) => el.id === elementId)?.data.availablePorts || [];
    let wires = state.schema.schemaItems.wires;

    let elemParams: ElemParams[] = [];
    const oldInParams = oldParams.filter((p) => p.name.includes('in'));
    const oldOutParams = oldParams.filter((p) => p.name.includes('out'));

    const missedInIndexes = findMissingNumbers(oldInParams, 'in');
    const missedOutIndexes = findMissingNumbers(oldOutParams, 'out');

    const oldParamsDescriptions = oldParams.map((p) => p.description);

    const param = { name: '', description: '', modelName: '', value: '', unit: '' } as ElemParams;
    const payloadInOut = payload.filter((v) => v.type === EFSMVariableType.IN || v.type === EFSMVariableType.OUT);

    if (payloadInOut.length < oldParams.length) {
        const payloadNames = payload.map((v) => v.name);
        elemParams = oldParams.filter((p) => payloadNames.includes(p.description));
        const parameterDeleted = oldParams.filter((p) => !payloadNames.includes(p.description))[0];
        if (parameterDeleted) {
            const { wiresForDelete, newWires } = setWires(wires, parameterDeleted.name, elementId || '');
            wires = newWires;
            dispatch(actions.markConnectionsPortsAsUnconnected(wiresForDelete));
        } else {
            const paramInPayload = payload.filter((v) => !payloadInOut.map((p) => p.name).includes(v.name))[0];
            elemParams = oldParams.filter((p) => p.description !== paramInPayload.name);
            const parameterDeleted = oldParams.filter((p) => p.description === paramInPayload.name)[0];
            const { wiresForDelete, newWires } = setWires(wires, parameterDeleted.name, elementId || '');
            wires = newWires;
            dispatch(actions.markConnectionsPortsAsUnconnected(wiresForDelete));
        }
    } else if (payloadInOut.length === oldParams.length) {
        const namesDuplicates = findDuplicates(payloadInOut, 'name');
        if (namesDuplicates.length !== 0) {
            elemParams = oldParams;
        } else {
            elemParams = oldParams.map((p) => {
                const payloadParam = payloadInOut.find((v) => v.name === p.description);

                if (payloadParam) {
                    const payloadParamType = payloadParam.type;
                    const type = payloadParamType === EFSMVariableType.IN ? 'in' : 'out';

                    const isTypeChanged =
                        !(payloadParamType === EFSMVariableType.IN && p.name.includes('in')) &&
                        !(payloadParamType === EFSMVariableType.OUT && p.name.includes('out'));

                    if (isTypeChanged) {
                        const { wiresForDelete, newWires } = setWires(wires, p.name, elementId || '');
                        wires = newWires;
                        dispatch(actions.markConnectionsPortsAsUnconnected(wiresForDelete));
                    }

                    const inIndex = missedInIndexes.length === 0 ? oldInParams.length + 1 : missedInIndexes[0];
                    const outIndex = missedOutIndexes.length === 0 ? oldOutParams.length + 1 : missedOutIndexes[0];

                    const paramName = !isTypeChanged ? p.name : `${type}_${type === 'in' ? inIndex : outIndex}`;
                    return { ...p, name: paramName };
                }
                const payloadNames = payload.map((v) => v.name);
                const description = payloadNames.filter((name) => !oldParamsDescriptions.includes(name))[0] || '';
                return { ...p, description };
            });
        }
    } else {
        const inParamsAdding = payload
            .filter((v) => v.type === EFSMVariableType.IN && !oldParamsDescriptions.includes(v.name) && v.name !== '')
            .map((v) => ({
                ...param,
                name: `in_${missedInIndexes.length === 0 ? oldInParams.length + 1 : missedInIndexes[0]}`,
                description: v.name,
            }));

        const outParamsAdding = payload
            .filter((v) => v.type === EFSMVariableType.OUT && !oldParamsDescriptions.includes(v.name) && v.name !== '')
            .map((v) => ({
                ...param,
                name: `out_${missedOutIndexes.length === 0 ? oldOutParams.length + 1 : missedOutIndexes[0]}`,
                description: v.name,
            }));

        elemParams = [...oldInParams, ...inParamsAdding, ...oldOutParams, ...outParamsAdding];
    }

    const newPorts = setFSMPorts(libraryPortTypes, elemParams, availablePorts);

    const newHeight = calculateNewHeight(newPorts);

    dispatch(
        actions.setFSMParameters({
            id: elementId,
            elemParams: sortParametersByParamNames(elemParams),
            availablePorts: newPorts,
            height: newHeight,
            wires,
        })
    );
};
