import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useReactFlow } from '@reactflow/core';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { ModalTypes, Project, SchemaItemTypes, TModal, TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { closeProject, editUserBlock, saveProject, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, LoaderDefault, Tooltip, uiColors } from '@repeat/ui-kit';

import { MenuBreadCrumbs } from './MenuBreadCrumbs';
import { MenuCodeGenerationItem } from './MenuCodeGenerationItem';
import { MenuGetNoNameItem } from './MenuGetNoNameItem';
import { MenuReadOnlyModeItem } from './MenuReadOnlyModeItem';
import { SProjectName, SWorkspaceProjectName } from './SWorkspaceProjectMenu';
import { messages } from './translation';

import { AbilityContext } from '../../../context/Can';
import { useReadOnlyMode } from '../../../hooks/useReadOnlyMode';
import { useProjectScreenshot } from '../../Project/hooks/useProjectScreenshot';

export const WorkspaceProjectMenu: FC<{ noSave?: boolean; noClose?: boolean }> = ({
    noSave = false,
    noClose = false,
}) => {
    const { formatMessage } = useIntl();

    const ability = useContext(AbilityContext);
    const isCodeGenAvailable = ability.can('use', `tool-CODE_GENERATION`);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { projectId } = useProjectId();

    const [currentProjectName, setCurrentProjectName] = useState<string>('');
    const [path, setPath] = useState<{ id: string | null; name: string }[]>([]);

    const { mode, elementId } = useAppSelector((state) => state.workspace.meta);
    const libraryType = useAppSelector(workspaceSelectors.libraryType);
    const solverType = useAppSelector(workspaceSelectors.solverType);
    const node = useAppSelector(workspaceSelectors.getSchemaNodeById(elementId));
    const selectedItemsCount = useAppSelector(workspaceSelectors.schemaSelectedItemsCount);
    const schemaGroups = useAppSelector(workspaceSelectors.schemaGroups);
    const userBlockGroups = useAppSelector((state) => state.workspace.schema.schemaItems.userBlockGroups);
    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const prevWorkspaceMode = useAppSelector((state) => state.workspace.meta.previousMode);
    const userBlockID = useAppSelector((state) => state.workspace.meta.userBlockId);
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditorMode);
    const metaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);

    const userBlockName = localStorage.getItem('userBlockName');
    const { name: libraryItemName } = useAppSelector(workspaceSelectors.libraryItemNameAndDescription);

    const reactFlow = useReactFlow();

    const createIdArray = () => {
        const result = [];
        let parentId = elementId;
        if (!schemaGroups) {
            return [];
        }

        const findNode = (parentId: string) => {
            if (userBlockID && userBlockGroups) {
                return (
                    userBlockGroups.find((obj) => obj.id.toString() === parentId) ||
                    userBlockGroups.find((group) => group.parentGroupId === null)
                );
            }
            return schemaGroups.find((obj) => obj.id.toString() === parentId);
        };

        while (parentId !== null) {
            const node = findNode(parentId);
            if (node) {
                result.push({ id: node.id.toString(), name: node.name });
                parentId = node.parentGroupId;
            } else {
                break;
            }
        }

        return result;
    };

    const { handleScreenShot, handleDownloadScreenshot, isDownloading, isSaving } = useProjectScreenshot();

    useEffect(() => {
        if (userBlockName) {
            setCurrentProjectName(libraryItemName);
        } else {
            setCurrentProjectName(localStorage.getItem('currentProjectName') || '');
        }
    }, [workspaceMode]);

    useEffect(() => {
        setPath(createIdArray().reverse());
    }, [elementId, userBlockGroups, schemaGroups]);

    const handleSaveClick = async () => {
        if (isUserBlockEditorMode) {
            dispatch(editUserBlock());
        } else {
            if (projectId && solverType && libraryType) {
                await dispatch(saveProject(projectId, libraryType, solverType));
                handleScreenShot(projectId);
            }
        }
    };
    const handleSaveNewClick = (project: Pick<Project, 'projectName' | 'projectId'>) => {
        const modal: TModal = {
            type: ModalTypes.PROJECT_SAVE_NEW,
            data: { project },
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    const handleCloseClick = async () => {
        await dispatch(closeProject());
        if (workspaceMode === WorkspaceModes.DEMO) {
            return navigate('/projects/demo');
        }
        return navigate('/projects');
    };

    const handleProjectNameClick = useCallback(() => {
        dispatch(
            workspaceActions.changeWorkspaceMode({ mode: prevWorkspaceMode as TWorkspaceMode, userBlockId: null })
        );
    }, [workspaceMode, metaUserBlockId]);

    const handleGroupNameClick = (el: string) => {
        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: WorkspaceModes.GROUP,
                elementId: el,
                groupId: el,
            })
        );
    };

    const handleClearSelection = () => {
        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.NODE }));
    };

    const { isReadOnlyMode } = useReadOnlyMode(workspaceMode, prevWorkspaceMode as TWorkspaceMode);

    if (currentProjectName) {
        return (
            <>
                {(mode === WorkspaceModes.MAIN || mode === WorkspaceModes.DEMO) && (
                    <Tooltip text={formatMessage(messages[TranslationKey.BACK_TO_PROJECTS])}>
                        <IconButton fill={uiColors.darkGrey} noHover onClick={handleCloseClick} name={'arrowLeft'} />
                    </Tooltip>
                )}
                {mode !== WorkspaceModes.GROUP && (
                    <SWorkspaceProjectName data-tour='workspace-project-name' noSave={noSave}>
                        {(mode === WorkspaceModes.MAIN || mode === WorkspaceModes.DEMO) && selectedItemsCount > 0 && (
                            <SProjectName onClick={handleClearSelection}>{currentProjectName}</SProjectName>
                        )}
                        {(mode === WorkspaceModes.MAIN || mode === WorkspaceModes.DEMO) &&
                            selectedItemsCount === 0 &&
                            currentProjectName}
                        {mode === WorkspaceModes.CODE_EDITOR && node && (
                            <>
                                <SProjectName onClick={handleProjectNameClick}>{currentProjectName}</SProjectName>
                                &nbsp;/&nbsp;
                                {node.data.shortName} [{node.data.index}]
                            </>
                        )}
                        {mode === WorkspaceModes.FSM_EDITOR && node && (
                            <>
                                <SProjectName onClick={handleProjectNameClick}>{currentProjectName}</SProjectName>
                                &nbsp;/&nbsp;
                                {node.data.shortName} [{node.data.index}]
                            </>
                        )}
                        {mode === WorkspaceModes.SUBMODEL && node && (
                            <>
                                <SProjectName onClick={handleProjectNameClick}>{currentProjectName}</SProjectName>
                                &nbsp;/&nbsp;
                                {node.data.shortName} [{node.data.index}]
                            </>
                        )}
                        {mode === WorkspaceModes.CODE_EDITOR && !node && (
                            <>
                                <SProjectName onClick={handleProjectNameClick}>{currentProjectName}</SProjectName>
                                &nbsp;/&nbsp;
                                {formatMessage(messages[TranslationKey.SCHEMA_ELEMENT_DELETED])}
                            </>
                        )}
                    </SWorkspaceProjectName>
                )}
                {mode === WorkspaceModes.GROUP && (
                    <MenuBreadCrumbs
                        handleProjectNameClick={handleProjectNameClick}
                        handleGroupNameClick={handleGroupNameClick}
                        projectName={currentProjectName}
                        path={path}
                    />
                )}
                {!noSave && isReadOnlyMode && (
                    <>
                        <Tooltip text={formatMessage(messages[TranslationKey.SAVE])}>
                            {!isSaving && (
                                <IconButton
                                    data-tour='workspace-project-save'
                                    fill={uiColors.darkGrey}
                                    noHover
                                    name='save'
                                    onClick={handleSaveClick}
                                />
                            )}
                            {isSaving && <LoaderDefault width={28} height={26} color={'var(--ui-text)'} />}
                        </Tooltip>
                        {!workspaceMetaUserBlockId && (
                            <>
                                <Tooltip text={formatMessage(messages[TranslationKey.SAVE_AS])}>
                                    <IconButton
                                        fill={uiColors.darkGrey}
                                        noHover
                                        name='saveEdit'
                                        onClick={() =>
                                            projectId &&
                                            handleSaveNewClick({ projectName: currentProjectName, projectId })
                                        }
                                    />
                                </Tooltip>
                                <Tooltip text={formatMessage(messages[TranslationKey.SCHEMA_SCREENSHOT])}>
                                    {!isDownloading && (
                                        <IconButton
                                            fill={uiColors.darkGrey}
                                            noHover
                                            name='screenShot'
                                            onClick={async () => {
                                                return new Promise((resolve) => {
                                                    reactFlow.fitView();
                                                    setTimeout(() => resolve(true), 100);
                                                }).then(() => handleDownloadScreenshot());
                                            }}
                                        />
                                    )}
                                    {isDownloading && <LoaderDefault width={28} height={26} color={'var(--ui-text)'} />}
                                </Tooltip>

                                {projectId && (
                                    <>
                                        {isCodeGenAvailable && (
                                            <MenuCodeGenerationItem
                                                projectId={projectId}
                                                projectName={currentProjectName}
                                            />
                                        )}
                                        <MenuGetNoNameItem projectId={projectId} projectName={currentProjectName} />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
                {((workspaceMode !== WorkspaceModes.MAIN && userBlockID) ||
                    workspaceMode === WorkspaceModes.DEMO ||
                    workspaceMode === WorkspaceModes.PREVIEW ||
                    prevWorkspaceMode === WorkspaceModes.DEMO ||
                    prevWorkspaceMode === WorkspaceModes.PREVIEW ||
                    workspaceMode === WorkspaceModes.SUBMODEL) && <MenuReadOnlyModeItem />}
            </>
        );
    }

    return null;
};
