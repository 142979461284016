import React, { FC, memo, useState, useRef, ChangeEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
    SProjectHistoryItem,
    SProjectHistoryItemBadge,
    SProjectHistoryItemBody,
    SProjectHistoryItemComment,
    SProjectHistoryItemControl,
    SProjectHistoryItemDate,
    SProjectHistoryItemTitle,
} from '@components/ProjectHistory/SProjectHistory';
import { IProjectHistoryHandler, IProjectHistoryItem } from '@components/ProjectHistory/types';

import { IS_SHOW_REMOVE_PROJECT_HISTORY_BUTTON } from '@repeat/constants';
import { TranslationKey } from '@repeat/translations';
import {
    Dropdown,
    Icon,
    IconButton,
    uiColors,
    FormattedDate,
    TextAreaField,
    Button,
    EVariantButton,
    ESizeButton,
} from '@repeat/ui-kit';

import { messages } from './translation';

export const ProjectHistoryItem: FC<IProjectHistoryItem & IProjectHistoryHandler> = memo(
    ({ uuid, active, version, date, comment, onPreview, onOpen, onDelete, onEditComment }) => {
        const { formatMessage } = useIntl();

        const [isEditing, setIsEditing] = useState<boolean>(false);
        const [editedComment, setEditedComment] = useState<string>(comment !== null ? comment : '');

        const textAreaRef = useRef(null);

        const handleStartEdit = useCallback((event: React.MouseEvent) => {
            event.stopPropagation();
            setIsEditing(true);
        }, []);

        const handleSaveEdit = useCallback(
            (event: React.MouseEvent) => {
                event.stopPropagation();
                onEditComment(version, date, editedComment);
                setIsEditing(false);
            },
            [editedComment]
        );

        const handleCancelEdit = useCallback((event: React.MouseEvent) => {
            event.stopPropagation();
            setEditedComment(comment !== null ? comment : '');
            setIsEditing(false);
        }, []);

        const handleOnChangeComment = useCallback((event: ChangeEvent) => {
            const target = event.target as HTMLTextAreaElement;
            setEditedComment(target.value);
        }, []);

        const handleOnClickVersion = useCallback((event: React.MouseEvent) => {
            event.stopPropagation();
            onOpen(version, date);
        }, []);

        const handleStopDoubleClick = (event: MouseEvent) => event.stopPropagation();

        return (
            <SProjectHistoryItem active={active}>
                <SProjectHistoryItemTitle onDoubleClick={handleOnClickVersion}>
                    <SProjectHistoryItemBadge>{version}</SProjectHistoryItemBadge>
                    <SProjectHistoryItemDate>
                        <FormattedDate date={date} />
                    </SProjectHistoryItemDate>
                </SProjectHistoryItemTitle>
                <SProjectHistoryItemBody>
                    <div>
                        {isEditing && (
                            <TextAreaField
                                ref={textAreaRef}
                                value={editedComment !== null ? editedComment : ''}
                                onChange={handleOnChangeComment}
                                onDoubleClick={handleStopDoubleClick}
                            />
                        )}
                        {!isEditing && (
                            <SProjectHistoryItemComment>
                                {comment && <p onDoubleClick={handleStartEdit}>{comment}</p>}
                                {!comment && (
                                    <p data-name='project-history-item-comment-empty' onDoubleClick={handleStartEdit}>
                                        {formatMessage(messages[TranslationKey.EMPTY_COMMENT_PROJECT_HISTORY])}
                                    </p>
                                )}
                                <IconButton
                                    style={{ maxWidth: '16px' }}
                                    fill={uiColors.darkGrey}
                                    noHover
                                    key={`edit-comment-item-${version}`}
                                    name='editSimple'
                                    onClick={handleStartEdit}
                                />
                            </SProjectHistoryItemComment>
                        )}
                    </div>
                    {isEditing && (
                        <div>
                            <Button size={ESizeButton.SMALL} variant={EVariantButton.PRIMARY} onClick={handleSaveEdit}>
                                {formatMessage(messages[TranslationKey.SAVE])}
                            </Button>
                            <Button
                                size={ESizeButton.SMALL}
                                variant={EVariantButton.SECONDARY}
                                onClick={handleCancelEdit}
                            >
                                {formatMessage(messages[TranslationKey.CANCEL])}
                            </Button>
                        </div>
                    )}
                </SProjectHistoryItemBody>
                <SProjectHistoryItemControl>
                    <Dropdown
                        clickOutside
                        trigger={<Icon key={`project-history-${version}`} name={'dots'} fill={uiColors.darkGrey} />}
                    >
                        <IconButton
                            fill={uiColors.darkGrey}
                            text={formatMessage(messages[TranslationKey.PROJECT_HISTORY_LOAD_VERSION])}
                            noHover
                            key={`open-item-${version}`}
                            name='play'
                            onClick={() => onOpen(version, date)}
                        />
                        {!active && (
                            <IconButton
                                fill={uiColors.darkGrey}
                                text={formatMessage(messages[TranslationKey.PROJECT_HISTORY_PREVIEW_VERSION])}
                                noHover
                                key={`view-item-${version}`}
                                name='eyeOpen'
                                onClick={() => onPreview(version, date, uuid)}
                            />
                        )}
                        {IS_SHOW_REMOVE_PROJECT_HISTORY_BUTTON && (
                            <IconButton
                                fill={uiColors.darkGrey}
                                text={formatMessage(messages[TranslationKey.DELETE])}
                                noHover
                                key={`delete-item-${version}`}
                                name='delete'
                                onClick={() => onDelete(version, date)}
                            />
                        )}
                    </Dropdown>
                </SProjectHistoryItemControl>
            </SProjectHistoryItem>
        );
    }
);
