import React, { Children, FC, useEffect, useRef, useState } from 'react';

import { SDropdownContainer, SDropdownMenu, SDropdownMenuItem, SDropdownTrigger } from './SDropdown';

interface IDropdown {
    trigger: React.ReactNode | React.ReactElement;
    children: React.ReactNode;
    clickOutside?: boolean;
    onMouseOver?: (event: React.MouseEvent) => void;
}

export const Dropdown: FC<IDropdown> = ({ children, trigger, clickOutside = false, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (clickOutside) {
            const handleClickOutside = (event: MouseEvent) => {
                if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                    setIsOpen(false);
                }
            };

            document.body.addEventListener('click', handleClickOutside);
            return () => {
                document.body.removeEventListener('click', handleClickOutside);
            };
        }
        return;
    }, [clickOutside]);

    const handleTriggerClick = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleOnMouseUp = () => {
        setIsOpen(false);
    };

    const DropdownChildren =
        children && Children.map(children, (child) => <SDropdownMenuItem>{child}</SDropdownMenuItem>);

    return (
        <SDropdownContainer ref={dropdownRef}>
            <SDropdownTrigger data-open={isOpen} onClick={handleTriggerClick}>
                {trigger}
            </SDropdownTrigger>
            <SDropdownMenu onMouseUp={handleOnMouseUp} role='menu' isOpen={isOpen} {...rest}>
                {DropdownChildren}
            </SDropdownMenu>
        </SDropdownContainer>
    );
};
