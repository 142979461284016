import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { ApplicationActions } from '@repeat/common-slices';
import { API_ALL_VMS_ARE_BUSY, API_NOT_FOUND_CODE, API_VM_IS_LOCKED_CODE, extractNumbers } from '@repeat/constants';
import {
    ICodeGeneratingData,
    IModelData,
    IProjectData,
    IWorkspaceState,
    InvalidConnectionError,
    InvalidConnectionsError,
    ModelStatus,
    ModelStatuses,
    NeedToUpdateNodeError,
    NeedToUpdateNodesError,
    NotificationTypes,
    Statuses,
    TModelConnection,
    TSchemaConnection,
    TSchemaNode,
} from '@repeat/models';
import { IDownloadFileParams, ModelService, prepareModelBlockData } from '@repeat/services';
import { workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { actions, initialState } from '..';
import { AppDispatch, RootStateFn } from '../../../store';

export const modelControlReducers = {
    setModelStatus: (state: IWorkspaceState, action: PayloadAction<ModelStatus>) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: action.payload,
        },
    }),
    setModelTime: (state: IWorkspaceState, action: PayloadAction<number>) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelTime: action.payload,
        },
    }),
    runProjectRequest: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.INIT,
            modelTime: initialState.modelControl.modelTime,
            runProject: {
                ...state.modelControl.runProject,
                status: Statuses.LOADING,
            },
        },
    }),
    runProjectSuccess: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.INIT,
            runProject: {
                ...state.modelControl.runProject,
                status: Statuses.SUCCEEDED,
            },
        },
    }),
    runProjectFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.STOP,
            runProject: {
                status: Statuses.FAILED,
                error: action.payload.error,
            },
        },
    }),
    stopProjectRequest: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            stopProject: {
                ...state.modelControl.stopProject,
                status: Statuses.LOADING,
            },
        },
    }),
    stopProjectSuccess: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.STOP,
            stopProject: {
                ...state.modelControl.stopProject,
                status: Statuses.SUCCEEDED,
            },
        },
    }),
    stopProjectFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            stopProject: {
                ...state.modelControl.stopProject,
                status: Statuses.FAILED,
                error: action.payload.error,
            },
        },
    }),
    softStopProjectRequest: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            softStopProject: {
                ...state.modelControl.softStopProject,
                status: Statuses.LOADING,
            },
        },
    }),
    softStopProjectSuccess: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.DATA,
            softStopProject: {
                ...state.modelControl.softStopProject,
                status: Statuses.SUCCEEDED,
            },
        },
    }),
    softStopProjectFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            softStopProject: {
                ...state.modelControl.softStopProject,
                status: Statuses.FAILED,
                error: action.payload.error,
            },
        },
    }),
    freezeProjectRequest: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            freezeProject: {
                ...state.modelControl.freezeProject,
                status: Statuses.LOADING,
            },
        },
    }),
    freezeProjectSuccess: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.FREEZE,
            freezeProject: {
                ...state.modelControl.freezeProject,
                status: Statuses.SUCCEEDED,
            },
        },
    }),
    freezeProjectFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            freezeProject: {
                ...state.modelControl.freezeProject,
                status: Statuses.FAILED,
                error: action.payload.error,
            },
        },
    }),
    continueProjectRequest: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.INIT,
            runProject: {
                ...state.modelControl.runProject,
                status: Statuses.LOADING,
            },
        },
    }),
    continueProjectSuccess: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.RUN,
            runProject: {
                ...state.modelControl.runProject,
                status: Statuses.SUCCEEDED,
            },
        },
    }),
    continueProjectFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.STOP,
            runProject: {
                status: Statuses.FAILED,
                error: action.payload.error,
            },
        },
    }),
    generateSourcesCodeRequest: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.CODE_GENERATION,
            generateSourcesCode: {
                ...state.modelControl.generateSourcesCode,
                status: Statuses.LOADING,
            },
        },
    }),
    generateSourcesCodeSuccess: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.STOP,
            generateSourcesCode: {
                ...state.modelControl.generateSourcesCode,
                status: Statuses.SUCCEEDED,
            },
        },
    }),
    generateSourcesCodeFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.STOP,
            generateSourcesCode: {
                status: Statuses.FAILED,
                error: action.payload.error,
            },
        },
    }),
    generateSourcesCodeCancel: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            modelStatus: ModelStatuses.STOP,
            generateSourcesCode: {
                ...initialState.modelControl.generateSourcesCode,
            },
        },
    }),
    generateNoNameFileRequest: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            generateNoNameFile: {
                ...state.modelControl.generateNoNameFile,
                status: Statuses.LOADING,
            },
        },
    }),
    generateNoNameFileSuccess: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            generateNoNameFile: {
                ...state.modelControl.generateNoNameFile,
                status: Statuses.SUCCEEDED,
            },
        },
    }),
    generateNoNameFileFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            generateNoNameFile: {
                status: Statuses.FAILED,
                error: action.payload.error,
            },
        },
    }),
    generateNoNameFileCancel: (state: IWorkspaceState) => ({
        ...state,
        modelControl: {
            ...state.modelControl,
            generateNoNameFile: {
                ...initialState.modelControl.generateNoNameFile,
            },
        },
    }),
};

const validateSchema = (elements: TSchemaNode[], connections: TSchemaConnection[]): void => {
    const needToUpdateNodesCount = elements.filter((node: TSchemaNode) => node.data.isNeedToUpdate === true).length;
    const invalidConnectionsCount = connections.filter(
        (connection: TSchemaConnection) => connection.data.isValidConnection === false
    ).length;
    if (needToUpdateNodesCount > 0) {
        if (needToUpdateNodesCount == 1) {
            throw new NeedToUpdateNodeError();
        } else {
            throw new NeedToUpdateNodesError();
        }
    }

    if (invalidConnectionsCount > 0) {
        if (invalidConnectionsCount == 1) {
            throw new InvalidConnectionError();
        } else {
            throw new InvalidConnectionsError();
        }
    }
};

export const portIdSplit = (id: string | null | undefined) => {
    if (!id) {
        return {
            elementId: 0,
            portNumber: 0,
            portName: '',
        };
    }

    const data = id.split('-');
    return {
        elementId: Number(data[0]),
        portNumber: extractNumbers(data[2]),
        portName: data[2],
    };
};

export const prepareModelData = (projectData: IProjectData): IModelData => {
    const filteredElements: TSchemaNode[] = projectData.data.elements.filter((el) => !el.data.isViewOnly);

    return {
        ...projectData,
        header: { ...projectData.header },
        data: {
            elements: filteredElements.map((node: TSchemaNode) => prepareModelBlockData(node)),
            wires: projectData.data.wires.map((connection: TSchemaConnection) => ({
                id: Number(connection.id),
                firstElement: Number(connection.source),
                firstPort: portIdSplit(connection.sourceHandle).portName,
                secondElement: Number(connection.target),
                secondPort: portIdSplit(connection.targetHandle).portName,
                type: connection.data?.type,
                wireParams: connection.data?.wireParams,
                wireProps: connection.data?.wireProps,
            })),
            groups: projectData.data.groups.map((group) => {
                const elements = group.elements
                    .filter((el) => !el.data.isViewOnly)
                    .map((el) => prepareModelBlockData(el));
                const wires = group.wires.map((connection: TSchemaConnection) => {
                    return {
                        id: Number(connection.id),
                        firstElement: Number(connection.source),
                        firstPort: portIdSplit(connection.sourceHandle).portName,
                        secondElement: Number(connection.target),
                        secondPort: portIdSplit(connection.targetHandle).portName,
                        type: connection.data?.type,
                        wireParams: connection.data?.wireParams,
                        wireProps: connection.data?.wireProps,
                    } as TModelConnection;
                });
                return { ...group, elements, wires };
            }),
        },
    };
};

const handleModelErrors =
    (error: Error | AxiosError, defaultCallback: (error: Error | AxiosError) => void) =>
    async (dispatch: AppDispatch) => {
        if (error instanceof NeedToUpdateNodeError) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.SCHEMA_HAS_NOT_SUPPORTED_ELEMENT,
                    },
                })
            );
            return;
        }
        if (error instanceof NeedToUpdateNodesError) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.SCHEMA_HAS_NOT_SUPPORTED_ELEMENTS,
                    },
                })
            );
            return;
        }
        if (error instanceof InvalidConnectionError) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.SCHEMA_HAS_INVALID_CONNECTION,
                    },
                })
            );
            return;
        }
        if (error instanceof InvalidConnectionsError) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.SCHEMA_HAS_INVALID_CONNECTIONS,
                    },
                })
            );
            return;
        }

        if (axios.isAxiosError(error)) {
            if (error.response?.status === API_ALL_VMS_ARE_BUSY) {
                const errorKey = TranslationKey.WARNING_ALL_VMS_ARE_BUSY;

                dispatch(actions.runProjectFailed({ error: errorKey }));
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: errorKey,
                        },
                    })
                );
                return;
            } else if (error.response?.status === API_VM_IS_LOCKED_CODE) {
                const errorKey = TranslationKey.WARNING_VM_IS_IN_USE;

                dispatch(actions.runProjectFailed({ error: errorKey }));
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: errorKey,
                        },
                    })
                );
                return;
            }
        }

        defaultCallback(error);
    };

export const runProject = (id: number) => async (dispatch: AppDispatch, getState: RootStateFn) => {
    try {
        const {
            schema: {
                solverType,
                libraryType,
                schemaItems: { elements, wires, groups },
            },
            settings,
        } = getState().workspace;
        const task = getState().task;

        if (!solverType || !libraryType) {
            return;
        }
        validateSchema(elements, wires);

        dispatch(actions.runProjectRequest());
        const modelData = prepareModelData({
            data: { elements, wires, groups: groups || [] },
            projectId: id,
            header: { ...task, ...settings },
            libraryType,
            solverType,
        });

        const response = await ModelService.runProject(modelData);

        dispatch(workspaceActions.setElementParameters(response.data.elements));
        dispatch(actions.runProjectSuccess());
    } catch (error: any) {
        dispatch(
            handleModelErrors(error, (error) => {
                const errorKey = TranslationKey.ERROR_RUN_PROJECT;

                console.error(error);

                dispatch(actions.runProjectFailed({ error: errorKey }));
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.ERROR,
                            message: errorKey,
                        },
                    })
                );
            })
        );
    }
};

export const runDemoProject = (id: number) => async (dispatch: AppDispatch, getState: RootStateFn) => {
    try {
        const {
            schema: {
                schemaItems: { elements, wires },
            },
        } = getState().workspace;
        validateSchema(elements, wires);

        dispatch(actions.runProjectRequest());

        const modelData = {
            projectId: id,
        };

        const response = await ModelService.runDemoProject(modelData);

        dispatch(workspaceActions.setElementParameters(response.data.elements));

        dispatch(actions.runProjectSuccess());
    } catch (error: any) {
        dispatch(
            handleModelErrors(error, (error) => {
                const errorKey = TranslationKey.ERROR_RUN_PROJECT;

                dispatch(actions.runProjectFailed({ error: errorKey }));
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.ERROR,
                            message: errorKey,
                        },
                    })
                );
            })
        );
    }
};

// TODO make using AppDispatch again
export const stopProject = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    dispatch(actions.stopProjectRequest());
    try {
        await ModelService.stopProject();
        dispatch(actions.stopProjectSuccess());
    } catch (error: any) {
        const errorKey = TranslationKey.ERROR_STOP_PROJECT;
        dispatch(actions.stopProjectFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: errorKey,
                },
            })
        );
        if (error.response.status === API_NOT_FOUND_CODE) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_NOT_FOUND_CODE,
                    },
                })
            );
        } else {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_UNKNOWN,
                    },
                })
            );
        }
    }
};

export const softStopProject = () => async (dispatch: AppDispatch) => {
    dispatch(actions.softStopProjectRequest());
    try {
        await ModelService.softStopProject();
        dispatch(actions.softStopProjectSuccess());
    } catch (error: any) {
        const errorKey = TranslationKey.ERROR_STOP_PROJECT;
        dispatch(actions.softStopProjectFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: TranslationKey.ERROR_STOP_PROJECT,
                },
            })
        );
        if (error.response.status === API_NOT_FOUND_CODE) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_NOT_FOUND_CODE,
                    },
                })
            );
        } else {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_UNKNOWN,
                    },
                })
            );
        }
    }
};

export const freezeProject =
    ({ projectId, nameVar, newValue }: { projectId: number; nameVar: string; newValue: number }) =>
    async (dispatch: AppDispatch) => {
        dispatch(actions.freezeProjectRequest());
        try {
            await ModelService.freezeProject({ projectId, nameVar, newValue });
            dispatch(actions.freezeProjectSuccess());
        } catch (error) {
            const errorKey = TranslationKey.ERROR_FREEZE_PROJECT;
            dispatch(actions.freezeProjectFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };
export const continueProject =
    ({ projectId, nameVar, newValue }: { projectId: number; nameVar: string; newValue: number }) =>
    async (dispatch: AppDispatch) => {
        dispatch(actions.continueProjectRequest());
        try {
            await ModelService.freezeProject({ projectId, nameVar, newValue });
            dispatch(actions.continueProjectSuccess());
        } catch (error) {
            const errorKey = TranslationKey.ERROR_RUN_PROJECT;
            dispatch(actions.continueProjectFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

export const generateSourcesCode =
    ({
        projectId,
        projectName,
        codeGenType,
        onDownload,
        abortController,
    }: {
        projectId: number;
        projectName: string;
        codeGenType: string;
        onDownload: (localUrl: string, fileExtension: string) => void;
        abortController?: AbortController;
    }) =>
    async (dispatch: AppDispatch, getState: RootStateFn) => {
        try {
            const {
                schema: {
                    solverType,
                    libraryType,
                    schemaItems: { elements, wires, groups },
                },
                settings,
            } = getState().workspace;
            const task = getState().task;

            if (!solverType || !libraryType) {
                return;
            }

            validateSchema(elements, wires);

            const modelData = {
                ...prepareModelData({
                    data: { elements, wires, groups: groups || [] },
                    projectId,
                    header: { ...task, ...settings },
                    libraryType,
                    solverType,
                }),
                projectName,
                codeGenType,
            } as ICodeGeneratingData;
            const params: IDownloadFileParams = {
                onLoadStart: () => {
                    dispatch(actions.generateSourcesCodeRequest());
                },
                onLoadSuccess: (localUrl: string, fileExtension: string) => {
                    onDownload(localUrl, fileExtension);
                    dispatch(actions.generateSourcesCodeSuccess());
                },
                onLoadError: (error) => {
                    const errorKey = TranslationKey.ERROR_GENERATE_SOURCES;

                    dispatch(actions.generateSourcesCodeFailed({ error: errorKey }));
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.ERROR,
                                message: errorKey,
                            },
                        })
                    );
                },
                abortController,
            };

            await ModelService.generateProjectCode(modelData, params);

            return;
        } catch (error: any) {
            dispatch(
                handleModelErrors(error, (error) => {
                    const errorKey = TranslationKey.ERROR_GENERATE_SOURCES;

                    dispatch(actions.generateSourcesCodeFailed({ error: errorKey }));
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.ERROR,
                                message: errorKey,
                            },
                        })
                    );
                })
            );
        }
    };

export const generateNoNameFile =
    ({
        projectId,
        onDownload,
        abortController,
    }: {
        projectId: number;
        onDownload: (localUrl: string, fileExtension: string) => void;
        abortController?: AbortController;
    }) =>
    async (dispatch: AppDispatch, getState: RootStateFn) => {
        try {
            const {
                schema: {
                    solverType,
                    libraryType,
                    schemaItems: { elements, wires, groups },
                },
                settings,
            } = getState().workspace;
            const task = getState().task;

            if (!solverType || !libraryType) {
                return;
            }

            validateSchema(elements, wires);

            const modelData = prepareModelData({
                data: { elements, wires, groups: groups || [] },
                projectId,
                header: { ...task, ...settings },
                libraryType,
                solverType,
            });
            const params: IDownloadFileParams = {
                onLoadStart: () => {
                    dispatch(actions.generateNoNameFileRequest());
                },
                onLoadSuccess: (localUrl: string, fileExtension: string) => {
                    onDownload(localUrl, fileExtension);
                    dispatch(actions.generateNoNameFileSuccess());
                },
                onLoadError: (error: any) => {
                    const errorKey = TranslationKey.ERROR_GENERATE_NONAME_FILE;

                    dispatch(actions.generateNoNameFileFailed({ error: errorKey }));
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.ERROR,
                                message: errorKey,
                            },
                        })
                    );
                },
                abortController,
            };

            await ModelService.generateProjectNoNameFile(modelData, params);

            return;
        } catch (error: any) {
            dispatch(
                handleModelErrors(error, (error) => {
                    const errorKey = TranslationKey.ERROR_GENERATE_NONAME_FILE;

                    dispatch(actions.generateNoNameFileFailed({ error: errorKey }));
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.ERROR,
                                message: errorKey,
                            },
                        })
                    );
                })
            );
        }
    };
