import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { sanitizeFilename } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { EUserPermissions, ModelStatus, ModelStatuses, Statuses } from '@repeat/models';
import { generateSourcesCode, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, LoaderDefault, Tooltip, uiColors } from '@repeat/ui-kit';

import { messages } from './translation';

const DOWNLOAD_LOADER_ICON_SIZE = 26;

export const MenuCodeGenerationItem: FC<{ projectId: number; projectName: string }> = ({ projectId, projectName }) => {
    const { formatMessage } = useIntl();

    const dispatch = useAppDispatch();

    const livePermissions = useAppSelector((state) => state.workspace.livePermissions.permissions);
    const modelStatus = useAppSelector<ModelStatus>((state) => state.workspace.modelControl.modelStatus);
    const isDownloading = useAppSelector<boolean>(
        (state) => state.workspace.modelControl.generateSourcesCode.status === Statuses.LOADING
    );

    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const [activeType, setActiveType] = useState<string | null>(null);

    useEffect(() => {
        if (abortController && abortController.signal.aborted) {
            setAbortController(new AbortController());
        }

        if (!isDownloading) {
            setActiveType(null);
        }

        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [abortController, isDownloading]);

    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        setIsEnabled(modelStatus === ModelStatuses.STOP && livePermissions[EUserPermissions.CODE_GENERATION]);
    }, [modelStatus, livePermissions]);

    const handleDownload = (localUrl: string, fileExtension: string) => {
        const anchor: HTMLAnchorElement = document.createElement('a');
        anchor.href = localUrl;
        anchor.download = `${sanitizeFilename(projectName)}-sources.${fileExtension}`;

        document.body.appendChild(anchor);
        anchor.click();

        URL.revokeObjectURL(localUrl);
    };

    const handleGenerateSources = (codeGenType: string) => {
        const controller = new AbortController();

        setAbortController(controller);

        setActiveType(codeGenType);

        dispatch(
            generateSourcesCode({
                projectId,
                projectName,
                codeGenType,
                onDownload: handleDownload,
                abortController: controller,
            })
        );
    };
    const handleCancelGenerateSources = () => {
        if (abortController) {
            abortController.abort();
        }
        setActiveType(null);
        dispatch(workspaceActions.generateSourcesCodeCancel());
    };

    const getTooltipMessage = useCallback(
        (type: string) => {
            if (isDownloading) {
                return formatMessage(messages[TranslationKey.WORKSPACE_GENERATE_SOURCES_CANCEL]);
            }
            if (!isEnabled && livePermissions[EUserPermissions.CODE_GENERATION]) {
                return formatMessage(messages[TranslationKey.WORKSPACE_GENERATE_SOURCES_NOT_AVAILABLE]);
            }
            if (!isEnabled && !livePermissions[EUserPermissions.CODE_GENERATION]) {
                return formatMessage(messages[TranslationKey.WORKSPACE_GENERATE_SOURCES_NO_LIVE_PERMISSION]);
            }

            if (type === 'usds') {
                return formatMessage(messages[TranslationKey.WORKSPACE_GENERATE_SOURCES_USDS]);
            }

            return formatMessage(messages[TranslationKey.WORKSPACE_GENERATE_SOURCES_COMMON]);
        },
        [isEnabled, isDownloading]
    );

    return (
        <>
            <Tooltip text={getTooltipMessage('usds')}>
                {isDownloading && activeType === 'usds' && (
                    <LoaderDefault
                        color={uiColors.black}
                        width={DOWNLOAD_LOADER_ICON_SIZE}
                        height={DOWNLOAD_LOADER_ICON_SIZE}
                        onClick={handleCancelGenerateSources}
                    />
                )}
                {!(isDownloading && activeType === 'usds') && (
                    <IconButton
                        fill={uiColors.darkGrey}
                        noHover
                        name='fileCode'
                        onClick={() => handleGenerateSources('usds')}
                        disabled={!isEnabled}
                    />
                )}
            </Tooltip>
            <Tooltip text={getTooltipMessage('common')}>
                {isDownloading && activeType === 'common' && (
                    <LoaderDefault
                        color={uiColors.black}
                        width={DOWNLOAD_LOADER_ICON_SIZE}
                        height={DOWNLOAD_LOADER_ICON_SIZE}
                        onClick={handleCancelGenerateSources}
                    />
                )}
                {!(isDownloading && activeType === 'common') && (
                    <IconButton
                        fill={uiColors.darkGrey}
                        noHover
                        name='fileCodeC'
                        onClick={() => handleGenerateSources('common')}
                        disabled={!isEnabled}
                    />
                )}
            </Tooltip>
        </>
    );
};
