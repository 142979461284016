import { FC } from 'react';

import styled from 'styled-components';

import { uiColors } from '../../config/config';
import { hexToRGB } from '../../config/helpers';
import { Loader } from '../Loader/Loader';

const SLoaderWrapper = styled.div<{ overlay: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    ${({ overlay }) => overlay && `background: ${hexToRGB(uiColors.darkBlue, '0.1')}; backdrop-filter: blur(4px);`};

    img {
        max-height: 72px;
        max-width: 72px;
    }
`;

export const LoaderFullscreen: FC<{ overlay?: boolean }> = ({ overlay = true }) => {
    return (
        <SLoaderWrapper overlay={overlay}>
            <Loader />
        </SLoaderWrapper>
    );
};
