import { TPortPositionType } from './canvas';
import { ILibraryItem, TLibraryType, TSolverType } from './libraryItem';

export interface IElementPropsBase {
    description: string;
    name: string;
    value: string | number;
    help?: { description: string; image: string } | null;
    visibilityConditions?: TPropertyVisibilityCondition[];
}

export type TPropertyValue = {
    title: string;
    value: number | string;
};
export enum PropertyTypes {
    TOGGLE = 'toggle',
    SELECT = 'select',
    MULTISELECT = 'multiselect',
    NUMBER = 'number',
    CODE = 'code',
    TABLE = 'table',
    VARIABLES = 'variables',
}
export type TPropertyType =
    | PropertyTypes.VARIABLES
    | PropertyTypes.TABLE
    | PropertyTypes.TOGGLE
    | PropertyTypes.SELECT
    | PropertyTypes.NUMBER
    | PropertyTypes.CODE
    | PropertyTypes.MULTISELECT;

type TExtendedConditionValue = {
    values: string[];
    comparison: string[];
};

export type TPropertyVisibilityCondition = {
    [key: string]: string[];
};

export interface IPropertyAvailableValuesSrcFilter {
    libraries?: string[];
    blocksExceptions?: string[];
    stateParameters?: string[];
}
export interface IPropertyAvailableValuesSrc {
    method: string;
    filter?: IPropertyAvailableValuesSrcFilter | null;
}

export type ElemProps = IElementPropsBase & {
    editable: boolean;
    availableValues?: TPropertyValue[];
    availableValuesSrc?: IPropertyAvailableValuesSrc | null;
    dependency?: string | null;
    isStatic?: boolean;
    type?: TPropertyType;
    visibilityConditions?: TPropertyVisibilityCondition[];
    rules?: { [key: string]: string }[];
    language?: string;
    isPortsManager?: boolean;
};
export type ElemParams = IElementPropsBase & {
    modelName: string;
    unit?: string;
    connectionType?: string;
    isVisible?: boolean;
};
export type TSubmodelProxyItem = { name: string; internalBlockId: number; internalName: string };
export type TProxyMap = { params: TSubmodelProxyItem[]; props: TSubmodelProxyItem[] };
export type TUserBlockData = { name: string; subLibrary: string; description?: string; image?: string | null };

export type ElemPropsView = ElemProps & {
    minValue?: string | undefined;
    maxValue?: string | undefined;
    isVisible: boolean;
};

export type TElementPortType = 'in' | 'out';

export interface IElementPort {
    isUsed?: boolean;
    hasMultipleConnections?: boolean;
    num: number;
    x: number;
    y: number;
    type?: TElementPortType | string; // TODO: add port types for electrocity, electrocityDC elements
    connectionType?: string;
    positionType: TPortPositionType;
}

export interface IElementConfiguration {
    id: number | null;
    name: string;
    description: string;
    elementProperties: ElemProps[];
    isSelected: boolean;
}

export interface IElement extends Omit<ILibraryItem, 'shortName' | 'index' | 'isActive' | 'id'> {
    tint: string;
    x: number;
    y: number;
    id: number;
    indicator: { connectedElementId: number; parameter: string; unitsPropValue: string };
    size: number;
    value: number | string;
    valueTask: number;
    valueMin: number;
    valueMax: number;
    new?: boolean;
    angle: number;
    warning: boolean;
    state: string;
    indexDisplay: string;
    ports: IElementPort[];
    wireId?: number;
    library: TLibraryType;
    solver: TSolverType;
    isIndicator?: boolean;
    availableConfigurations?: IElementConfiguration[];
    selectedConfiguration?: IElementConfiguration;
    fileName?: string;
}

export interface IPortType {
    type: string;
    connectionType?: string;
}
export interface IPortOptions {
    x: number;
    y: number;
    type: string;
    positionType: TPortPositionType;
}
export type TPort = IElementPort & { num: number };

export interface IUsedElementsPortsMap {
    [key: number]: number[];
}

export interface IElementInitializationData {
    name: string;
    uuid: string;
    elemParams: ElemParams[];
    elemProps: ElemProps[];
}
