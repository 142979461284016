import { memo, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { MEMORY_USAGE_LIMIT, TOOLTIP_SHOW_DELAY } from '@repeat/constants';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { ModelStatus, ModelStatuses, NotificationTypes, WorkspaceModes } from '@repeat/models';
import {
    continueProject,
    elementsSelectors,
    freezeProject,
    runDemoProject,
    runProject,
    softStopProject,
    stopProject,
    workspaceSelectors,
} from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, LoaderDefault, Tooltip, TooltipPosition } from '@repeat/ui-kit';

import { ModelControlTime } from './ModelControlTime';
import {
    SModelControlItem,
    SModelControlItems,
    SModelControlStatus,
    SModelControlWrapper,
} from './StyledModelControls';
import { messages } from './translation';

import { memoryCalculation as handleCalculation } from '../ProjectSettings/memoryCalculation';

const TOOLTIP_STATUS_HELP_WIDTH = 200;

export const ModelControl = memo(() => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const { projectId } = useProjectId();

    const modelStatus = useAppSelector<ModelStatus>((state) => state.workspace.modelControl.modelStatus);
    const stopProjectStatus = useAppSelector(workspaceSelectors.stopProjectStatus);
    const elements = useAppSelector(elementsSelectors.getElements);
    const projectSettings = useAppSelector(workspaceSelectors.getProjectSettings);
    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);

    const [calculation, setCalculation] = useState(0);

    useEffect(() => {
        handleCalculation(elements, projectSettings, setCalculation);
    }, [elements, projectSettings]);

    const [isTurnedOn, setIsTurnedOn] = useState(false);
    const pauseDisabled = !isTurnedOn || modelStatus === ModelStatuses.STOP;
    const runDisabled = modelStatus === ModelStatuses.CODE_GENERATION;

    useEffect(() => {
        if (stopProjectStatus === 'succeeded') {
            setIsTurnedOn(false);
        }
    }, [stopProjectStatus]);

    const buttonClickHandler = useCallback(async () => {
        if (modelStatus === ModelStatuses.STOP) {
            if (calculation >= MEMORY_USAGE_LIMIT) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_MEMORY_LIMIT_ERROR,
                        },
                    })
                );
            }
            if (
                workspaceMode !== WorkspaceModes.DEMO ||
                (workspaceMode === WorkspaceModes.DEMO && workspaceMetaUserBlockId)
            ) {
                projectId && (await dispatch(runProject(projectId)));
            } else {
                projectId && (await dispatch(runDemoProject(projectId)));
            }
        }
        setIsTurnedOn((state) => !state);
        if (modelStatus === ModelStatuses.RUN || modelStatus === ModelStatuses.FREEZE) {
            projectId && (await dispatch(softStopProject()));
        } else if (modelStatus === ModelStatuses.DATA) {
            projectId && (await dispatch(stopProject()));
        }
    }, [modelStatus]);

    const pauseClickHandler = () => {
        if (pauseDisabled) {
            return;
        }
        if (modelStatus === ModelStatuses.RUN) {
            dispatch(freezeProject({ projectId: Number(projectId), nameVar: 'status', newValue: 0 }));
        }
    };

    const continueClickHandler = () => {
        dispatch(continueProject({ projectId: Number(projectId), nameVar: 'status', newValue: 1 }));
    };

    const getMainIconName = useCallback(() => {
        if ([ModelStatuses.STOP, ModelStatuses.CODE_GENERATION].includes(modelStatus)) {
            return 'play';
        }
        if (modelStatus === ModelStatuses.DATA) {
            return 'stop';
        }
        return 'softStop';
    }, [modelStatus]);

    const getStatusHelp = useCallback(() => {
        switch (modelStatus) {
            case ModelStatuses.STOP:
                return formatMessage(messages[TranslationKey.MODEL_STATUS_STOP_HELP]);
            case ModelStatuses.INIT:
                return formatMessage(messages[TranslationKey.MODEL_STATUS_INIT_HELP]);
            case ModelStatuses.RUN:
                return formatMessage(messages[TranslationKey.MODEL_STATUS_RUN_HELP]);
            case ModelStatuses.FREEZE:
                return formatMessage(messages[TranslationKey.MODEL_STATUS_FREEZE_HELP]);
            case ModelStatuses.DATA:
                return formatMessage(messages[TranslationKey.MODEL_STATUS_DATA_HELP]);
            case ModelStatuses.CODE_GENERATION:
                return formatMessage(messages[TranslationKey.MODEL_STATUS_CODE_HELP]);
            default:
                return '';
        }
    }, [modelStatus]);

    const getMainIconHelp = useCallback(() => {
        if ([ModelStatuses.STOP, ModelStatuses.CODE_GENERATION].includes(modelStatus)) {
            return formatMessage(messages[TranslationKey.MODEL_RUN_HELP]);
        }
        if (modelStatus === ModelStatuses.DATA) {
            return formatMessage(messages[TranslationKey.MODEL_STOP_HELP]);
        }
        return formatMessage(messages[TranslationKey.MODEL_SOFT_STOP_HELP]);
    }, [modelStatus]);

    const getFreezeIconHelp = useCallback(() => {
        if (modelStatus === ModelStatuses.FREEZE) {
            return formatMessage(messages[TranslationKey.MODEL_CONTINUE_HELP]);
        }
        return formatMessage(messages[TranslationKey.MODEL_FREEZE_HELP]);
    }, [modelStatus]);

    return (
        <SModelControlWrapper data-tour='model-control-wrapper'>
            <SModelControlItems>
                <ModelControlTime />
                <SModelControlItem>
                    <SModelControlStatus>
                        <Tooltip
                            text={getStatusHelp()}
                            position={TooltipPosition.BOTTOM}
                            delayShow={TOOLTIP_SHOW_DELAY}
                            tooltipWidth={TOOLTIP_STATUS_HELP_WIDTH}
                        >
                            {modelStatus}
                        </Tooltip>
                    </SModelControlStatus>
                </SModelControlItem>
                {modelStatus === ModelStatuses.INIT ? (
                    <SModelControlItem>
                        <LoaderDefault />
                    </SModelControlItem>
                ) : (
                    <SModelControlItem>
                        <Tooltip
                            text={getMainIconHelp()}
                            position={TooltipPosition.BOTTOM}
                            delayShow={TOOLTIP_SHOW_DELAY}
                            tooltipWidth={TOOLTIP_STATUS_HELP_WIDTH}
                        >
                            <IconButton
                                noHover
                                fill={'white'}
                                size='medium'
                                name={getMainIconName()}
                                onClick={buttonClickHandler}
                                data-type={isTurnedOn ? 'stop' : 'run'}
                                data-tour='model-control-start'
                                disabled={runDisabled}
                            />
                        </Tooltip>
                    </SModelControlItem>
                )}
                <SModelControlItem>
                    {modelStatus === ModelStatuses.FREEZE ? (
                        <Tooltip
                            text={getFreezeIconHelp()}
                            position={TooltipPosition.BOTTOM}
                            delayShow={TOOLTIP_SHOW_DELAY}
                            tooltipWidth={TOOLTIP_STATUS_HELP_WIDTH}
                        >
                            <IconButton
                                fill={'white'}
                                noHover
                                size='medium'
                                name='play'
                                onClick={continueClickHandler}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip
                            text={getFreezeIconHelp()}
                            position={TooltipPosition.BOTTOM}
                            delayShow={TOOLTIP_SHOW_DELAY}
                            tooltipWidth={TOOLTIP_STATUS_HELP_WIDTH}
                        >
                            <IconButton
                                fill={'white'}
                                noHover
                                size='medium'
                                name='pause'
                                data-type='pause'
                                onClick={pauseClickHandler}
                                disabled={pauseDisabled}
                            />
                        </Tooltip>
                    )}
                </SModelControlItem>
            </SModelControlItems>
        </SModelControlWrapper>
    );
});
