import { AxiosResponse } from 'axios';

import { IAPIChart } from 'libs/models/src/lib/charts';

import { ElemParams, IChartItem, ICodeGeneratingData, IModelData } from '@repeat/models';

import FileDownloadService, { IDownloadFileParams } from './FileDownloadService';
import { $api } from './http';

interface ReturnRunProjectContract {
    elements: {
        [key: string]: { index: number; params: ElemParams[] };
    };
    wires: {
        [key: string]: { index: number; params: ElemParams[] };
    } | null;
}

type RunProjectPayload = IModelData;
type RunDemoProjectPayload = {
    projectId: number;
};
interface IRunProject {
    (payload: RunProjectPayload, signal?: AbortSignal): Promise<AxiosResponse<ReturnRunProjectContract>>;
}

interface IGetTrendDataPayload {
    projectId: number;
    timeStart: number;
    timeEnd: number | null;
    modelNames: string[];
}
interface IReturnGetTrendDataContract {
    projectId: number;
    modelNames: string[];
    timeStart: number;
    timeEnd: number;
    data: number[][][];
}
interface IGetTrendData {
    (payload: IGetTrendDataPayload, signal?: AbortSignal): Promise<AxiosResponse<IReturnGetTrendDataContract>>;
}

interface IGetChartsDataPayload {
    projectId: number;
    timeStart: number;
    timeEnd: number | null;
    graphs: IChartItem[];
    countGraphs: number;
}
interface IReturnGetChartsDataContract {
    projectId: number;
    countGraphs: number;
    graphs: IAPIChart[];
    data: number[][][] | null;
}
interface IGetChartsData {
    (payload: IGetChartsDataPayload, signal?: AbortSignal): Promise<AxiosResponse<IReturnGetChartsDataContract>>;
}

interface IGenerateProjectCode {
    (payload: ICodeGeneratingData, params?: IDownloadFileParams): void;
}

interface IGenerateProjectNoNameFile {
    (payload: IModelData, params?: IDownloadFileParams): void;
}

export default class ModelService {
    static runProject: IRunProject = async (payload: RunProjectPayload, signal?: AbortSignal) => {
        return await $api.post('/api/v1/modeling/run', payload, { headers: { projectId: payload.projectId }, signal });
    };

    static runDemoProject = async (payload: RunDemoProjectPayload, signal?: AbortSignal) => {
        return await $api.post('/api/v1/examples/run', payload, {
            headers: { projectId: payload.projectId },
            signal,
        });
    };

    static async stopProject(signal?: AbortSignal) {
        const data = {};
        return $api.post('/api/v1/modeling/stop', data, { signal });
    }

    static async softStopProject(signal?: AbortSignal) {
        const data = {};
        return $api.post('/api/v1/modeling/softStop', data, { signal });
    }

    // получение параметров для тренда
    static async getTrendParamsValues<T>(projectId: number, params: any[], signal?: AbortSignal) {
        return $api.post(`/api/v1/modeling/setTrends`, { projectId, params }, { signal });
    }

    // изменение значений переменных модели
    static async freezeProject(
        { projectId, nameVar, newValue }: { projectId: number | string; nameVar: string; newValue: any },
        signal?: AbortSignal
    ) {
        return $api.post(`/api/v1/modeling/setValue`, { projectId, nameVar, newValue }, { signal });
    }

    static getTrendData: IGetTrendData = async (payload: IGetTrendDataPayload, signal?: AbortSignal) => {
        const url = `/api/v3/modeling/getRange?projectId=${payload.projectId}`;
        const requestData: {
            modelNames: string[];
            timeStart: number;
            timeEnd?: number;
        } = {
            modelNames: payload.modelNames,
            timeStart: payload.timeStart,
        };
        if (payload.timeEnd !== null) {
            requestData.timeEnd = payload.timeEnd;
        }

        return await $api.post(url, requestData, { signal });
    };

    static getChartsData: IGetChartsData = async (payload: IGetChartsDataPayload, signal?: AbortSignal) => {
        const url = `/api/v1/modeling/getRange`;
        const requestData: {
            graphs: IChartItem[];
            countGraphs: number;
            timeStart: number;
            timeEnd?: number;
            projectId: number;
        } = {
            graphs: payload.graphs,
            timeStart: payload.timeStart,
            projectId: payload.projectId,
            countGraphs: payload.countGraphs,
        };
        if (payload.timeEnd !== null) {
            requestData.timeEnd = payload.timeEnd;
        }

        return await $api.post(url, requestData, { headers: { projectId: payload.projectId }, signal });
    };

    static getYTrendData: IGetTrendData = async (payload: IGetTrendDataPayload, signal?: AbortSignal) => {
        const url = `/api/v1/modeling/getRangePack`;
        const requestData: {
            modelNames: string[];
            timeStart: number;
            timeEnd?: number;
        } = {
            modelNames: payload.modelNames,
            timeStart: payload.timeStart,
        };
        if (payload.timeEnd !== null) {
            requestData.timeEnd = payload.timeEnd;
        }

        return await $api.post(url, requestData, { signal, headers: { projectId: payload.projectId } });
    };

    static generateProjectCode: IGenerateProjectCode = async (
        modelData: ICodeGeneratingData,
        params?: IDownloadFileParams
    ) => {
        const method = 'post';
        const config = {
            headers: {
                projectId: modelData.projectId,
            },
        };

        const downloadFileParams = params || {};

        await FileDownloadService.downloadFile(
            '/api/v1/modeling/generateSource',
            downloadFileParams,
            method,
            config,
            modelData
        );
    };

    static generateProjectNoNameFile: IGenerateProjectNoNameFile = async (
        modelData: IModelData,
        params?: IDownloadFileParams
    ) => {
        const method = 'post';
        const config = {
            headers: {
                projectId: modelData.projectId,
            },
        };

        const downloadFileParams = params || {};

        await FileDownloadService.downloadFile(
            '/api/v1/modeling/generateRepeatCore',
            downloadFileParams,
            method,
            config,
            modelData
        );
    };
}
