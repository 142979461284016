import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
    getStylesByVariant,
    IS_SHOW_OPTIONS_SAVE_BUTTON,
    METER_ELEMENTS_TYPES,
    sanitizeHTML,
    unicodeToHtml,
} from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ElemProps, TElement, TSchemaNode, WorkspaceModes } from '@repeat/models';
import { workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { SContextMenuItem, TInputVariant } from '@repeat/ui-kit';

import { getPropertiesValuesByNames, getPropsResult } from './ElementProperties';
import { SForm } from './SPropertiesSettings';

import { messages } from '../translation';

export const PropertiesSettings: FC<{ setVisibility: (state: boolean) => void }> = ({ setVisibility }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const prevWorkspaceMode = useAppSelector((state) => state.workspace.meta.previousMode);

    const [chosenElementId, setChosenElementId] = useState<number | string>('');

    const [variant, setVariant] = useState<{ element: TInputVariant; parameter: TInputVariant }>({
        element: 'default',
        parameter: 'default',
    });

    const schemaElementsMain = useAppSelector(workspaceSelectors.schemaElements);
    const externalProperties = useAppSelector((state) => state.workspace.schema.externalInfo?.properties) || [];
    const node = useAppSelector(workspaceSelectors.currentNodeProperties) as TElement;
    const { type } = node;
    const selectedElement = useAppSelector(workspaceSelectors.selectedItems).elements[0];

    const currentNodeProperties = useAppSelector(workspaceSelectors.currentNodeProperties);

    const groupElements = useAppSelector(workspaceSelectors.groupElementsById(selectedElement?.id));
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditorMode);
    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);

    const schemaElements =
        (selectedElement && selectedElement.data.type === 'group') || isUserBlockEditorMode
            ? groupElements
            : schemaElementsMain;

    const filteredElements = schemaElements.filter((el) => {
        const props = el.data.elemProps.filter((prop) => !prop.isStatic || prop.editable);
        return (
            el.data.type !== 'InPort' &&
            el.data.type !== 'OutPort' &&
            !el.data.isViewOnly &&
            props.length !== 0 &&
            !METER_ELEMENTS_TYPES.includes(el.data.type)
        );
    });

    const visibilityConditionsKeys: string[][] = [];
    const currentElementProps =
        filteredElements
            ?.find((el: TSchemaNode) => el.data?.id === Number(chosenElementId))
            ?.data.elemProps.filter((prop) => !prop.isStatic) || [];
    currentElementProps?.forEach((prop) => {
        if (prop.visibilityConditions) {
            prop.visibilityConditions.forEach((condition) => visibilityConditionsKeys.push(Object.keys(condition)));
        }
    });
    const uniqueVisibilityConditionsKeys = Array.from(new Set(visibilityConditionsKeys.flat()));

    const propertiesToCheckUniqueness =
        selectedElement?.data.type === 'group' || workspaceMetaUserBlockId
            ? currentNodeProperties.elemProps
            : externalProperties;

    const findValuesOfManagerProperties = () => {
        const valuesByNames: { [name: string]: string } = {};
        currentElementProps?.forEach((p) => {
            if (uniqueVisibilityConditionsKeys.includes(p.name)) {
                valuesByNames[p.name] = p.value.toString();
            }
        });
        return valuesByNames;
    };

    const optionsForVisibility = getPropertiesValuesByNames(currentElementProps);
    const options = findValuesOfManagerProperties();

    const currentElementPropsTmp = getPropsResult(currentElementProps, optionsForVisibility, options, type);

    const currentElementProperties = currentElementPropsTmp
        ? currentElementPropsTmp.filter((x) => {
              return (
                  !uniqueVisibilityConditionsKeys.includes(x.name) &&
                  x.isPortsManager !== true &&
                  !propertiesToCheckUniqueness.some((y) => `${x.name}-${chosenElementId}` === y.name) &&
                  x.isVisible
              );
          })
        : [];

    useEffect(() => {
        if (currentElementProperties?.length === 0) {
            setVariant({ ...variant, element: 'default' });
        }
    }, [currentElementProperties]);

    const changeElement = (e: SelectChangeEvent) => {
        setChosenElementId(e.target.value);
        setVariant({ ...variant, element: 'warning' });
    };

    const changeProperty = (e: SelectChangeEvent) => {
        if (selectedElement?.data.type === 'group') {
            dispatch(
                workspaceActions.setGroupBlockProperty({
                    groupId: selectedElement.id,
                    elementId: chosenElementId.toString(),
                    name: e.target.value,
                })
            );
        } else if (!selectedElement && isUserBlockEditorMode && workspaceMetaUserBlockId) {
            dispatch(
                workspaceActions.addLibraryItemProperty({
                    blockId: workspaceMetaUserBlockId,
                    elementId: chosenElementId.toString(),
                    name: e.target.value,
                })
            );
        } else {
            dispatch(workspaceActions.addExternalProperty({ id: chosenElementId.toString(), name: e.target.value }));
        }
        setVariant({ element: 'success', parameter: 'success' });
        setVisibility(false);
    };

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (variant.element === 'success' && variant.parameter === 'success') {
            timer = setTimeout(() => {
                setVariant({ element: 'default', parameter: 'default' });
            }, 500);
        }
        if (variant.element === 'warning' && currentElementProperties?.length === 0) {
            timer = setTimeout(() => {
                setVariant({ element: 'default', parameter: 'default' });
            }, 500);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [variant]);

    return (
        <SForm>
            <FormControl style={{ width: '100%', margin: '8px 0 16px' }} size={'small'}>
                <InputLabel id='demo-simple-select-standard-label'>
                    {intl.formatMessage(messages[TranslationKey.WORKSPACE_ELEMENT_TITLE])}
                </InputLabel>
                <Select
                    disabled={
                        workspaceMode === WorkspaceModes.DEMO ||
                        workspaceMode === WorkspaceModes.PREVIEW ||
                        prevWorkspaceMode === WorkspaceModes.DEMO ||
                        prevWorkspaceMode === WorkspaceModes.PREVIEW
                    }
                    labelId='demo-simple-select-standard-label'
                    id='demo-simple-select-standard'
                    label={intl.formatMessage(messages[TranslationKey.WORKSPACE_ELEMENT_TITLE])}
                    value={chosenElementId || ''}
                    onChange={changeElement}
                    sx={variant && getStylesByVariant(variant.element)}
                >
                    {filteredElements && filteredElements.length === 0 ? (
                        <SContextMenuItem value='' key={'workspace.context_menu.item.no_elements'}>
                            <em>{intl.formatMessage(messages[TranslationKey.NO_ELEMENTS])}</em>
                        </SContextMenuItem>
                    ) : (
                        filteredElements?.map((el: { data: { name: string; id: number; index: string } }) => (
                            <SContextMenuItem value={el.data.id} key={`select-element-${el.data.id}-${el.data.index}`}>
                                {el.data.name} {el.data.index}
                            </SContextMenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
            <FormControl disabled={!chosenElementId} style={{ width: '100%', margin: '0 0 16px' }} size={'small'}>
                <InputLabel id='demo-simple-select-standard-label'>
                    {intl.formatMessage(messages[TranslationKey.WORKSPACE_PROPERTY])}
                </InputLabel>
                <Select
                    disabled={
                        workspaceMode === WorkspaceModes.DEMO ||
                        workspaceMode === WorkspaceModes.PREVIEW ||
                        prevWorkspaceMode === WorkspaceModes.DEMO ||
                        prevWorkspaceMode === WorkspaceModes.PREVIEW
                    }
                    sx={variant && getStylesByVariant(variant.parameter)}
                    labelId='demo-simple-select-standard-label'
                    id='demo-simple-select-standard'
                    label={intl.formatMessage(messages['workspace.context_menu.parameter'])}
                    onChange={changeProperty}
                    value={''}
                >
                    {!currentElementProperties || currentElementProperties?.length === 0 ? (
                        <SContextMenuItem value=''>
                            <em>{intl.formatMessage(messages['workspace.context_menu.item.no_parameters'])}</em>
                        </SContextMenuItem>
                    ) : (
                        currentElementProperties.map((el: ElemProps) => {
                            const itemText = `${unicodeToHtml(el.description)}`;
                            return (
                                <SContextMenuItem value={el.name} key={`select-${el.name}`}>
                                    <span dangerouslySetInnerHTML={sanitizeHTML(itemText)} />
                                </SContextMenuItem>
                            );
                        })
                    )}
                </Select>
            </FormControl>
            {IS_SHOW_OPTIONS_SAVE_BUTTON && (
                <Button type='submit'>{intl.formatMessage(messages[TranslationKey.SAVE])}</Button>
            )}
        </SForm>
    );
};
