import { FC } from 'react';
import { useIntl } from 'react-intl';

import { sanitizeHTML, unicodeToHtml } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ElemParams, TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { deleteParameter, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Tooltip, TooltipPosition, TooltipVariant, uiColors } from '@repeat/ui-kit';

import {
    SIcon,
    SIconButton,
    SParameterDescription,
    SParameterModelName,
    SParametersItem,
    SParameterValue,
    SPoint,
} from './SItemParameter';
import { TooltipContent } from './TooltipContent';

import { useReadOnlyMode } from '../../../hooks/useReadOnlyMode';
import { messages } from '../translation';

interface IItemParameter {
    parameter: ElemParams;
    isManaged?: boolean;
    isConnected?: boolean;
}

export const ItemParameter: FC<IItemParameter> = (props) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const { parameter, isManaged, isConnected, ...rest } = props;
    const elementId = useAppSelector((state) => state.workspace.meta.elementId);
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const prevWorkspaceMode = useAppSelector((state) => state.workspace.meta.previousMode);
    const isUserBlockEditor = useAppSelector(workspaceSelectors.isUserBlockEditor);

    const { isReadOnlyMode } = useReadOnlyMode(workspaceMode, prevWorkspaceMode as TWorkspaceMode);

    if (!parameter) {
        return null;
    }

    const { description, value, unit, help, name, modelName } = parameter;
    const parameterUnit = unit && unit?.length !== 0 ? `, ${unicodeToHtml(unit)}` : '';

    const parameterDescription =
        isManaged && workspaceMode !== WorkspaceModes.CODE_EDITOR && !isUserBlockEditor
            ? `${name} / ${description}`
            : description;

    return (
        <SParametersItem {...rest}>
            <div>
                <SParameterDescription>
                    {parameterDescription}
                    <span dangerouslySetInnerHTML={sanitizeHTML(parameterUnit)} />
                    {help && (
                        <Tooltip
                            text={<TooltipContent help={help} />}
                            position={TooltipPosition.LEFT}
                            variant={TooltipVariant.LIGHT}
                            tooltipWidth={200}
                        >
                            <SIcon name='help' fill={uiColors.darkGrey} />
                        </Tooltip>
                    )}
                    {modelName && <SParameterModelName> [{modelName}]</SParameterModelName>}
                    {isManaged && isConnected && (
                        <Tooltip
                            text={intl.formatMessage(
                                messages[TranslationKey.WORKSPACE_RIGHT_SIDEBAR_WARNING_DELETE_CONNECTED_PORT]
                            )}
                            position={TooltipPosition.TOP}
                            variant={TooltipVariant.LIGHT}
                            tooltipWidth={200}
                        >
                            <SPoint />
                        </Tooltip>
                    )}
                </SParameterDescription>
                {isReadOnlyMode && !isManaged && workspaceMode !== WorkspaceModes.SUBMODEL && (
                    <SParameterValue>{value || value === 0 ? value : '-'}</SParameterValue>
                )}
            </div>
            {isManaged && workspaceMode === WorkspaceModes.CODE_EDITOR && (
                <SIconButton
                    noHover
                    fill={uiColors.red}
                    name='minus'
                    onClick={() => {
                        elementId && dispatch(deleteParameter({ id: elementId, parameterName: parameter.name }));
                    }}
                />
            )}
        </SParametersItem>
    );
};
