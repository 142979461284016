import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { ModalTypes, Statuses, TBlockFileUpload } from '@repeat/models';
import { workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Modal } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

import { ELEMENTS_WITH_INITIALIZATION } from '@repeat/constants';
import {
    initializeElementByFile,
    initializeUpdatedElement,
    updateElementByTextFile,
    updateSourcesFileAndInitialize,
} from 'libs/repeat/store/src/lib/slices/workspace/schema/schemaSlice';
import { UploadForm } from '../Upload/UploadForm';

const setFileTypes = (type: string): Record<string, string[]> => {
    const fileTypes: Record<string, Record<string, string[]>> = {
        fmi: { 'application/x-fmu-sharedlibrary': ['.fmu'] },
        FuzzyLogic: { 'application/x-fcl': ['.fcl'] },
        PulseqSource: { 'text/seq': ['.seq'] },
        RFSParameters: { 'text/plain': ['.s1p', '.s2p', '.s3p', '.s4p'] },
    };
    return fileTypes[type];
};

export const ElementFileUpdateModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();

    const element = useAppSelector((state) => state.workspace.schema.selectedItems.elements[0]);
    const { elementInitialization } = useAppSelector((state) => state.workspace.schema);
    const { projectId } = useProjectId() || { projectId: null };

    const modal = useAppSelector((state) => state.app.modals)[0] as TBlockFileUpload;
    const elementType = modal.data.type;
    const isElementWithInitialization = ELEMENTS_WITH_INITIALIZATION.includes(elementType);

    const setModalTitle = useCallback((type: string): string => {
        const modalTitles: Record<string, string> = {
            fmi: 'FMI',
            FuzzyLogic: 'Fuzzy Logic',
            PulseqSource: 'PULSEQ',
            RFSParameters: formatMessage(messages[TranslationKey.ELEMENT_FILE_S_PARAMETERS_NAME]),
        };
        return modalTitles[type];
    }, []);
    const setMessages = useCallback((type: string) => {
        const extensions: Record<string, string> = {
            fmi: '.fmu',
            FuzzyLogic: '.flc',
            PulseqSource: '.seq',
            RFSParameters: '',
        };

        const extension = extensions[type];

        return {
            uploadHelp:
                type === 'RFSParameters'
                    ? formatMessage(messages[TranslationKey.ELEMENT_RFSPARAMETERS_INITIALIZATION_DROPZONE])
                    : formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_DROPZONE], {
                          extension,
                      }),
            uploadButton: formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_SELECT], {
                extension,
            }),
        };
    }, []);

    const systemName = element.data.elemProps.find((prop) => prop.name === 'systemName')?.value.toString() || '';
    const uuid = systemName.split('.').slice(0, -1).join('.');
    useEffect(() => {
        return () => {
            dispatch(workspaceActions.resetElementInitialization());
        };
    }, []);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.ELEMENT_FILE_UPDATE }));
    };
    const handleSave = () => {
        if (isElementWithInitialization) {
            dispatch(initializeUpdatedElement());
        } else {
            dispatch(updateElementByTextFile(elementType));
        }
    };

    const handleFileUpload = (file: File) => {
        if (!element?.id || !projectId) {
            return;
        }
        dispatch(
            updateSourcesFileAndInitialize({ file, projectId, elementId: parseInt(element.id), elementType, uuid })
        );
    };

    const handleInitialize = () => {
        if (!element?.id || !projectId) {
            return;
        }

        dispatch(initializeElementByFile());
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button
                onClick={handleSave}
                disabled={elementInitialization.initializeElementByFile.status !== Statuses.SUCCEEDED}
            >
                {intl.formatMessage(messages[TranslationKey.SAVE])}
            </Button>
        </>
    );
    const formMessages = {
        title: '',
        dropZoneMessages: {
            ...setMessages(elementType),
            ...(elementType === 'fmi' && {
                attentionMessage: formatMessage(messages[TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE]),
            }),
        },
    };

    return (
        <Modal
            id={ModalTypes.ELEMENT_FILE_UPDATE}
            title={intl.formatMessage(messages[TranslationKey.ELEMENT_FILE_UPDATE], {
                name: setModalTitle(elementType),
            })}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='medium'
            footer={<FooterButtons />}
        >
            <UploadForm
                acceptFileTypes={setFileTypes(elementType)}
                maxFileSize={52428800}
                onFileUpload={handleFileUpload}
                {...(isElementWithInitialization && { onInitialize: handleInitialize })}
                uploadStatusInfo={elementInitialization.uploadElementSourcesFile}
                initializeStatusInfo={elementInitialization.initializeElementByFile}
                messages={formMessages}
                extension={elementType === 'fmi' ? 'FMU' : 'FCL'}
            />
        </Modal>
    );
};
