import React, { FC } from 'react';

import { SAnchor, SButtonWrapper, SLink } from './SComponents';

export type TSizesButton = 'small' | 'medium' | 'big';
export type TVariantButton = 'primary' | 'secondary' | 'outlined';
export enum EVariantButton {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    OUTLINED = 'outlined',
}
export enum ESizeButton {
    SMALL = 'small',
    MEDIUM = 'medium',
}
type TType = 'button' | 'submit';

interface IButtonProps {
    type?: TType;
    variant?: TVariantButton;
    disabled?: boolean;
    href?: string;
    size?: TSizesButton;
    children?: React.ReactNode;
    form?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
    target?: string;
    rel?: string;
    to?: string;
    style?: Record<string, any>;
}

export const Button: FC<IButtonProps> = ({
    to,
    href,
    size = 'medium',
    variant = 'primary',
    type = 'button',
    children,
    ...rest
}) => {
    if (href) {
        return (
            <SAnchor {...rest} href={href}>
                {children}
            </SAnchor>
        );
    }
    if (to) {
        return (
            <SLink {...rest} to={to}>
                {children}
            </SLink>
        );
    }

    return (
        <SButtonWrapper {...rest} size={size} variant={variant} type={type}>
            {children}
        </SButtonWrapper>
    );
};
