import { CODE_LIST, TranslationKey } from '../translations.enum';

export const messages = {
    [TranslationKey.LOGIN]: 'Логин',
    [TranslationKey.PASSWORD]: 'Пароль',
    [TranslationKey.PASSWORD_RESTORE]: 'Восстановить пароль',
    [TranslationKey.PASSWORD_RESTORE_INSTRUCTION_SENT]:
        'Инструкции по восстановлению пароля отправлены, проверьте свой email',
    [TranslationKey.PASSWORD_CONFIRMATION]: 'Подтверждение Пароля',
    [TranslationKey.PASSWORD_NO_MATCH]: 'Пароли не совпадают',
    [TranslationKey.BYTES]: 'байт',
    [TranslationKey.ROLE]: 'Роль',

    [TranslationKey.ROLE_ADMIN]: 'Администратор',

    [TranslationKey.PASSWORD_NEW]: 'Новый пароль',
    [TranslationKey.PASSWORD_NEW_REPEAT]: 'Повторите пароль',
    [TranslationKey.FORGOT_PASSWORD_TOKEN_INVALID]: 'Ссылка не действительна',
    [TranslationKey.CREATE_TITLE]: 'Создать',
    [TranslationKey.SIGN_IN]: 'Войти',
    [TranslationKey.REGISTER]: 'Регистрация',
    [TranslationKey.FINALIZE]: 'Завершить',
    [TranslationKey.NO_ACCOUNT]: 'У вас нет аккаунта?',
    [TranslationKey.ACCOUNT_ALREADY_EXISTS]: 'У вас есть аккаунт?',
    [TranslationKey.FORGOT_PASSWORD]: 'Забыли пароль?',
    [TranslationKey.ACCOUNT_PAGE]: 'Личный кабинет',
    [TranslationKey.PERSONAL_DATA]: 'Персональные данные',
    [TranslationKey.TARIFFS]: 'Тарифы',
    [TranslationKey.SETTINGS]: 'Настройки',
    [TranslationKey.EDIT_DATA_ACTION]: 'Редактировать данные',
    [TranslationKey.CHANGE_PASSWORD_ACTION]: 'Изменить пароль',
    [TranslationKey.NAME]: 'Имя',
    [TranslationKey.EMAIL]: 'Email',
    [TranslationKey.PHONE]: 'Телефон',
    [TranslationKey.EXTENSION_NUMBER_SUPPORT]: 'доб. 1, 2',

    [TranslationKey.EDIT_DATA]: 'Редактирование данных',
    [TranslationKey.CHANGE_PASSWORD]: 'Изменение пароля',
    [TranslationKey.INFO]: 'Информация',
    [TranslationKey.PROPERTIES]: 'Свойства',
    [TranslationKey.CHARTS]: 'Графики',
    [TranslationKey.CHART]: 'График',
    [TranslationKey.VARIABLE]: 'Переменная',
    [TranslationKey.WORKSPACE_PAGE_ERROR_NAME_VARIABLES]: 'Укажите имя переменной',
    [TranslationKey.WORKSPACE_PAGE_ERROR_VALUE_VARIABLES]: 'Значение должно быть числом',
    [TranslationKey.ELEMENT]: 'Блок',
    [TranslationKey.PARAMETER]: 'Параметр',
    [TranslationKey.PARAMETERS]: 'Параметры',
    [TranslationKey.NO_PARAMETERS]: 'Нет параметров для выбора',
    [TranslationKey.METER_UNITS]: 'Порядок единиц измерения',
    [TranslationKey.METER_UNITS_FACTOR]: 'Коэффициент усиления',
    [TranslationKey.NO_ELEMENTS]: 'Нет блоков для выбора',
    // Blocks group
    [TranslationKey.GROUP_NEW]: 'Создать группу',
    [TranslationKey.GROUP_SAVE_AS_BLOCK]: 'Сохранить как блок',
    [TranslationKey.GROUP_CREATING_WARNING_TITLE]: 'Предупреждение',
    [TranslationKey.GROUP_CREATING_WARNING_ACCEPT]: 'Принять и продолжить',
    [TranslationKey.GROUP_CREATING_WARNING_BLOCKS]:
        'Обнаружены блоки, связанные с группируемыми. Поэтому следующие блоки также будут перенесены в группу:',
    [TranslationKey.GROUP_CREATING_WARNING_CHARTS]:
        'Также обнаружены графики, настроенные на указанные выше блоки. При создании группы будут удалены следующие графики:',

    // User blocks
    [TranslationKey.CUSTOM]: 'Мои блоки',
    [TranslationKey.USER_BLOCK_EDIT_TITLE]: 'Редактирование блока',
    [TranslationKey.USER_BLOCK_EDIT_VIEW_TITLE]: 'Изменить системные параметры блока',
    [TranslationKey.USER_BLOCK_EDIT_SYSTEM_PARAMETERS]: 'Редактировать системные параметры',
    [TranslationKey.USER_BLOCK_SAVE_TITLE]: 'Сохранить изменения',
    [TranslationKey.USER_BLOCK_SAVED]: 'Блок успешно сохранен',
    [TranslationKey.USER_BLOCK_TITLE]: 'Название',
    [TranslationKey.USER_BLOCK_SUBSECTION]: 'Подраздел',
    [TranslationKey.USER_BLOCK_SUBSECTION_PLACEHOLDER]: 'Выберите подраздел или введите его название',
    [TranslationKey.USER_BLOCK_NO_SUBSECTIONS]: 'Нет вариантов для выбора',
    [TranslationKey.USER_BLOCK_ICON]: 'Изображение',
    [TranslationKey.USER_BLOCK_ICON_CHANGE]: 'Изменить',
    [TranslationKey.USER_BLOCK_ICON_SCALE]: 'Масштабировать',
    [TranslationKey.USER_BLOCK_ICON_ROTATE]: 'Повернуть',
    [TranslationKey.USER_BLOCK_DESCRIPTION]: 'Описание...',
    [TranslationKey.USER_BLOCK_DELETE_CONFIRM]: 'Возможно, блок уже используется в проектах. Подтвердите удаление',
    [TranslationKey.USER_BLOCK_NEW]: 'Новый блок',
    [TranslationKey.USER_BLOCK_CHANGED_NOTIFICATION]: 'В блок внесены изменения',
    [TranslationKey.USER_BLOCK_DELETED_NOTIFICATION]: 'Блок удален',
    [TranslationKey.USER_BLOCK_GET_INFO_ERROR]: 'Ошибка получения данных блока',
    [TranslationKey.USER_BLOCKS_MODIFIED_NOTIFICATION]:
        'Один или несколько пользовательских блоков были удалены или изменены.',
    [TranslationKey.USER_BLOCK_TRANSFORM_TO_GROUP]: 'Сделать группой',

    [TranslationKey.PROJECT_NEW]: 'Новый проект',
    [TranslationKey.PROJECT_EDIT]: 'Редактирование проекта',
    [TranslationKey.PROJECT_OPEN]: 'Загрузить',
    [TranslationKey.PROJECT_IMPORT]: 'Импорт',
    [TranslationKey.PROJECT_EXPORT]: 'Экпорт',
    [TranslationKey.PROJECT_NAME]: 'Название проекта',
    [TranslationKey.PROJECT_LIBRARY]: 'Библиотека',
    [TranslationKey.PROJECT_SOLVER]: 'Решатель',
    [TranslationKey.PROJECT_CREATED]: 'Проект успешно создан',
    [TranslationKey.PROJECT_IMPORTED]: 'Проект успешно импортирован',
    [TranslationKey.PROJECT_EXPORTED]: 'Проект успешно экспортирован',
    [TranslationKey.PROJECT_SAVED]: 'Проект успешно сохранен',
    [TranslationKey.PROJECT_DELETED]: 'Проект успешно удален',
    [TranslationKey.PROJECTS_FILTER_BY_TITLE]: 'Поиск по названию',
    [TranslationKey.PROJECTS_COLUMN_TITLE]: 'Название',
    [TranslationKey.PROJECTS_COLUMN_CREATED_AT]: 'Создан',
    [TranslationKey.PROJECTS_COLUMN_UPDATED_AT]: 'Обновлен',
    [TranslationKey.PROJECT_DELETE_TITLE]: 'Подтверждение удаления',
    [TranslationKey.PROJECT_DELETE_CONFIRMATION]: 'Вы действительно хотите удалить проект "{title}"?',
    [TranslationKey.PROJECTS_DELETE_CONFIRMATION]: 'Вы действительно хотите удалить выбранные проекты?',
    [TranslationKey.PROJECT_DELETE_NOT_SELECTED]: 'Проект для удаления не выбран.',
    [TranslationKey.PROJECT_EDIT_NOT_SELECTED]: 'Проект для редактирования не выбран.',
    [TranslationKey.PROJECT_DELETE_DROPDOWN]: 'Удалить',
    [TranslationKey.PROJECT_EDIT_DROPDOWN]: 'Редактировать',
    [TranslationKey.PROJECT_EXPORT_DROPDOWN]: 'Экспортировать',
    [TranslationKey.PROJECT_DUPLICATE_DROPDOWN]: 'Клонировать',
    [TranslationKey.DEMO_PROJECT_DUPLICATE_DROPDOWN]: 'Добавить себе',
    [TranslationKey.PROJECT_ADD_TO_DEMO_DROPDOWN]: 'Добавить в демо проекты',
    [TranslationKey.PROJECT_REMOVE_FROM_DEMO_DROPDOWN]: 'Убрать из демо проектов',
    [TranslationKey.PROJECT_COPY_ID_DROPDOWN]: 'Скопировать id проекта',
    [TranslationKey.PROJECT_LIST_EMPTY]: 'У вас пока нет сохраненных проектов',
    [TranslationKey.DEMO_PROJECT_LIST_EMPTY]: 'У вас пока нет демо проектов',
    [TranslationKey.PROJECT_LIST_BY_FILTER_EMPTY]: 'Проекты по критериям фильтра не найдены',
    [TranslationKey.BLOCK_NEW]: 'Добавить блок',
    [TranslationKey.COMPANY_BASE]: 'Общие сведения',
    [TranslationKey.COMPANY_BANK]: 'Банковские реквизиты',
    [TranslationKey.COMPANY_TITLE]: 'Наименование',
    [TranslationKey.COMPANY_INN]: 'ИНН',
    [TranslationKey.COMPANY_KPP]: 'КПП',
    [TranslationKey.COMPANY_OGRN]: 'ОГРН',
    [TranslationKey.COMPANY_LEGAL_ADDRESS]: 'Юридический адрес',
    [TranslationKey.COMPANY_POSTAL_ADDRESS]: 'Почтовый адрес',
    [TranslationKey.COMPANY_BANK_ACCOUNT]: 'Р/с',
    [TranslationKey.COMPANY_BANK_BIK]: 'БИК',
    [TranslationKey.COMPANY_BANK_TITLE]: 'Наименование банка',
    [TranslationKey.COMPANY_BANK_INN]: 'ИНН банка',
    [TranslationKey.COMPANY_BANK_KPP]: 'КПП банка',
    [TranslationKey.COMPANY_BANK_CORRESPONDENT_ACCOUNT]: 'К/с',
    [TranslationKey.USER_AGREEMENT]: 'Пользовательское соглашение',
    [TranslationKey.PRIVACY_POLICY_AGREEMENT]: 'Политика в отношении обработки персональных данных',
    [TranslationKey.REGLAMENT_TP]: 'Регламент ТП',

    [TranslationKey.API_KEYS_LIST_EMPTY]: 'У вас еще нет сгенерированных API ключей.',
    [TranslationKey.API_KEYS_COLUMN_KEY]: 'API ключ',
    [TranslationKey.API_KEYS_COLUMN_NAME]: 'Название',
    [TranslationKey.API_KEYS_COLUMN_EXPIRE_AT]: 'Истекает',
    [TranslationKey.API_KEYS_COLUMN_CREATED_AT]: 'Создан',
    [TranslationKey.API_KEYS_NEW]: 'Новый API ключ',
    [TranslationKey.API_KEYS_DELETE]: 'Удалить API ключ',
    [TranslationKey.API_KEY_DELETE_TITLE]: 'Удаление API ключа',
    [TranslationKey.API_KEY_DELETE_CONFIRMATION]: 'Вы действительно хотите удалить API ключ "{title}"?',
    [TranslationKey.API_KEY_DELETE_NOT_SELECTED]: 'API ключ для удаления не выбран.',
    [TranslationKey.API_KEY_NAME]: 'Название',
    [TranslationKey.API_KEY_EXPIRE_AT]: 'Дата окончания срока действия',
    [TranslationKey.ERROR_NEW_API_KEY_NAME]: 'API ключ с таким именем уже существует',
    [TranslationKey.ERROR_API_KEY_NAME_IS_EMPTY]: 'Название ключа не может состоять только из пробелов',
    [TranslationKey.API_KEY_CREATED]: 'API ключ успешно создан',
    [TranslationKey.API_KEY_DELETED]: 'API ключ успешно удален',

    [TranslationKey.HELP_SECTION_COMMON]: 'Общая информация',
    [TranslationKey.HELP_SECTION_INTERFACE]: 'Описание интерфейса пользователя',
    [TranslationKey.HELP_SECTION_TASK]: 'Режим проектирования',
    [TranslationKey.HELP_SECTION_MODELING]: 'Режим моделирования',
    [TranslationKey.HELP_SECTION_CHANGELOG]: 'История версий платформы',
    [TranslationKey.HELP_SECTION_VERSIONS_TITLE]: 'Версии',
    [TranslationKey.HELP_SECTION_SUPPORT]: 'Тех.поддержка',
    [TranslationKey.HELP_SECTION_HOTKEYS]: 'Горячие клавиши',
    [TranslationKey.HELP_ERROR_FETCH_HISTORY_VERSION_DATA]:
        'Ошибка при получении данных с сервера. Не удалось загрузить историю версий на странице «Справки»',
    [TranslationKey.HELP_BLOCK_SUMMARY]: 'Общее',
    [TranslationKey.HELP_BLOCK_NEW]: 'Новое',
    [TranslationKey.HELP_BLOCK_IMPROVEMENTS]: 'Улучшения',
    [TranslationKey.HELP_BLOCK_FIXES]: 'Исправления',
    [TranslationKey.HELP_BLOCK_ADDITIONAL]: 'Дополнения',

    [TranslationKey.PAGE_MAIN_TITLE]: 'Главная',
    [TranslationKey.PAGE_TARIFFS_TITLE]: 'Тарифная сетка',
    [TranslationKey.PAGE_PROJECTS_TITLE]: 'Проекты',
    [TranslationKey.PAGE_DEMO_PROJECTS_TITLE]: 'Демо проекты',
    [TranslationKey.PAGE_FAV_PROJECTS_TITLE]: 'Избранные проекты',
    [TranslationKey.PAGE_FAV_PROJECTS_EMPTY]: 'У вас пока нет избранных проектов',
    [TranslationKey.PAGE_FAV_PROJECT_SUCCESS]: 'Проект добавлен в избранное',
    [TranslationKey.PAGE_DEMO_PROJECT_SUCCESS]: 'Проект добавлен в демо примеры',
    [TranslationKey.PAGE_DEMO_PROJECT_REMOVED]: 'Проект удален из демо примеров',
    [TranslationKey.PAGE_FAV_PROJECT_DELETE]: 'Проект удален из избранного',
    [TranslationKey.PAGE_FAV_PROJECTS_SUCCESS]: 'Проекты добавлены в избранное',
    [TranslationKey.PAGE_PROJECT_ADD_FAV]: 'Добавить проект в избранное',
    [TranslationKey.PAGE_PROJECT_DELETE_FAV]: 'Убрать проект из избранного',
    [TranslationKey.PAGE_PROJECTS_ADD_FAV]: 'Добавить выбранные проекты в избранное',
    [TranslationKey.PAGE_PROJECTS_REMOVE_FAV]: 'Убрать выбранные проекты из избранного',
    [TranslationKey.PAGE_PROJECTS_REMOVE]: 'Удалить выбранные проекты',
    [TranslationKey.PROJECTS_CREATED]: 'Созданные проекты',
    [TranslationKey.PROJECT_NOT_SUPPORTED]: 'не поддерживается',
    [TranslationKey.PROJECT_NOT_SUPPORTED_USDS]:
        'Уважаемый пользователь! Вы пытались открыть проект, совместимый с решателем USDS. С версии 1.4.0 мы отключили поддержку проектов, созданных на решателе USDS. Приносим свои извинения за доставленные неудобства. При необходимости вы можете обратиться в тех.поддержку.',
    [TranslationKey.PROJECT_IMPORT_MODAL_TITLE]: 'Импорт проекта',
    [TranslationKey.LIBRARYITEM_IMPORT_MODAL_TITLE]: 'Импорт блока',
    [TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION]:
        'Для импорта проекта переместите файл с расширением .rpt в область для загрузки или воспользуйтесь кнопкой "Импортировать" для выбора файла .rpt',
    [TranslationKey.PROJECT_IMPORT_SELECT_FILE]: 'Импортировать',
    [TranslationKey.ERROR_PROJECT_NOT_IMPORTED]: 'Ошибка при импорте. Проект не импортирован.',
    [TranslationKey.ERROR_PROJECT_NOT_EXPORTED]: 'Ошибка при экспорте. Проект не экспортирован.',
    [TranslationKey.ERROR_PROJECT_NOT_LOAD]: 'Ошибка при получении данных проекта.',
    [TranslationKey.BLOCK_IMPORT_ERROR]: 'Блок уже существует!',
    [TranslationKey.ERROR_NEW_BLOCK_NAME]: 'Блок с таким именем уже существует!',
    [TranslationKey.ERROR_BLOCK_NAME_IS_EMPTY]: 'Имя блока не может быть пустым!',

    [TranslationKey.PAGE_ACCOUNT_TITLE]: 'Аккаунт',
    [TranslationKey.PAGE_ACCOUNT_EDIT_TITLE]: 'Редактирование аккаунта',
    [TranslationKey.PAGE_PASSWORD_TITLE]: 'Смена пароля',
    [TranslationKey.PAGE_COMPANY_TITLE]: 'Организация',
    [TranslationKey.PAGE_TARIFF_TITLE]: 'Тариф',
    [TranslationKey.PAGE_SETTINGS_TITLE]: 'Настройки',
    [TranslationKey.PAGE_API_KEYS_TITLE]: 'API ключи',
    [TranslationKey.PAGE_LEGAL_DATA_TITLE]: 'Юридическая информация',

    // pages
    [TranslationKey.PAGE_HELP_TITLE]: 'Справка и поддержка',
    [TranslationKey.ENGINEER_MODE_TITLE]: 'Режим инженера',
    [TranslationKey.PAGE_VERSIONS_HISTORY_TITLE]: 'История версий платформы',
    [TranslationKey.PAGE_LICENSE_TITLE]: 'Лицензия',
    [TranslationKey.PAGE_HELP_SUBTITLE]: 'Платформа симуляционного моделирования REPEAT',
    [TranslationKey.PAGE_ABOUT_TITLE]: 'О платформе',
    [TranslationKey.PAGE_DASHBOARD_TITLE]: 'Панель управления',
    [TranslationKey.PAGE_BALANCE_TITLE]: 'Зачисления / Списания',
    [TranslationKey.PAEGE_DOCUMENTS_TITLE]: 'Документы',
    [TranslationKey.PAGE_USERS_TITLE]: 'Администрирование аккаунтов',
    [TranslationKey.PAGE_SUBSCRIPTIONS_TITLE]: 'Рассылки',
    [TranslationKey.PAGE_REFUND_TITLE]: 'Возврат средств',
    [TranslationKey.PAGE_CONTACTS_TITLE]: 'Контакты',
    [TranslationKey.PAGE_LICENSE_PAGE]: 'Управление лицензиями',
    [TranslationKey.USERS_PAGE_ADD_ACOUNT]: 'Добавить аккаунт',

    [TranslationKey.SOCKET_ERR_MSG]: 'Ошибка соединения',
    [TranslationKey.NETWORK_ERR_MSG]: 'Ошибка сети',
    [TranslationKey.NAME_ERROR_MSG]: 'Разрешены только буквы, цифры и символы (_ и -)',
    [TranslationKey.ONLY_DIGITS_ERROR_MSG]: 'Разрешены только цифры',
    [TranslationKey.ONLY_PHONE_ERROR_MSG]: 'Разрешены только цифры, + и три цифры в скобках',
    [TranslationKey.TOO_MANY_ERROR_MSG]: 'Длина должна быть не более {num} символов',
    [TranslationKey.TOO_FEW_ERROR_MSG]: 'Длина не должна быть менее {num} символов',
    [TranslationKey.ERROR_PHONE_NOT_CORRECT]: 'Некорректный формат телефона',
    [TranslationKey.ERROR_PASSWORD_MIN_LENGTH]: 'Не менее 3 символов',
    [TranslationKey.OBLIGATORY_FIELD_MSG]: 'Обязательное поле',
    [TranslationKey.MALFORMED_EMAIL_MSG]: 'Некорректный email',
    [TranslationKey.FLOAT64_MSG]: 'Значение должно быть FLOAT64 (пример: 5.0E-7)',

    [TranslationKey.LOGIN_IN_USE]: 'Логин уже занят',
    [TranslationKey.PHONE_IN_USE]: 'Телефон уже занят',
    [TranslationKey.EMAIL_IN_USE]: 'Email уже занят',

    [TranslationKey.MESSAGE_ACCOUNT_UPDATED]: 'Данные успешно обновлены',
    [TranslationKey.MESSAGE_PASSWORD_UPDATED]: 'Пароль успешно обновлен',
    [TranslationKey.MESSAGE_REGISTRATION_SUCCESS]: 'Вы успешно зарегистрировались',
    [TranslationKey.MESSAGE_REGISTRATION_ON_PAGE_LEAVE]:
        'Вы уверены, что хотите покинуть страницу. Внесенные изменения не сохранятся',
    [TranslationKey.MESSAGE_DOWNLOAD_DOCUMENT_ERROR]:
        'Проблема с сервером, не удалось загрузить файл «{invoice}-{id}.pdf». Повторите попытку позже',

    // profile
    [TranslationKey.USER_ACCOUNT]: 'Учетная запись',

    [TranslationKey.ERROR_ACCOUNT_UPDATE]: 'Ошибка обновления аккаунта',
    [TranslationKey.ERROR_ACCOUNT_SETTINGS_UPDATE]: 'Ошибка сохранения настроек аккаунта',

    // account
    [TranslationKey.SELECT_ACCOUNT_TYPE]: 'Выберите тип аккаунта',
    [TranslationKey.INDIVIDUAL]: 'Физическое лицо / Самозанятый',
    [TranslationKey.ENTREPRENEUR]: 'Индивидуальный предприниматель',
    [TranslationKey.COMPANY_RESIDENT]: 'Юридическое лицо (резидент РФ)',
    [TranslationKey.COMPANY_NON_RESIDENT]: 'Юридическое лицо (нерезидент РФ)',
    [TranslationKey.EDUCATIONAL_UNIT]: 'Образовательное учреждение',

    // account table
    [TranslationKey.TABLE_BALANCE_COLUMN_TRANSACTION_NAME]: 'Транзакции',
    [TranslationKey.TABLE_BALANCE_COLUMN_SUM]: 'Сумма',
    [TranslationKey.TABLE_BALANCE_COLUMN_DATE]: 'Дата',
    [TranslationKey.TABLE_BALANCE_COLUMN_TRANSACTION_ID]: 'Номер транзакции',
    [TranslationKey.TABLE_BALANCE_SEARCH_PLACEHOLDER]: 'Поиск по транзакциям...',
    [TranslationKey.TABLE_BALANCE_ERROR_DATA]:
        'Ошибка при получении данных с сервера. Не удалось загрузить страницу «Зачисление/Cписание средств»',
    [TranslationKey.TABLE_BALANCE_EMPTY_DATA]: 'У вас пока нет доступных транзакций',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_TYPE]: 'Тип',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_SUM]: 'Сумма',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_VAT]: 'НДС',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_TOTAL]: 'Итого',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_DOCUMENT_ID]: 'Номер',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_DATE]: 'Дата',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_STATUS]: 'Статус',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_PERSONAL_ACCOUNT]: 'Лицевой счет',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_CLIENT_NAME]: 'Клиент: Наименование',
    [TranslationKey.TABLE_DOCUMENT_EMPTY_DATA]: 'У вас пока нет доступных документов',
    [TranslationKey.TARIFF_ERROR_DATA]: 'Ошибка при получении данных с сервера. Не удалось загрузить раздел «Тариф»',
    [TranslationKey.TARIFFS_ERROR_DATA]:
        'Ошибка при получении данных с сервера. Не удалось загрузить раздел «Тарифная сетка»',
    [TranslationKey.TARIFF_CHANGE]: 'Смена тарифного плнана',
    [TranslationKey.TABLE_DOCUMENT_ERROR_DATA]:
        'Ошибка при получении данных с сервера. Не удалось загрузить страницу «Документы»',
    [TranslationKey.TABLE_USERS_COLUMN_FULLNAME]: 'ФИО',
    [TranslationKey.TABLE_USERS_COLUMN_ROLE]: 'Роль',
    [TranslationKey.TABLE_USERS_COLUMN_DATE]: 'Дата регистрации',
    [TranslationKey.TABLE_USERS_EMPTY_DATA]: 'У вас еще нет добавленных учетных записей',

    [TranslationKey.USERS_DATA]: 'Пользователи',

    //student role
    [TranslationKey.DEFAULT]: 'Пользователь',
    [TranslationKey.ADMINISTRATOR]: 'Администратор',
    [TranslationKey.FORM_ADD_ACCOUNT_ROLE]: 'Выберите роль',
    //form
    [TranslationKey.FORM_ADD_ACCOUNT_ID]: 'Идентификатор аккаунта',

    // errors
    [TranslationKey.ERROR_SIGIN_DATA]: 'Неверный логин или пароль',
    [TranslationKey.ERROR_SIGNUP_DATA]: 'Ошибка регистрации',
    [TranslationKey.ERROR_NEW_PROJECT_NAME]: 'Проект с таким именем уже существует',
    [TranslationKey.ERROR_PROJECT_NAME_IS_EMPTY]: 'Название проекта не может состоять только из пробелов',
    [TranslationKey.ERROR_UNKNOWN]: 'Неизвестная ошибка',
    [TranslationKey.ERROR_BANK_ACCOUNT_FORMAT]: 'Неверный формат банковского счета',
    [TranslationKey.ERROR_KPP_FORMAT]: 'Неверный формат КПП',
    [TranslationKey.ERROR_BIK_FORMAT]: 'Неверный формат БИК',
    [TranslationKey.ERROR_ORGN_FORMAT]: 'Неверный формат ОГРН',
    [TranslationKey.ERROR_OGRNIP_FORMAT]: 'Неверный формат ОГРНИП',
    [TranslationKey.ERROR_TAX_NUMBER_FORMAT]: 'Неверный формат ИНН',
    [TranslationKey.ERROR_TAX_NUMBER_BANK_FORMAT]: 'Неверный формат ИНН Банка',
    [TranslationKey.ERROR_TERMS_AND_CONDITIONS]: 'Необходимо согласиться с условиями сервиса',
    [TranslationKey.ERROR_PRIVACY_POLICY]: 'Необходимо согласиться с политикой конфиденциальности',
    [TranslationKey.ERROR_LARGE_FILE]: 'Размер файла превышает допустимый',
    [TranslationKey.ERROR_SMALL_FILE]: 'Размер файла меньше допустимого',
    [TranslationKey.ERROR_INVALID_TYPE_FILE]: 'Выбранный файл имеет неверный формат',
    [TranslationKey.ERROR_TOO_MANY_FILES_TEXT]:
        'Пожалуйста, выберите не более {count} {file} для перемещения в зону добавления',
    [TranslationKey.ERROR_TOO_MANY_FILE]: 'файла',
    [TranslationKey.ERROR_TOO_MANY_FILES]: 'файлов',
    [TranslationKey.ERROR_SEND_MESSAGE_TO_SUPPORT]: 'При отправке сообщения произошла ошибка',

    [TranslationKey.FULL_TITLE]: 'Полное наименование',
    [TranslationKey.TAX_NUMBER]: 'ИНН',
    [TranslationKey.ORGNIP]: 'ОГРНИП',
    [TranslationKey.ORGN]: 'ОГРН',
    [TranslationKey.KPP]: 'КПП',
    [TranslationKey.KIO]: 'КИО',
    [TranslationKey.ADDR_LEGAL]: 'Юридический адрес',
    [TranslationKey.ADDR_POST]: 'Почтовый адрес',
    [TranslationKey.ADDR_SAME_AS_LEGAL]: 'Совпадает с юридическим адресом',

    [TranslationKey.LICENSE_EDU]: 'Образовательная лицензия',
    [TranslationKey.LICENSE_INFO]: 'Информация о лицензии',
    [TranslationKey.LICENSE_INFO_EDU_NUMBER]: 'Номер образовательной лицензии',
    [TranslationKey.LICENSE_INFO_EDU_DATE]: 'Дата образовательной лицензии',

    [TranslationKey.BANK_NAME]: 'Наименование банка',
    [TranslationKey.ACCOUNT_NUMBER]: 'Номер счета',
    [TranslationKey.BIC]: 'БИК',
    [TranslationKey.KPP_BANK]: 'КПП банка',
    [TranslationKey.TAX_NUMBER_BANK]: 'ИНН банка',
    [TranslationKey.CORRESPONDENT_ACCOUNT]: 'Корреспондентский счет',

    [TranslationKey.TERMS_AND_CONDITIONS]: 'Условия использования сервиса',
    [TranslationKey.PRIVACY_POLICY]: 'политику в отношении обработки персональных данных',

    [TranslationKey.AGREE]: 'Принимаю',

    // common
    [TranslationKey.COUNTRY_NAME]: 'Страна',
    [TranslationKey.BACK]: 'Назад',
    [TranslationKey.GO_NEXT]: 'Далее',
    [TranslationKey.RETURN_TO_ENTRANCE]: 'Вернуться ко входу',
    [TranslationKey.INVOICE]: 'Счет',
    [TranslationKey.DOWNLOAD]: 'Скачать',
    [TranslationKey.UNLIMITED]: 'Безлимит',

    [TranslationKey.SEC]: 'сек',
    [TranslationKey.MEGABYTE]: 'мб',
    [TranslationKey.PIECE]: 'шт',

    [TranslationKey.ELECTROCITY]: 'Электрика',
    [TranslationKey.ELECTROCITY_DC]: 'Электрика ПТ',
    [TranslationKey.AUTO]: 'Автоматика',
    [TranslationKey.THERMOHYDRAULICS]: 'Теплогидравлика',
    [TranslationKey.SIMULATION]: 'Симуляция',
    [TranslationKey.SYSTEM_DESIGN]: 'Системный дизайн',
    [TranslationKey.EXTERNAL_MODELS]: 'Внешние модели',
    [TranslationKey.CODE_GENERATION]: 'Кодогенерация',
    [TranslationKey.COMMON]: 'Общее',
    [TranslationKey.DEVELOPMENT]: 'В разработке',
    [TranslationKey.MECHANICS]: 'Механика',
    [TranslationKey.ELECTRIC_DRIVE]: 'Электрические приводы',
    [TranslationKey.HEAT_EXCHANGE]: 'Теплообмен',
    [TranslationKey.ELECTRONICS]: 'Электроника',
    [TranslationKey.ISOTHERMAL_HYDRAULICS]: 'Изотермическая гидравлика',
    [TranslationKey.PNEUMATICS]: 'Пневматика',
    [TranslationKey.SUBLIBRARY_ROTATIONAL_MOTION]: 'Вращательное движение',
    [TranslationKey.SUBLIBRARY_TRANSLATIONAL_MOTION]: 'Поступательное движение',
    [TranslationKey.SUBLIBRARY_BASE_ELEMENTS]: 'Базовые элементы',
    [TranslationKey.SUBLIBRARY_CONVERTERS]: 'Преобразователи',
    [TranslationKey.SUBLIBRARY_ELECTRIC_MACHINES]: 'Электрические машины',
    [TranslationKey.SUBLIBRARY_SUPPLIERS]: 'Источники',
    [TranslationKey.SUBLIBRARY_FUNCTIONS]: 'Функции',
    [TranslationKey.SUBLIBRARY_OPERATIONS]: 'Операции',
    [TranslationKey.SUBLIBRARY_LOGIC]: 'Логика',
    [TranslationKey.SUBLIBRARY_SWITCH]: 'Переключатели и узлы',
    [TranslationKey.SUBLIBRARY_TRANSFERFUNCTIONS]: 'Передаточные звенья',
    [TranslationKey.SUBLIBRARY_CONTROLLERS]: 'Контроллеры',
    [TranslationKey.SUBLIBRARY_DELAYS]: 'Импульсы и задержки',
    [TranslationKey.SUBLIBRARY_RELAY]: 'Релейные звенья',
    [TranslationKey.SUBLIBRARY_NONLINEAR]: 'Нелинейные звенья',
    [TranslationKey.SUBLIBRARY_SIGNAL_PROCESSING]: 'Обработка сигналов',

    [TranslationKey.BASE_PERMISSION]: 'База',
    [TranslationKey.PNEUMO_PERMISSION]: 'Пневматические системы',
    [TranslationKey.HEATHYDRO_PERMISSION]: 'Теплогидравлика и Теплообмен',
    [TranslationKey.CODEGENERATING_PERMISSION]: 'Кодогенерация',
    [TranslationKey.ELECTRODYNAMICS_PERMISSION]: 'Электродинамика',

    [TranslationKey.SUBLIBRARY_STRUCTURAL_AND_SUPPORT]: 'Структурные и вспомогательные элементы',
    [TranslationKey.SUBLIBRARY_LOADS]: 'Нагрузки',
    [TranslationKey.SUBLIBRARY_TRANSFORMERS]: 'Трансформаторы',
    [TranslationKey.SUBLIBRARY_SOURCES]: 'Источники',
    [TranslationKey.SUBLIBRARY_POWER_CONVERTERS]: 'Силовые преобразователи',
    [TranslationKey.SUBLIBRARY_LONGITUDINAL_ELEMENTS]: 'Продольные элементы',

    [TranslationKey.SUBLIBRARY_ELECTRONIC_COMPONENTS_AND_POWER_CONVERTERS]:
        'Электронные компоненты и силовые преобразователи',
    [TranslationKey.SUBLIBRARY_PASSIVE_ELEMENTS]: 'Пассивные элементы',
    [TranslationKey.SUBLIBRARY_BASIC_COMPONENTS]: 'Базовые компоненты',
    [TranslationKey.SUBLIBRARY_MEASURING_ELEMENTS]: 'Измерительные элементы',

    [TranslationKey.SUBLIBRARY_CMS]: 'Теплогидравлические системы',
    [TranslationKey.SUBLIBRARY_TRANSPORT]: 'Транспортные системы',
    [TranslationKey.SUBLIBRARY_RADIO_FREQUENCY_ANALYSIS]: 'РЧ анализ',

    [TranslationKey.ALL_LIBRARY_TYPES]: 'Междисциплинарная библиотека',

    // solvers
    [TranslationKey.SOLVER_USDS]: 'USDS',
    [TranslationKey.SOLVER_JAUTO]: 'JAuto',
    [TranslationKey.SOLVER_MDCORE]: 'MDCore',

    // Tariffs
    [TranslationKey.USER_SET_TARIFF_SUCCESS]: 'Тариф успешно изменен',
    [TranslationKey.USER_SET_TARIFF_ERROR]: 'Ошибка изменения тарифа',
    [TranslationKey.TARIFF_ACTIVE]: 'Активен',
    [TranslationKey.TARIFF_FUNCTIONAL]: 'Функционал',
    [TranslationKey.TARIFF_RESOURCES]: 'Ресурсы',
    [TranslationKey.TARIFF_FREE]: 'Бесплатно',
    [TranslationKey.TARIFF_SCHEMAS_AMOUNT]: 'Максимальное количество схем',
    [TranslationKey.TARIFF_BLOCKS_AMOUNT]: 'Максимальное количество блоков на схеме',
    [TranslationKey.TARIFF_MAX_REAL_TIME]: 'Реальное время моделирования',
    [TranslationKey.TARIFF_MAX_RAM]: 'Максимальное количество RAM',
    [TranslationKey.TARIFF_MAX_ROM]: 'Максимальное количество ROM',
    [TranslationKey.TARIFF_MAX_CPU]: 'Максимальное количество CPU',
    [TranslationKey.TARIFF_MAX_MODELING_TIME]: 'Время моделирования',
    [TranslationKey.TARIFF_JUTHON_BLOCK]: 'Блок jython',
    [TranslationKey.TARIFF_FMI_BLOCK]: 'Блок fmi',
    [TranslationKey.TARIFF_MAX_SIMULATIONS_COUNT]: 'Количество симуляций в сутки',
    [TranslationKey.TARIFF_MAX_DISK_SPACE]: 'Место на диске',
    [TranslationKey.TARIFF_UNCONNECTED]:
        'Тариф не подключен. Вы можете обратиться в техническую поддержку для его подключения.',
    [TranslationKey.AVAILABLE_MODULES]: 'Доступные модули',
    [TranslationKey.TARIFF_SUBSCRIPTION_TYPE]: 'Тип подписки',
    [TranslationKey.TARIFF_START_DATE]: 'Дата начала подписки',
    [TranslationKey.TARIFF_END_DATE]: 'Дата окончания',
    [TranslationKey.TARIFF_STATUS]: 'Статус подписки',
    [TranslationKey.TARIFF_LIMITED_TYPE]: 'Ограниченная',
    [TranslationKey.TARIFF_UNLIMITED_TYPE]: 'Без ограничений',
    [TranslationKey.TARIFF_STATUS_ACTIVE]: 'Активна',
    [TranslationKey.TARIFF_STATUS_NOT_RENEWED]: 'Непродлена',
    [TranslationKey.TARIFF_STATUS_CANCELED]: 'Отменена',
    [TranslationKey.TARIFF_WORKPLACE_COUNT]: 'Рабочие места',

    [TranslationKey.SECTION_IN_PROGRESS]: 'В разработке',
    [TranslationKey.CONTACT_SUPPORT]: 'Написать в тех.поддержку',
    [TranslationKey.LEAVE_PAGE_MESSAGE]:
        'Вы уверены, что хотите перейти на другую страницу? Все несохраненные данные будут утеряны и расчет модели остановится.',
    [TranslationKey.LOG_OUT_MESSAGE]:
        'При выходе из приложения несохраненные данные будут утеряны и расчет остановится. Вы уверены, что хотите продолжить?',

    // library
    [TranslationKey.FILTER_BY_NAME]: 'Фильтр по названию',
    [TranslationKey.ERROR_LIBRARY_ITEMS]: 'Ошибка загрузки элементов библиотеки',
    [TranslationKey.ELEMENT_IS_BLOCKED]: 'Блок временно недоступен.',
    [TranslationKey.LIBRARIES_ALL]: 'Библиотеки',
    [TranslationKey.LIBRARIES_AVAILABLE]: 'Доступные библиотеки',
    [TranslationKey.LIBRARY_NEW_BLOCK]: 'Новый блок',

    // Navigation menu
    [TranslationKey.SETTINGS_MENU_ITEM]: 'Профиль',
    [TranslationKey.API_KEYS_MENU_ITEM]: 'API ключи',
    [TranslationKey.EXIT_MENU_ITEM]: 'Выход',
    //workspace
    [TranslationKey.WORKSPACE_PAGE_VISUALIZATION]: 'Визуализация',
    [TranslationKey.WORKSPACE_PAGE_VARIABLES]: 'Переменные',
    [TranslationKey.WORKSPACE_PAGE_ERROR_VARIABLES]: 'Переменная должна быть уникальной',
    [TranslationKey.WORKSPACE_PAGE_PRESENTATION]: 'Презентация',
    [TranslationKey.WORKSPACE_PAGE_JOURNAL]: 'Журнал',
    //schema
    [TranslationKey.ERROR_LOADING_SCHEMA]: 'Ошибка получения схемы',
    [TranslationKey.ERROR_SAVING_SCHEMA]: 'Ошибка сохранения схемы',
    [TranslationKey.ERROR_GETTING_ELEMENT_PROPERTIES_SETS]:
        'Ошибка получения конфигураций блока. Вы можете выбрать настраиваемую конфигурацию.',
    [TranslationKey.ERROR_RUN_PROJECT]: 'Ошибка запуска расчета',
    [TranslationKey.ERROR_GENERATE_SOURCES]: 'Ошибка при генерации исходников',
    [TranslationKey.ERROR_GENERATE_NONAME_FILE]: 'Ошибка при генерации repeatCore файла',
    [TranslationKey.ERROR_FETCH_TREND_DATA]: 'Ошибка получения данных для визуализации',
    [TranslationKey.WARNING_VM_IS_IN_USE]: 'У вас уже запущен проект на расчет.',
    [TranslationKey.WARNING_ALL_VMS_ARE_BUSY]: 'Все виртуальные машины заняты.',
    [TranslationKey.WARNING_INTEGRATION_STEP_PRIORITY]:
        'Шаг интегрирования блока имеет приоритет над шагом интегрирования, указанным в проектных настройках.',
    [TranslationKey.MESSAGE_STOP_BY_USER]: 'Расчет полностью остановлен и данные расчета удалены',
    [TranslationKey.MESSAGE_STOP_FROM_SOLVER]: 'Расчет остановлен со стороны решателя',
    [TranslationKey.MESSAGE_ERROR_FROM_SOLVER]:
        'Расчет остановлен со стороны решателя из-за ошибки. Подробности в журнале.',
    [TranslationKey.MESSAGE_BUILD_ERROR_FROM_SOLVER]:
        'Расчет остановлен со стороны решателя из-за ошибки сборки задачи. Подробности в журнале.',
    [TranslationKey.MESSAGE_EXCEEDED_TIME_LIMIT_FROM_SOLVER]:
        'Расчет остановлен со стороны решателя. Достигнут лимит модельного времени.',
    [TranslationKey.MESSAGE_EXCEEDED_MEMORY_HEAP_SIZE_LIMIT_FROM_SOLVER]:
        'Расчет остановлен со стороны решателя. Превышен лимит оперативной памяти JVM.',
    [TranslationKey.MESSAGE_EXCEEDED_DB_SIZE_LIMIT_FROM_SOLVER]:
        'Расчет остановлен со стороны решателя. Превышен лимит памяти БД (500 Мб).',
    [TranslationKey.ERROR_STOP_PROJECT]: 'Ошибка остановки расчета',
    [TranslationKey.ERROR_FREEZE_PROJECT]: 'Ошибка паузы расчета',
    [TranslationKey.ERROR_CREATION_PROJECT]: 'Ошибка создания проекта',
    [TranslationKey.WORKSPACE_PAGE_SAVE]: 'Сохранить',
    [TranslationKey.WORKSPACE_PROJECT_TITLE]: 'Проект',
    [TranslationKey.WORKSPACE_BLOCK_TITLE]: 'Блок',
    [TranslationKey.WORKSPACE_SELECT_PROJECT]: 'Выберите проект',
    [TranslationKey.WORKSPACE_NO_PROJECTS_TO_SELECT]: 'Нет проектов для выбора',
    [TranslationKey.NEW_WINDOW_OPEN]: 'Открыть в новом окне',
    [TranslationKey.WORKSPACE_CHART_EDITOR_OPEN]: 'Открыть редактор графиков',
    [TranslationKey.WORKSPACE_CHART_EDITOR]: 'Редактор графиков',
    [TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS]: 'Аннотации',
    [TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_ADD]: 'Добавить',
    [TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_EMPTY]: 'Список аннотаций пустой',
    [TranslationKey.WORKSPACE_CHART_EDITOR_NOT_EDITABLE]:
        'Чтобы редактировать график, остановите или поставьте на паузу моделирование.',
    [TranslationKey.WORKSPACE_CHART_EDITOR_NO_ANNOTATIONS]: 'Нет текстовых аннотаций',
    [TranslationKey.WORKSPACE_PAGE_TOOLS]: 'Инструменты',
    [TranslationKey.WORKSPACE_ELEMENT_TITLE]: 'Блок',
    [TranslationKey.WORKSPACE_CONNECTION_TITLE]: 'Соединение',
    [TranslationKey.WORKSPACE_OBJECTS_TITLE]: 'Объекты',
    [TranslationKey.ERROR_NOT_FOUND_CODE]: 'Не определен код ответа',
    [TranslationKey.ERROR_CONNECT_SELECTOR]: 'Не установлено соединение для получения модельных данных расчета',
    [TranslationKey.ERROR_CONNECT_LOG]: 'Не установлено соединение для получения данных журнала',
    [TranslationKey.ERROR_CONNECT_TRENDS]: 'Не установлено соединение для получения данных трендов',
    [TranslationKey.WORKSPACE_PROPERTIES]: 'Свойства',
    [TranslationKey.WORKSPACE_PROPERTY]: 'Свойство',
    [TranslationKey.WORKSPACE_ADD_PROPERTY]: 'Добавить свойство',
    [TranslationKey.WORKSPACE_PARAMETERS]: 'Параметры блока, ось Y',
    [TranslationKey.WORKSPACE_PARAMETERS_X]: 'Время или параметр блока, ось X',
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS]: 'Параметры',
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_INPUTS]: 'Входы',
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_OUTPUTS]: 'Выходы',
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_WARNING_DELETE_CONNECTED_PORT]:
        'Порт соединен. При удалении параметра произойдет удаление соединения.',
    [TranslationKey.WORKSPACE_WIRE]: 'Проводник',
    [TranslationKey.WORKSPACE_PROPERTIES_NOT_FOUND]: 'Нет доступных свойств',
    [TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND]: 'Нет доступных параметров',
    [TranslationKey.WORKSPACE_PROPERTIES_NOT_DEFINED]: 'Свойства не назначены',
    [TranslationKey.WORKSPACE_PARAMETERS_NOT_DEFINED]: 'Параметры не назначены',
    [TranslationKey.WORKSPACE_ERROR_CHART]: 'Ошибка получения данных для построения графика',
    [TranslationKey.WORKSPACE_ERROR_CHART_CSV]: 'Ошибка при формировании файла CSV',
    [TranslationKey.WORKSPACE_WARNING_CHART_START]: 'График сконфигурирован. Запустите моделирование.',
    [TranslationKey.WORKSPACE_CHART_ADD]: 'Добавить график y(t)',
    [TranslationKey.WORKSPACE_CHART_TYPE_Y_T]: 'y(t)',
    [TranslationKey.WORKSPACE_CHART_TYPE_Y_X]: 'y(x)',
    [TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER]: 'Выберите параметр блока',
    [TranslationKey.WORKSPACE_CHART_POINT_VALUE]: 'Значение',
    [TranslationKey.WORKSPACE_CHART_DOWNLOAD_CSV]: 'Скачать CSV',
    [TranslationKey.WORKSPACE_CHART_CSV_TIME_TITLE]: 'Время, с',
    [TranslationKey.WORKSPACE_UNDO]: 'Отменить',
    [TranslationKey.WORKSPACE_REDO]: 'Вернуть',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_USDS]: 'Сгенерировать исходники под USDS',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_COMMON]: 'Сгенерировать C исходники',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_NOT_AVAILABLE]:
        'Сгенерировать исходники нельзя в процессе моделирования',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_CANCEL]: 'Отменить генерацию исходников',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_NO_LIVE_PERMISSION]:
        'Все рабочие места модуля «Кодогенерация» заняты. Генерация недоступна.',
    [TranslationKey.WORKSPACE_GET_NONAME_FILE]: 'Сгенерировать repeatCore файл',
    [TranslationKey.WORKSPACE_GET_NONAME_FILE_CANCEL]: 'Отменить генерацию repeatCore файла',
    [TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST]: 'Ошибка получения списка проектов',
    [TranslationKey.WORKSPACE_ERROR_GETTING_PROJECT_DATA]: 'Ошибка получения данных проекта',
    [TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_FOR_PROJECT_BLOCKS]:
        'Произошла ошибка получения проектов для одного или нескольких блоков "Проект"',
    [TranslationKey.WORKSPACE_PROJECT_BLOCKS_MODIFIED_CONNECTED_PROJECT]:
        'Для одного или нескольких блоков "Проект" внесены изменения в связанный проект',

    [TranslationKey.WORKSPACE_NOTEBOOK_TOOL]: 'Ноутбук',
    [TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL]: 'Ноутбук с ядром',
    [TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL_LINK_ERROR]:
        'Не получена ссылка для ноутбука с ядром. Пожалуйста, повторите попытку через некоторое время. Если ошибка повторится, обратитесь в тех.поддержку.',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL]: 'Расчет токов КЗ',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_START]: 'Запустить расчет',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT]: 'Вид КЗ',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE]: 'Режим',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MAX]: 'Максимальные токи',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MIN]: 'Минимальные токи',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT]: 'Отклонение, %',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT_FULL]: 'Отклонение напряжения в сетях низкого напряжения, %',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_DURATION]: 'Длительность КЗ, с',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_3]: 'Трёхфазное КЗ - К(3)',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_2]: 'Двухфазное КЗ - К(2)',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_1]: 'Однофазное КЗ - К(1)',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_NO_LIVE_PERMISSION]:
        'Все рабочие места модуля «Электродинамика» заняты. Расчет недоступен.',
    [TranslationKey.WORKSPACE_HARMONIC_TOOL]: 'Гармонический анализ',
    [TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED]: 'Данное действие недоступно в режиме просмотра',
    [TranslationKey.WORKSPACE_VIEW_MODE]: 'Режим просмотра',
    [TranslationKey.WORKSPACE_WARNING_PROJECT_CHANGED]: 'В проект внесены изменения',
    [TranslationKey.WORKSPACE_ELEMENT_ACTIONS]: 'Действия',
    [TranslationKey.WORKSPACE_ELEMENT_POSITIONING]: 'Позиционирование',
    [TranslationKey.WORKSPACE_ELEMENT_POSITION]: 'положение',
    [TranslationKey.WORKSPACE_ELEMENT_SIZE]: 'Размер',
    [TranslationKey.RULES_ELEMENT_MAX_COUNT]: 'Выбранный блок может быть только один на схеме.',
    [TranslationKey.ELEMENT_LIVE_PERMISSIONS_ADD_ERROR]:
        'Блок не добавлен в модель. Рабочее место модуля добавляемого блока занято. Подождите, когда рабочее место модуля освободится, и повторите попытку.',
    [TranslationKey.CHART_ELEMENT_MAX_COUNT]: 'Добавлено максимальное количество графиков.',
    [TranslationKey.ELEMENT_CONFIGURATION]: 'Конфигурация блока',
    [TranslationKey.ELEMENT_CONFIGURATION_NOTICE]:
        'Для добавляемого блока вы можете выбрать одну из представленных конфигураций или выбрать настраиваемую конфигурацию.',
    [TranslationKey.ELEMENT_CONFIGURATION_IS_NOT_RELEVANT]:
        'Перечень конфигураций был обновлен. Выбранная ранее конфигурация "{name}" неактуальна.',
    [TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE]: 'Настраиваемая конфигурация',
    [TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE_SELECT]: 'Выбрать настраиваемую конфигурацию',
    [TranslationKey.ELEMENT_CONFIGURATION_CURRENT]: 'Текущая конфигурация',
    [TranslationKey.ELEMENT_INITIALIZATION]: 'Инициализация блока {name}',
    [TranslationKey.ELEMENT_FILE_UPDATE]: 'Обновление файла блока {name}',
    [TranslationKey.ELEMENT_FILE_S_PARAMETERS_NAME]: 'S-параметры',
    [TranslationKey.ELEMENT_FILE_TOUCHSTONE_FORMAT]: 'формата Touchstone ver. 1',
    [TranslationKey.PROJECT_BLOCK_INITIALIZATION]: 'Инициализация блока Проект',
    [TranslationKey.ELEMENT_INITIALIZATION_NOTICE]:
        'Для добавления блока {name} выберите файл {extension}. Загрузка и инициализация запустятся автоматически. После инициализации добавьте на схему.',
    [TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE]:
        'Поддерживается выполнение моделей FMI 2.0 на ОС Linux x64 в режиме косимуляции (собранных с помощью GCC до версии 5.1.0 включительно).',
    [TranslationKey.ELEMENT_INITIALIZATION_DROPZONE]:
        'Переместите файл с расширением {extension} в область для загрузки или воспользуйтесь кнопкой "Загрузить файл {extension}" для выбора файла',
    [TranslationKey.ELEMENT_RFSPARAMETERS_INITIALIZATION_DROPZONE]:
        'Переместите файл формата Touchstone ver. 1 с расширением .s1p, .s2p, .s3p или .s4p, содержащий S-параметры моделируемого РЧ устройства, в область для загрузки.',
    [TranslationKey.ELEMENT_INITIALIZATION_SELECT]: 'Загрузить файл {extension}',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_LABEL]: 'Загрузка файла:',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_INITIALIZE_LABEL]: 'Инициализация:',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_SUCCESS]: 'Успешно завершена',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_FAILED]: 'Завершена с ошибкой.',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_LOADING]: 'В процессе',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_WAITING]: 'В ожидании загрузки файла',
    [TranslationKey.ELEMENT_INITIALIZATION_FMU_NOT_VALID]: 'Загруженный {extension} файл не соответствует требованиям',
    [TranslationKey.ELEMENT_INITIALIZATION_RUN_AGAIN]: 'Повторить?',
    [TranslationKey.ELEMENT_TEXT_BLOCK_TYPING]: 'Введите ваш текст по двойному клику',
    [TranslationKey.ERROR_OPEN_SCHEMA]:
        'Произошла ошибка при открытии схемы. Попробуйте еще раз. Если схема все равно не открывается, пожалуйста, обратитесь в тех.поддержку.',
    [TranslationKey.CONTEXT_SEARCH_PLACEHOLDER]: 'Введите название блока',
    [TranslationKey.ELEMENT_NOT_SUPPORTED]: 'Замените блок на обновленный блок из библиотеки',
    [TranslationKey.ELEMENT_ATTENTION_LABEL]: 'Предупреждение',
    [TranslationKey.ELEMENT_HAS_NO_GOTO_PAIR]: 'У блока отсутствует парный блок (GoTo или GoFrom)',
    [TranslationKey.ELEMENT_HAS_NOT_UNIQUE_GOTO]: 'Название GoTo блока должно быть уникальным. Переименуйте его.',
    [TranslationKey.SCHEMA_HAS_NOT_SUPPORTED_ELEMENT]:
        'Обнаружен блок с обратно несовместимыми изменениями после обновления библиотеки блоков. Замените на обновленный блок из библиотеки. Блок на замену отмечен красной рамкой на схеме. Действие будет доступно после замены блока на обновленный.',
    [TranslationKey.SCHEMA_HAS_NOT_SUPPORTED_ELEMENTS]:
        'Обнаружены блоки с обратно несовместимыми изменениями после обновления библиотеки блоков. Замените на обновленные блоки из библиотеки. Блоки на замену отмечены красной рамкой на схеме. Действие будет доступно после замены блоков на обновленные.',
    [TranslationKey.SCHEMA_HAS_INVALID_CONNECTION]: 'Обнаружено некорректное соединение в схеме.',
    [TranslationKey.SCHEMA_HAS_INVALID_CONNECTIONS]: 'Обнаружены некорректные соединения в схеме.',
    [TranslationKey.SCHEMA_INVALID_CONNECTION]: 'Некорректное соединение',

    [TranslationKey.SCHEMA_ELEMENT_EDIT_CODE]: 'Редактировать код блока',
    [TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA]: 'Вернуться к схеме',
    [TranslationKey.SCHEMA_ELEMENT_DELETED]: 'Блок удален',
    [TranslationKey.SCHEMA_ELEMENT_IS_DEPRECATED]: 'Блок устарел, поддержка прекратится после: ',
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA]: 'Перейти к редактированию',
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING]: 'Переход к редактированию',
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW]: 'Просмотреть',
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE]:
        ' Чтобы перейти к редактированию проекта {subProject}, требуется закрыть текущий проект.',
    [TranslationKey.WORKSPACE_USER_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE]:
        ' Чтобы перейти к редактированию блока, требуется закрыть текущий проект.',
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_CONFIRM_SAVING_PROJECT]: ' Сохранить изменения в проекте {mainProject} ?',
    [TranslationKey.WORKSPACE_MEMORY_USAGE]: 'Расчет использования памяти',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT]: 'максимальный размер - {size} Мб',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_ERROR]:
        'Расчетное значение использования памяти при моделировании показывает превышение максимально доступного лимита. При достижении лимита расчет будет остановлен со стороны решателя.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC]: 'Синхронизация с реальным временем',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC_TOOLTIP]:
        'При включенной синхронизации один шаг модельного времени будет ориентировочно соответствовать одному шагу реального времени. При большом объёме вычислений модельное время может отставать от реального. При выключенной синхронизации вычисление будет производиться с максимально возможной скоростью и обычно опережает реальное время.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME]: 'Время моделирования, с',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME_TOOLTIP]:
        'Модельное время, после которого расчёт будет остановлен.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP]: 'Шаг интегрирования, мс',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_TOOLTIP]:
        'Промежуток модельного времени между ближайшими рассчитываемыми состояниями модели. При большом промежутке расчёт может потерять устойчивость и результаты будут некорректными.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP]: 'Шаг дискретизации, мc',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_TOOLTIP]:
        'Промежуток модельного времени между ближайшими занесениями результатов расчёта в базу данных. Используется для регулирования объёма хранимых данных.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE]: 'Компрессия',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE_TOOLTIP]:
        'Для уменьшения объёма хранимых данных расчёта можно использовать компрессию. Алгоритм Рамера — Дугласа — Пекера (RDP) позволяет сжимать данные таким образом, чтобы максимальное отклонение не превышало заданной точности.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_ACCURACY]: 'Точность компрессии',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_TYPE]: 'Тип компрессии',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_CALCULATION]: 'Память, Мб',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING]: 'Моделирование',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_GRAPHS]: 'Графики',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_CALCULATION]: 'Расчет памяти',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_POINT]: 'Мб',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR]: 'Значение не может быть больше шага дискретизации',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_ERROR]:
        'Значение не может быть меньше шага интегрирования',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_MULTIPLICITY_ERROR]:
        'Значение должно быть кратно шагу интегрирования',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO]: 'Значение должно быть больше нуля',
    [TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR]: 'Укажите целое число',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TITLE]: 'Внешние модели',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TASKNAME]: 'Имя исполняемого файла',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST]: 'Адрес сервера',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST_NOT_VALID]: 'Значение должно быть localhost или IPv4',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT]: 'Порт сервера',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT_VALUE]: 'Укажите целое число от {min} до {max}',
    [TranslationKey.WORKSPACE_INVOLVED_MODULES]: 'Задействованные модули на схеме',

    // model statuses
    [TranslationKey.MODEL_STATUS_STOP_HELP]: 'Моделирование полностью остановлено. Данные расчета недоступны.',
    [TranslationKey.MODEL_STATUS_INIT_HELP]: 'Инициализация моделирования.',
    [TranslationKey.MODEL_STATUS_FREEZE_HELP]:
        'Моделирование поставлено на паузу. Можно продолжить расчет с момента остановки.',
    [TranslationKey.MODEL_STATUS_RUN_HELP]: 'Моделирование в процессе.',
    [TranslationKey.MODEL_STATUS_DATA_HELP]:
        'Моделирование остановлено. Данные расчета доступны для построения графиков.',
    [TranslationKey.MODEL_STATUS_CODE_HELP]: 'Генерация исходников в процессе.',

    [TranslationKey.MODEL_RUN_HELP]: 'Запустить моделирование',
    [TranslationKey.MODEL_SOFT_STOP_HELP]:
        'Остановить моделирование. После остановки также будут доступны данные расчета.',
    [TranslationKey.MODEL_STOP_HELP]: 'Завершить работу с данными расчета. Данные расчета будут удалены.',
    [TranslationKey.MODEL_FREEZE_HELP]: 'Поставить моделирование на паузу.',
    [TranslationKey.MODEL_CONTINUE_HELP]: 'Продолжить моделирование.',
    [TranslationKey.MODEL_TIME_UNIT]: 'с',

    // journal
    [TranslationKey.JOURNAL_EMPTY]: 'Нет сообщений',
    [TranslationKey.JOURNAL_STACKTRACE_TITLE]: 'Stacktrace',

    // code editor
    [TranslationKey.CODE_EDITOR_NODE_NOT_FOUND]: 'Не найден блок',
    [TranslationKey.CODE_EDITOR_PROPERTY_NOT_FOUND]: 'Не найдено свойство блока с исходным кодом',
    [TranslationKey.JYTHON_CODE_TAB_INIT]: 'Инициализация',
    [TranslationKey.JYTHON_CODE_TAB_BODY]: 'Код расчета',
    [TranslationKey.JYTHON_CODE_TAB_UNINIT]: 'Завершение',

    // app license
    [TranslationKey.LICENSE_KEY]: 'Лицензионный ключ',
    [TranslationKey.LICENSE_ACTIVATION_KEY]: 'Активация лицензионного ключа',
    [TranslationKey.LICENSE_ACTIVATION_CODE_SUBTITLE]: 'Получение строки активации',
    [TranslationKey.LICENSE_ACTIVATION_CODE_INFO]:
        'Для активации получите строку активации по кнопке "Получить строку активации". Отправьте полученную строку активации поставщику ПО, чтобы получить файл лицензии для активации ПО REPEAT.',
    [TranslationKey.LICENSE_ACTIVATION_CODE]: 'Строка активации',
    [TranslationKey.LICENSE_ACTIVATION_GET_CODE_BUTTON]: 'Получить строку активации',
    [TranslationKey.LICENSE_ACTIVATION_USER_HAS_FILE]:
        'Если у вас есть файл лицензии, то переходите к следующему шагу.',
    [TranslationKey.LICENSE_ACTIVATION_FILE_SUBTITLE]: 'Загрузка файла лицензии',
    [TranslationKey.LICENSE_ACTIVATION_NOTICE]:
        'Для активации введенного лицензионного ключа загрузите файл лицензии с расширением .lrpt. Активация запустится автоматически.',
    [TranslationKey.LICENSE_ACTIVATION_UPLOAD_HELP]:
        'Переместите файл с расширением .lrpt в область для загрузки или воспользуйтесь кнопкой "Загрузить файл .lrpt" для выбора файла.',
    [TranslationKey.LICENSE_ACTIVATION_UPLOAD_BUTTON]: 'Загрузить файл .lrpt',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_TITLE]: 'Статус активации',
    [TranslationKey.LICENSE_KEY_ISSUE_DATE_TITLE]: 'Дата выпуска',
    [TranslationKey.LICENSE_KEY_INPUT_DATE_TITLE]: 'Дата ввода',
    [TranslationKey.LICENSE_KEY_EXPIRE_DATE_TITLE]: 'Действует до',
    [TranslationKey.LICENSE_KEY_TRIAL_DATE_TITLE]: 'Активировать до',
    [TranslationKey.LICENSE_KEY_NO_EXPIRE]: 'Бессрочно',
    [TranslationKey.LICENSE_STATUS_ACTIVATION_COMPLETED]: 'Активация завершена',
    [TranslationKey.LICENSE_STATUS_ACTIVATION_REQUIRED]: 'Требуется активация',
    [TranslationKey.LICENSE_KEY_NOT_VALID]: 'Невалидный лицензионный ключ',
    [TranslationKey.LICENSE_KEY_NOT_FOUND]: 'Ключ не найден',
    [TranslationKey.LICENSE_ACTIVE_KEY]: 'Активный ключ',
    [TranslationKey.LICENSE_INPUT_NEW_KEY]: 'Ввести новый ключ',
    [TranslationKey.LICENSE_NEW_KEY]: 'Новый ключ',
    [TranslationKey.LICENSE_ACTIVATE_NEW_KEY]: 'Активировать',
    [TranslationKey.LICENSE_KEY_ANNOTATION]: 'Для работы с ПО REPEAT требуется пройти процедуру регистрации лицензии.',
    [TranslationKey.LICENSE_KEY_PATTERN_HELP]: 'введите 25-значный лицензионный ключ',
    [TranslationKey.LICENSE_HAVE_NO_DATA]:
        'Не удалось получить данные о состоянии лицензии. Попробуйте обновить страницу. Если ошибка будет повторяться, обратитесь в тех.поддержку.',
    [TranslationKey.LICENSE_BACK_TO_LIST]: 'Перейти к списку ключей',
    [TranslationKey.LICENSE_ACTIVATION_CODE_COPY]: 'Скопировать',
    [TranslationKey.LICENSE_ACTIVATION_CODE_COPIED]: 'Скопировано',
    [TranslationKey.LICENSE_EXPIRATION_SOON]: 'Истекает срок действия лицензии',
    [TranslationKey.LICENSE_EXPIRATION_LEFT_DAYS]: 'Осталось дней: {days}',
    [TranslationKey.LICENSE_EXPIRATION_TODAY]: 'Время до истечения: {hours} ч. {minutes} мин.',
    [TranslationKey.LICENSE_ACTIVE_TRIAL]: 'Ключ действует в режиме триал-периода. Пожалуйста, активируйте ключ.',
    [TranslationKey.LICENSE_ACTIVATION_SUCCESS]: 'Лицензия успешно активирована',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_UPLOAD_LABEL]: 'Загрузка файла:',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_ACTIVATION_LABEL]: 'Активация:',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_SUCCESS]: 'Успешно завершена',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_FAILED]: 'Завершена с ошибкой.',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_LOADING]: 'В процессе',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_UPLOAD_WAITING]: 'В ожидании загрузки файла',
    [TranslationKey.LICENSE_ACTIVATION_RUN_AGAIN]: 'Повторить?',
    [TranslationKey.LICENSE_SERVICE_UNAVAILABLE]: 'Менеджер лицензирования недоступен',
    [TranslationKey.LICENSE_GATEWAY_TIMEOUT]: 'Превышено время ожидания ответа от менеджера лицензирования',

    // about platform
    [TranslationKey.ABOUT_VERSION]: 'Версия',
    [TranslationKey.ABOUT_VERSION_BUILD]: 'Сборка',

    // settings
    [TranslationKey.SETTINGS_INTERFACE_TITLE]: 'Интерфейс',

    // tour
    [TranslationKey.TOUR_ITEM]: 'Тур по странице',
    [TranslationKey.TOUR_NEXT]: 'Дальше',
    [TranslationKey.TOUR_PREV]: 'Назад',
    [TranslationKey.TOUR_SKIP]: 'Пропустить',
    [TranslationKey.TOUR_FINISH]: 'Завершить',

    [TranslationKey.TOUR_PROJECTS_NEW]: 'Новый проект',
    [TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION]: 'По указанной ссылке откроется форма для создания нового проекта.',
    [TranslationKey.TOUR_PROJECTS_LIST]: 'Список проектов',
    [TranslationKey.TOUR_PROJECTS_LIST_DESCRIPTION]: 'В указанной области выводится список уже созданных проектов.',

    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL]: 'Контроллер',
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_DESCRIPTION]: 'Используйте для управления запуском модели на расчет',
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START]: 'Кнопка запуска',
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START_DESCRIPTION]: 'Запускает модель на расчет.',
    [TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE]: 'Проект',
    [TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE_DESCRIPTION]: 'Название текущего проекта.',
    [TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE]: 'Сохранить',
    [TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE_DESCRIPTION]: 'Кнопка сохранения текущего проекта.',
    [TranslationKey.TOUR_WORKSPACE_LIBRARY]: 'Библиотека блоков',
    [TranslationKey.TOUR_WORKSPACE_LIBRARY_DESCRIPTION]: 'Используйте блоки библиотеки для составления модели.',
    [TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM]: 'Блок',
    [TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM_DESCRIPTION]:
        'Кликните по нужному вам блоку и, удерживая левую кнопку мыши, переместите блок на холст.',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER]: 'Визуализация',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER_DESCRIPTION]:
        'Используйте данную кнопку для открытия панели Визуализации.',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB]: 'Вкладка Визуализация',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB_DESCRIPTION]: 'Используйте ее для открытия панели Визуализации',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA]: 'Область визуализации',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA_DESCRIPTION]:
        'В данной области выводится график выбранного параметра блока схемы при запущенной модели на расчет.',
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB]: 'Вкладка Журнал',
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION]: 'Используйте ее для открытия панели Журнал',
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA]: 'Область Журнала',
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION]: 'В данной области выводятся события расчета модели.',
    [TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL]: 'Правая панель',
    [TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL_DESCRIPTION]:
        'В данной области выводятся свойства и элементы управления выбранного блока на схеме.',

    // modal
    [TranslationKey.MODAL_HELP_SECTION_MORE_DETAILED_INFORMATION]:
        'Более подробную информацию можно найти в разделе «Справка» по',
    [TranslationKey.MODAL_TECH_SUPPORT_CONTACT]:
        'Если у вас есть какие-либо вопросы или предложения относительно работы приложения, свяжитесь с',
    [TranslationKey.US]: 'нами',
    [TranslationKey.MODAL_RELEASE_INFO_DONT_SHOW_UPDATES]: 'Больше не показывать обновления',
    [TranslationKey.MODAL_ACCOUNT_DELTE]: 'Подтверждение удаления',
    [TranslationKey.MODAL_ACCOUNT_EDIT]: 'Редактирование аккаунта',
    [TranslationKey.MODAL_ACCOUNT_ADD]: 'Добавление аккаунта',
    [TranslationKey.MODAL_ACCOUNT_DELETE_TEXT]: 'Удалить аккаунт',

    // COMMON
    [TranslationKey.SAVE]: 'Сохранить',
    [TranslationKey.GENERATE]: 'Сгенерировать',
    [TranslationKey.SAVE_AS]: 'Сохранить как...',
    [TranslationKey.DO_NOT_SAVE]: 'Не сохранять',
    [TranslationKey.ADD]: 'Добавить',
    [TranslationKey.HIDE]: 'Скрыть',
    [TranslationKey.SAVING]: 'Сохранение',
    [TranslationKey.SELECT]: 'Выбрать',
    [TranslationKey.DELETE]: 'Удалить',
    [TranslationKey.EDIT]: 'Редактировать',
    [TranslationKey.CANCEL]: 'Отмена',
    [TranslationKey.ROTATE]: 'Повернуть',
    [TranslationKey.CHANGE]: 'Изменить',
    [TranslationKey.EDITING]: 'Редактирование',
    [TranslationKey.SEND]: 'Отправить',
    [TranslationKey.TO_APP]: 'Перейти к приложению',
    [TranslationKey.ERROR_DATA]: 'Ошибка при получении данных с сервера',
    [TranslationKey.LANGUAGE_RUSSIAN]: 'Русский',
    [TranslationKey.LANGUAGE_ENGLISH]: 'Английский',
    [TranslationKey.ACCOUNT_MODE_DEFAULT]: 'Обычный режим',
    [TranslationKey.ACCOUNT_MODE_ENGINEER]: 'Режим инженера',
    [TranslationKey.LANGUAGE_RUSSIAN_SHORT]: 'RU',
    [TranslationKey.LANGUAGE_ENGLISH_SHORT]: 'EN',
    [TranslationKey.THEME]: 'Тема',
    [TranslationKey.THEME_DARK]: 'Тёмная тема',
    [TranslationKey.THEME_LIGHT]: 'Светлая тема',
    [TranslationKey.ICON_FORMAT]: 'Формат иконок',
    [TranslationKey.ICON_DEFAULT]: 'Стандартный',
    [TranslationKey.ICON_NEW]: 'С фоном',
    [TranslationKey.ERROR_PAGE_NOT_FOUND]: 'Страница не найдена',
    [TranslationKey.LANGUAGE_TITLE]: 'Язык',
    [TranslationKey.MODE_TITLE]: 'Режим',
    [TranslationKey.WHATS_NEW]: 'Что нового?',
    [TranslationKey.UNDERSTAND_THANKS]: 'Понятно, спасибо',
    [TranslationKey.LINK]: 'ссылке',
    [TranslationKey.TECHNICAL_SUPPORT]: 'Техническая поддержка',
    [TranslationKey.CONTACTING_TECHNICAL_SUPPORT]: 'Обращение в техническую поддержку',
    [TranslationKey.CALLING_TECHNICAL_SUPPORT]: 'Заполните информацию по обращению',
    [TranslationKey.TECHNICAL_SUPPORT_CAPTION]:
        'Заполните форму представленную выше или отправьте сообщение на почту: support@repeatlab.ru',
    [TranslationKey.SALES_DEPARTMENT]: 'Отдел продаж',

    [TranslationKey.MESSAGE]: 'Сообщение',
    [TranslationKey.ENTER_TEXT]: 'Введите текст',
    [TranslationKey.ENTER_MESSAGE]: 'Введите сообщение',
    [TranslationKey.MESSAGE_SENT_SUCCESSFULLY]: 'Сообщение успешно отправлено!',
    [TranslationKey.OK_THANKS]: 'Понятно, спасибо',
    [TranslationKey.ERROR]: 'Ошибка',
    [TranslationKey.CRITICAL_ERROR]:
        'Возникла критическая ошибка приложения. Если ошибка повторится, обратитесь, пожалуйста, в тех.поддержку.',
    [TranslationKey.WRITE_TO_TECHNICAL_SUPPORT]: 'Тех.поддержка',
    [TranslationKey.NEED_HELP]: 'Нужна помощь?',
    [TranslationKey.SUPPORT_SUBJECT]: 'Тема обращения',
    [TranslationKey.FEATURES_SUPPORT_VALUE]: 'Консультация по возможностям продукта',
    [TranslationKey.TARIFF_SUPPORT_VALUE]: 'Консультация по тарифам и приобретению продукта',
    [TranslationKey.AUTH_SUPPORT_VALUE]: 'Требуется помощь с авторизацией/регистрацией',
    [TranslationKey.OTHER_SUPPORT_VALUE]: 'Другое',

    [TranslationKey.COPY_TO_CLIPBOARD]: 'Скопировать в буфер обмена',
    [TranslationKey.COPIED_TO_CLIPBOARD]: 'Скопировано',
    [TranslationKey.NOT_COPIED_TO_CLIPBOARD]:
        'Не скопировано по причине настроек безоспасности вашего браузера. Скопируйте вручную.',
    [TranslationKey.NOT_COPIED_TO_CLIPBOARD_PROJECT_ID]:
        'Не скопировано по причине настроек безоспасности вашего браузера. Скопируйте вручную в правой панели рабочей области под названием проекта.',

    [TranslationKey.ERROR_SEND_REQUEST]: 'Ошибка отправки запроса',
    [TranslationKey.ERROR_SC_RESPONSE]: 'Ошибка расчета токов КЗ',
    [TranslationKey.WORKSPACE_PROPERTIES_CUSTOM_NAME]: 'Метка блока',
    [TranslationKey.ACCOUNT_ADDED]: 'Аккаунт добавлен',
    [TranslationKey.ACCOUNT_UPDATED]: 'Аккаунт обновлен',
    [TranslationKey.ACCOUNT_DELETE]: 'Аккаунт удален',
    [TranslationKey.GO_BACK_TO_AUTH_PAGE]: 'Вернуться на страницу авторизации',

    // CONTROLS
    [TranslationKey.ZOOM_IN]: 'Увеличить',
    [TranslationKey.ZOOM_OUT]: 'Уменьшить',
    [TranslationKey.FIT_VIEW]: 'Уместить схему',
    [TranslationKey.LOCK_CANVAS]: 'Заблокировать схему',
    [TranslationKey.UNLOCK_CANVAS]: 'Разблокировать схему',
    [TranslationKey.MINI_MAP]: 'Миникарта схемы',
    [TranslationKey.CLOSE]: 'Закрыть',
    [TranslationKey.CLOSE_PROJECT]: 'Закрыть и перейти к проектам',
    [TranslationKey.PROJECT_OPEN_ERROR]: 'Ошибка открытия проекта',
    [TranslationKey.PROJECT_BUSY_MODULES]: 'Занятые модули',
    [TranslationKey.BACK_TO_PROJECTS]: 'Вернуться к списку проектов',
    [TranslationKey.SCHEMA_SCREENSHOT]: 'Сохранить скриншот',

    // UNITS
    [TranslationKey.UNIT_SECOND]: 'с',

    // ENGINEER
    [TranslationKey.PAGE_ENGINEER_TITLE]: 'Список блоков',
    [TranslationKey.ENGINEER_COLUMN_NAME]: 'Наименование',
    [TranslationKey.ENGINEER_COLUMN_TYPE]: 'Класс',

    [TranslationKey.ENGINEER_CLEAR_ALL]: 'Удалить все',

    [TranslationKey.LIBRARYITEM_IMPORT_FILE_DESCRIPTION]:
        'Для импорта блока переместите файл с расширением .json в область для загрузки или воспользуйтесь кнопкой "Импортировать" для выбора файла .json. В файле могут находится как один блок так и несколько сразу',
    [TranslationKey.LIBRARYITEM_IMPORT_SELECT_FILE]: 'Выбрать файл',
    [TranslationKey.ENGINEER_EXPORT_BlOCK]: 'Экспорт в JSON',
    // File manager
    [TranslationKey.FILE_MANAGER_FILES_COUNT]: 'Элементов',
    [TranslationKey.FILE_MANAGER_SELECTED_COUNT]: 'Выбрано',
    [TranslationKey.FILE_MANAGER_OPEN_FILE]: 'Открыть',
    [TranslationKey.FILE_MANAGER_REMOVE_DIALOG]: 'Подтвердите удаление',
    [TranslationKey.FILE_MANAGER_NEW_FOLDER]: 'Новая папка',
    [TranslationKey.FILE_MANAGER_SORT_ASC_TOOLTIP]: 'Сортировка по возрастанию',
    [TranslationKey.FILE_MANAGER_SORT_DESC_TOOLTIP]: 'Сортировка по убыванию',
    [TranslationKey.FILE_MANAGER_SORT_DEFAULT_TOOLTIP]: 'Сортировка по типу',
    [TranslationKey.FILE_MANAGER_SORT_DATE]: 'Сортировка по дате',
    [TranslationKey.FILE_MANAGER_SELECT_ALL_TOOLTIP]: 'Выбрать все',
    [TranslationKey.FILE_MANAGER_CREATE_FOLDER_TOOLTIP]: 'Новая папка',
    [TranslationKey.FILE_MANAGER_PREVIEW_TOOLTIP]: 'Отображение превью',
    [TranslationKey.FILE_MANAGER_MANAGER_VIEW_TOOLTIP]: 'Изменить вид',
    [TranslationKey.FILE_MANAGER_MANAGER_VIEW_UPDATE]: 'Обновить',
    [TranslationKey.USER_BLOCK_EMPTY_MESSAGE]: 'Пользовательские блоки не найдены',
    [TranslationKey.USER_BLOCK_MODAL_WARN]:
        'В схеме пользовательского блока не должны использоваться глобальные переменные',

    [TranslationKey.SESSION_CONNECTION_LOST_TITLE]: 'Соединение потеряно',
    [TranslationKey.SESSION_CONNECTION_LOST_DESCRIPTION]:
        'Потеряно соединение с сервером, проверьте интернет соединение и попробуйте подключиться снова.',
    [TranslationKey.SESSION_CONNECTION_LOST_BUTTON]: 'Переподключиться',
    [TranslationKey.WORKSPACE_LIVE_PERMISSION_MODULES]: 'Статус соединения и доступные модули',

    [TranslationKey.FSM_IN_VARIABLE]: 'Входная',
    [TranslationKey.FSM_OUT_VARIABLE]: 'Выходная',
    [TranslationKey.FSM_INTERNAL_VARIABLE]: 'Внешняя',
    [TranslationKey.FSM_EXTERNAL_VARIABLE]: 'Внутренняя',
    [TranslationKey.FSM_WARNING_START_POINT]: 'Стартовая точка может быть только одна',
    [TranslationKey.FSM_STATE_CONDITION]: 'Условие',
    [TranslationKey.FSM_STATE_ACTION]: 'Действие',
    [TranslationKey.FSM_STATE_TITLE]: 'Заголовок',

    [TranslationKey.PROJECT_VERSIONS_HISTORY]: 'История версий',
    [TranslationKey.ERROR_PROJECT_HISTORY]: 'Ошибка получения версий проекта',
    [TranslationKey.EMPTY_PROJECT_HISTORY]: 'История версий пуста',
    [TranslationKey.EMPTY_COMMENT_PROJECT_HISTORY]: 'Нет комментария',
    [TranslationKey.PROJECT_HISTORY_LOAD_VERSION]: 'Возврат к версии',
    [TranslationKey.PROJECT_HISTORY_PREVIEW_VERSION]: 'Посмотреть версию',

    [CODE_LIST[7006]]: 'Расчет проекта запущен',
    [CODE_LIST[7007]]: 'Успешный запуск Селектора',
    [CODE_LIST[7008]]: 'Успешный запуск Трендов',
    [CODE_LIST[7009]]: 'Успешное закрытие канала Селектора',
    [CODE_LIST[7010]]: 'Успешное закрытие канала Трендов',
    [CODE_LIST[7011]]: 'Успешная установка параметров трендов',
    [CODE_LIST[7012]]: 'Успешная установка нового значения параметру',
    [CODE_LIST[7014]]: 'Успешная установка нового значения переменной',
    [CODE_LIST[7015]]: 'Виртуальная машина выделена для расчета',
    [CODE_LIST[7050]]: 'Успешная остановка проекта',
    [CODE_LIST[7051]]: 'Успешное сохранение проекта',
    [CODE_LIST[7052]]: 'Успешное получение последнего сохранения',
    [CODE_LIST[7053]]: 'Успешное удаление проекта',
    [CODE_LIST[7054]]: 'Успешная остановка задачи',
    [CODE_LIST[7055]]: 'Проект проинициализирован',
    [CODE_LIST[1001]]: 'Ошибка отправки стоп команды',
    [CODE_LIST[1057]]: 'Успешное закрытие соединения',
    [CODE_LIST[6001]]: 'Нет свободных виртуальных машин',
    [CODE_LIST[6501]]: 'Достигнуто максимальное количество запущенных проектов.',
    [CODE_LIST[1085]]: 'Некорректная схема проекта',
    [CODE_LIST[1101]]: 'Параметр projectId пуст',
    [CODE_LIST[1102]]: 'Параметр userId пуст',
    [CODE_LIST[1103]]: 'Заголовок userId пуст',
    [CODE_LIST[1104]]: 'Параметр serviceKey пуст',
    [CODE_LIST[1105]]: 'Элементы проекта пусты',
    [CODE_LIST[1106]]: 'В схеме есть неподдерживаемый тип элемента',
    [CODE_LIST[1107]]: 'У входного порта информация выходного',
    [CODE_LIST[1108]]: 'Связь обращается к несуществующему элементу',
    [CODE_LIST[1109]]: 'Связь обращается к несуществующему порту элемента',
    [CODE_LIST[1110]]: 'У входного порта есть выходной элемент',
    [CODE_LIST[1111]]: 'Указан неподдерживаемый тип библиотеки',
    [CODE_LIST[1112]]: 'Неизвестный тип решателя',
    [CODE_LIST[1113]]: 'Ошибка конвертации строки в нижний регистр',
    [CODE_LIST[1114]]: 'Тип решателя пуст',
    [CODE_LIST[1115]]: 'Тип библиотеки пуст',
    [CODE_LIST[1206]]: 'Телефон уже занят',
    [CODE_LIST[1207]]: 'Email уже занят',
    [CODE_LIST[1214]]: 'Некорректный формат телефона',
    [CODE_LIST[1227]]: 'Указан некорректный пароль',
    [CODE_LIST[1238]]: 'Некорректный email',
    [CODE_LIST[1356]]: 'Критическая ошибка потока вычислений',
    [CODE_LIST[1451]]: 'Шаг дискретизации должен быть кратен шагу интегрирования. Проверьте настройки проекта.',
    [CODE_LIST[1500]]: 'Схема содержит блоки, которые по тарифу недоступны для использования:',
    [CODE_LIST[6502]]: 'Ошибки решателя MDCore',
    [CODE_LIST[8002]]: 'Успешное закрытие Селектора',
    [CODE_LIST[3004]]: 'Ошибка добавления в список дэмо примеров. Пример уже добавлен',
    [CODE_LIST[1503]]: 'Проект использует модули, рабочие места по которым заняты.',
};
