import styled from 'styled-components';

import { Icon, pxToRem, uiColors } from '@repeat/ui-kit';

export const SIconTab = styled(Icon)`
    min-width: 20px;
`;

export const STabName = styled.span<{ isActive: boolean }>`
    color: ${({ isActive }) => (isActive ? 'var(--ui-text)' : 'var(--ui-label)')};
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

export const STabsWrapper = styled.div`
    .react-tabs {
        .react-tabs__tab-list {
            background: var(--ui-sidebar);
            margin: 0 -${pxToRem(16)} ${pxToRem(24)} -${pxToRem(16)};
            min-height: ${pxToRem(38)};
            position: sticky;
            top: ${pxToRem(38)};
            z-index: 2;
        }

        .react-tabs__tab {
            background-color: transparent;
            color: var(--ui-link-active) !important;
            border-radius: 0;
            border: none !important;
            padding: ${pxToRem(6)} ${pxToRem(12)};

            span {
                margin-left: ${pxToRem(4)};
            }

            &::after {
                display: none !important;
            }

            &.react-tabs__tab--selected {
                color: var(--ui-link-default) !important;
                border-bottom: 2px solid var(--ui-link-default) !important;

                svg {
                    fill: var(--ui-link-default) !important;
                }
            }
        }
    }
`;
