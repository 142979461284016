import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Status } from '@repeat/models';

import { DropZone } from './DropZone';
import { Progress } from './Progress';
import { SFormAttentionMessage, SFormTitle, SUploadForm } from './SUpload';

export const UploadForm: FC<{
    acceptFileTypes?: {
        [key: string]: string[];
    };
    maxFileSize?: number;
    uploadStatusInfo: {
        fileSizeBytes: number;
        uploadedBytes: number;
        status: Status;
    };
    initializeStatusInfo: {
        status: Status;
        error: string | null;
    };
    onFileUpload: (file: File) => void;
    onInitialize?: () => void;
    messages?: {
        title: string;
        attentionMessage?: string;
        dropZoneMessages: { uploadHelp: string; uploadButton: string };
    };
    extension?: string
}> = ({
    acceptFileTypes,
    maxFileSize,
    uploadStatusInfo,
    initializeStatusInfo,
    onFileUpload,
    onInitialize,
    messages,
    extension
}) => {
    const { formatMessage } = useIntl();

    return (
        <SUploadForm>
            {messages?.title && <SFormTitle>{messages.title}</SFormTitle>}

            {messages?.attentionMessage && <SFormAttentionMessage>{messages.attentionMessage}</SFormAttentionMessage>}
            <DropZone
                acceptFileTypes={acceptFileTypes}
                maxFileCount={1}
                maxFileSize={maxFileSize}
                onDropFile={onFileUpload}
                dropZoneMessages={messages && messages.dropZoneMessages}
            />
            <Progress
                uploadStatusInfo={uploadStatusInfo}
                initializeStatusInfo={initializeStatusInfo}
                onInitialize={onInitialize && onInitialize}
                extension={extension || ''}
            />
        </SUploadForm>
    );
};
