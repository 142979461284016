import { FC, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { workspaceActions, workspaceSelectors } from '@repeat/store';
import { uiColors } from '@repeat/ui-kit';

import { SButtons, SGroupName, SGroupNameBlock, SInput } from './SRightBar';

import { SIconButton } from '../CurrentItemOptions/ItemOptions/SItemProperty';

interface IGroupName {
    name: string;
}

export const GroupName: FC<IGroupName> = (props) => {
    const { name } = props;

    const dispatch = useAppDispatch();
    const groupId = useAppSelector(workspaceSelectors.selectedItems).elements[0].id;

    const [localValue, setLocalValue] = useState<string>(name);

    const [isInput, setIsInput] = useState(false);

    return (
        <SGroupNameBlock onDoubleClick={() => setIsInput(true)} isInput={isInput}>
            {isInput ? (
                <SInput value={localValue} onChange={(e) => setLocalValue(e.target.value)} />
            ) : (
                <SGroupName>{name}</SGroupName>
            )}

            {isInput && (
                <SButtons>
                    <SIconButton
                        noHover
                        fill={uiColors.darkGrey}
                        name='close'
                        size='big'
                        onClick={() => {
                            setIsInput(false);
                        }}
                        isLabelInput={isInput}
                    />
                    <SIconButton
                        noHover
                        fill={uiColors.green}
                        name='done'
                        size='big'
                        onClick={() => {
                            setIsInput(false);
                            dispatch(workspaceActions.setGroupName({ groupId: Number(groupId), name: localValue }));
                        }}
                    />
                </SButtons>
            )}
        </SGroupNameBlock>
    );
};
