import { CODE_LIST, TranslationKey } from '../translations.enum';

export const messages = {
    [TranslationKey.LOGIN]: 'Username',
    [TranslationKey.PASSWORD]: 'Password',
    [TranslationKey.PASSWORD_RESTORE]: 'Restore password',
    [TranslationKey.PASSWORD_RESTORE_INSTRUCTION_SENT]:
        'Password restore instruction have been sent, please check your email for more details',
    [TranslationKey.PASSWORD_CONFIRMATION]: 'Password verification',
    [TranslationKey.PASSWORD_NO_MATCH]: 'Passwords do not match',
    [TranslationKey.BYTES]: 'bytes',
    [TranslationKey.ROLE]: 'Role',

    [TranslationKey.ROLE_ADMIN]: 'Admin',

    [TranslationKey.PASSWORD_NEW]: 'New password',
    [TranslationKey.PASSWORD_NEW_REPEAT]: 'Repeat password',
    [TranslationKey.FORGOT_PASSWORD_TOKEN_INVALID]: 'Link is invalid',
    [TranslationKey.CREATE_TITLE]: 'Create',
    [TranslationKey.SIGN_IN]: 'Sign in',
    [TranslationKey.REGISTER]: 'Register',
    [TranslationKey.FINALIZE]: 'Finalize',
    [TranslationKey.NO_ACCOUNT]: 'No Account?',
    [TranslationKey.ACCOUNT_ALREADY_EXISTS]: 'Already have an account?',
    [TranslationKey.FORGOT_PASSWORD]: 'Forgot password?',
    [TranslationKey.ACCOUNT_PAGE]: 'Account',
    [TranslationKey.PERSONAL_DATA]: 'Personal data',
    [TranslationKey.TARIFFS]: 'Tariffs',
    [TranslationKey.SETTINGS]: 'Settings',
    [TranslationKey.EDIT_DATA_ACTION]: 'Edit',
    [TranslationKey.CHANGE_PASSWORD_ACTION]: 'Change password',
    [TranslationKey.NAME]: 'Name',
    [TranslationKey.EMAIL]: 'Email',
    [TranslationKey.PHONE]: 'Phone',
    [TranslationKey.EXTENSION_NUMBER_SUPPORT]: 'ext. 1, 2',
    [TranslationKey.EDIT_DATA]: 'Edit data',
    [TranslationKey.CHANGE_PASSWORD]: 'Change password',
    [TranslationKey.INFO]: 'Info',
    [TranslationKey.PROPERTIES]: 'Properties',
    [TranslationKey.CHARTS]: 'Graphs',
    [TranslationKey.CHART]: 'Graph',
    [TranslationKey.VARIABLE]: 'Variable',
    [TranslationKey.ELEMENT]: 'Element',
    [TranslationKey.PARAMETER]: 'Parameter',
    [TranslationKey.PARAMETERS]: 'Parameters',
    [TranslationKey.NO_PARAMETERS]: 'No parameters to select',
    [TranslationKey.METER_UNITS]: 'Order of units of measurement',
    [TranslationKey.METER_UNITS_FACTOR]: 'Gain factor',
    [TranslationKey.NO_ELEMENTS]: 'No blocks to select',
    // Blocks group
    [TranslationKey.GROUP_NEW]: 'Create group',
    [TranslationKey.GROUP_SAVE_AS_BLOCK]: 'Save as block',
    [TranslationKey.GROUP_CREATING_WARNING_TITLE]: 'Warning',
    [TranslationKey.GROUP_CREATING_WARNING_ACCEPT]: 'Accept and continue',
    [TranslationKey.GROUP_CREATING_WARNING_BLOCKS]:
        'Blocks related to the blocks being grouped have been detected. Therefore, the following blocks will also be moved to the group:',
    [TranslationKey.GROUP_CREATING_WARNING_CHARTS]:
        'Graphs configured to the above blocks are also detected. The following graphs will be deleted when the group is created:',

    // User blocks
    [TranslationKey.CUSTOM]: 'My blocks',
    [TranslationKey.USER_BLOCK_EDIT_TITLE]: 'Editing a block',
    [TranslationKey.USER_BLOCK_EDIT_VIEW_TITLE]: 'Change the system parameters of the block',
    [TranslationKey.USER_BLOCK_EDIT_SYSTEM_PARAMETERS]: 'Edit system parameters',
    [TranslationKey.USER_BLOCK_SAVE_TITLE]: 'Save changes',
    [TranslationKey.USER_BLOCK_SAVED]: 'The block has been successfully saved',
    [TranslationKey.USER_BLOCK_TITLE]: 'Title',
    [TranslationKey.USER_BLOCK_SUBSECTION]: 'Subsection',
    [TranslationKey.USER_BLOCK_SUBSECTION_PLACEHOLDER]: 'Select a subsection or enter its name',
    [TranslationKey.USER_BLOCK_NO_SUBSECTIONS]: 'No options to choose from',
    [TranslationKey.USER_BLOCK_ICON]: 'Image',
    [TranslationKey.USER_BLOCK_ICON_CHANGE]: 'Change',
    [TranslationKey.USER_BLOCK_ICON_SCALE]: 'Scale',
    [TranslationKey.USER_BLOCK_ICON_ROTATE]: 'Rotate',
    [TranslationKey.USER_BLOCK_DESCRIPTION]: 'Description...',
    [TranslationKey.USER_BLOCK_DELETE_CONFIRM]: 'The block may already be used in projects. Confirm deletion',
    [TranslationKey.USER_BLOCK_NEW]: 'New block',
    [TranslationKey.USER_BLOCK_CHANGED_NOTIFICATION]: 'Changes have been made to the block',
    [TranslationKey.USER_BLOCK_DELETED_NOTIFICATION]: 'The block has been removed',
    [TranslationKey.USER_BLOCK_GET_INFO_ERROR]: 'Block data retrieval error',
    [TranslationKey.USER_BLOCKS_MODIFIED_NOTIFICATION]: 'One or more user blocks have been deleted or modified.',
    [TranslationKey.USER_BLOCK_TRANSFORM_TO_GROUP]: 'Transform to group',

    [TranslationKey.PROJECT_NEW]: 'New project',
    [TranslationKey.PROJECT_EDIT]: 'Edit project',
    [TranslationKey.PROJECT_OPEN]: 'Open project',
    [TranslationKey.PROJECT_IMPORT]: 'Import',
    [TranslationKey.PROJECT_EXPORT]: 'Export',
    [TranslationKey.PROJECT_NAME]: 'Project title',
    [TranslationKey.PROJECT_LIBRARY]: 'Library',
    [TranslationKey.PROJECT_SOLVER]: 'Solver',
    [TranslationKey.PROJECT_CREATED]: 'The project has been successfully created',
    [TranslationKey.PROJECT_IMPORTED]: 'The project has been successfully imported',
    [TranslationKey.PROJECT_EXPORTED]: 'The project has been successfully exported',
    [TranslationKey.PROJECTS_CREATED]: 'Created projects',
    [TranslationKey.PROJECT_NOT_SUPPORTED]: 'is not supported',
    [TranslationKey.PROJECT_NOT_SUPPORTED_USDS]:
        'Dear User! Since version 1.4.0 we disabled support for projects created on the USDS solver. We apologize for that! If necessary, you can contact technical support.',
    [TranslationKey.PROJECT_IMPORT_MODAL_TITLE]: 'Project import',
    [TranslationKey.LIBRARYITEM_IMPORT_MODAL_TITLE]: 'Block import ',
    [TranslationKey.PROJECT_IMPORT_FILE_DESCRIPTION]:
        'To import a project, move the file with the .rpt extension to the area to load or use the "Import" button to select the .rpt file',
    [TranslationKey.PROJECT_IMPORT_SELECT_FILE]: 'Import',
    [TranslationKey.ERROR_PROJECT_NOT_IMPORTED]: 'Error during import. Project not imported.',
    [TranslationKey.ERROR_PROJECT_NOT_EXPORTED]: 'Export error. Project not exported.',
    [TranslationKey.ERROR_PROJECT_NOT_LOAD]: 'Error in getting project data.',
    [TranslationKey.BLOCK_IMPORT_ERROR]: 'Block is already exist!',
    [TranslationKey.PROJECT_SAVED]: 'The project has been successfully saved',
    [TranslationKey.PROJECT_DELETED]: 'The project has been successfully deleted',
    [TranslationKey.PROJECTS_FILTER_BY_TITLE]: 'filter by title',
    [TranslationKey.PROJECTS_COLUMN_TITLE]: 'Project',
    [TranslationKey.PROJECTS_COLUMN_CREATED_AT]: 'Created',
    [TranslationKey.PROJECTS_COLUMN_UPDATED_AT]: 'Updated',
    [TranslationKey.PROJECT_DELETE_TITLE]: 'Confirm deletion',
    [TranslationKey.PROJECT_DELETE_CONFIRMATION]: 'Are you sure you want to delete the "{title}" project?',
    [TranslationKey.PROJECTS_DELETE_CONFIRMATION]: 'Are you sure you want to delete selected projects?',
    [TranslationKey.PROJECT_DELETE_NOT_SELECTED]: 'No project has been selected for deletion.',
    [TranslationKey.PROJECT_EDIT_NOT_SELECTED]: 'No project has been selected for editing.',
    [TranslationKey.PROJECT_DELETE_DROPDOWN]: 'Remove',
    [TranslationKey.PROJECT_EDIT_DROPDOWN]: 'Edit',
    [TranslationKey.PROJECT_EXPORT_DROPDOWN]: 'Export',
    [TranslationKey.PROJECT_DUPLICATE_DROPDOWN]: 'Duplicate',
    [TranslationKey.DEMO_PROJECT_DUPLICATE_DROPDOWN]: 'Add to projects',
    [TranslationKey.PROJECT_ADD_TO_DEMO_DROPDOWN]: 'Add to demo projects',
    [TranslationKey.PROJECT_REMOVE_FROM_DEMO_DROPDOWN]: 'Remove from demo projects',
    [TranslationKey.PROJECT_COPY_ID_DROPDOWN]: 'Copy project id',
    [TranslationKey.PROJECT_LIST_EMPTY]: "You don't have any saved projects yet",
    [TranslationKey.DEMO_PROJECT_LIST_EMPTY]: "You don't have any demo projects yet",
    [TranslationKey.PROJECT_LIST_BY_FILTER_EMPTY]: 'No projects found by filtering criteria',
    [TranslationKey.BLOCK_NEW]: 'Add block',
    [TranslationKey.LIBRARYITEM_IMPORT_SELECT_FILE]: 'Select file',
    [TranslationKey.COMPANY_BASE]: 'Basic information',
    [TranslationKey.COMPANY_BANK]: 'Bank details',
    [TranslationKey.COMPANY_TITLE]: 'Title',
    [TranslationKey.COMPANY_INN]: 'INN',
    [TranslationKey.COMPANY_KPP]: 'KPP',
    [TranslationKey.COMPANY_OGRN]: 'OGRN',
    [TranslationKey.COMPANY_LEGAL_ADDRESS]: 'Legal address',
    [TranslationKey.COMPANY_POSTAL_ADDRESS]: 'Postal address',
    [TranslationKey.COMPANY_BANK_ACCOUNT]: 'Bank account',
    [TranslationKey.COMPANY_BANK_BIK]: 'BIK',
    [TranslationKey.COMPANY_BANK_TITLE]: 'Bank name',
    [TranslationKey.COMPANY_BANK_INN]: 'INN',
    [TranslationKey.COMPANY_BANK_KPP]: 'KPP',
    [TranslationKey.COMPANY_BANK_CORRESPONDENT_ACCOUNT]: 'Correspondent account',
    [TranslationKey.USER_AGREEMENT]: 'User agreement',
    [TranslationKey.PRIVACY_POLICY_AGREEMENT]: 'Personal data processing policy',
    [TranslationKey.REGLAMENT_TP]: 'TA Regulations',

    [TranslationKey.API_KEYS_LIST_EMPTY]: "You don't have any created API keys",
    [TranslationKey.API_KEYS_COLUMN_KEY]: 'API key',
    [TranslationKey.API_KEYS_COLUMN_NAME]: 'Title',
    [TranslationKey.API_KEYS_COLUMN_EXPIRE_AT]: 'Expires',
    [TranslationKey.API_KEYS_COLUMN_CREATED_AT]: 'Created',
    [TranslationKey.API_KEYS_NEW]: 'New API key',
    [TranslationKey.API_KEYS_DELETE]: 'Delete API key',
    [TranslationKey.API_KEY_DELETE_TITLE]: 'Confirm API key deletion',
    [TranslationKey.API_KEY_DELETE_CONFIRMATION]: 'Are you sure you want to delete the "{title}" API key?',
    [TranslationKey.API_KEY_DELETE_NOT_SELECTED]: 'No API key has been selected for deletion.',
    [TranslationKey.API_KEY_NAME]: 'Title',
    [TranslationKey.API_KEY_EXPIRE_AT]: 'Expiration date',
    [TranslationKey.ERROR_NEW_API_KEY_NAME]: 'API key name already exists',
    [TranslationKey.ERROR_API_KEY_NAME_IS_EMPTY]: 'API key name cannot contain only spaces',
    [TranslationKey.API_KEY_CREATED]: 'The API key has been successfully created',
    [TranslationKey.API_KEY_DELETED]: 'The API key has been successfully deleted',

    [TranslationKey.HELP_SECTION_COMMON]: 'General information',
    [TranslationKey.HELP_SECTION_INTERFACE]: 'Description of user interface',
    [TranslationKey.HELP_SECTION_TASK]: 'Design mode',
    [TranslationKey.HELP_SECTION_MODELING]: 'Simulation mode',
    [TranslationKey.HELP_SECTION_CHANGELOG]: 'Version platform history',
    [TranslationKey.HELP_SECTION_VERSIONS_TITLE]: 'Versions',
    [TranslationKey.HELP_SECTION_SUPPORT]: 'Technical support',
    [TranslationKey.HELP_SECTION_HOTKEYS]: 'Hotkeys',
    [TranslationKey.HELP_ERROR_FETCH_HISTORY_VERSION_DATA]:
        'Error when receiving data from the server. Failed to load version history on the «Help» page',
    [TranslationKey.HELP_BLOCK_SUMMARY]: 'Summary',
    [TranslationKey.HELP_BLOCK_NEW]: 'New',
    [TranslationKey.HELP_BLOCK_IMPROVEMENTS]: 'Improvements',
    [TranslationKey.HELP_BLOCK_FIXES]: 'Fixes',
    [TranslationKey.HELP_BLOCK_ADDITIONAL]: 'Additional',

    // pages
    [TranslationKey.PAGE_MAIN_TITLE]: 'Main page',
    [TranslationKey.PAGE_TARIFFS_TITLE]: 'Tariffs',
    [TranslationKey.PAGE_PROJECTS_TITLE]: 'Projects',
    [TranslationKey.PAGE_DEMO_PROJECTS_TITLE]: 'Demo projects',
    [TranslationKey.PAGE_FAV_PROJECTS_TITLE]: 'Favorite projects',
    [TranslationKey.PAGE_FAV_PROJECTS_EMPTY]: 'No favorites projects',
    [TranslationKey.PAGE_FAV_PROJECT_SUCCESS]: 'Project successfully added to favorites',
    [TranslationKey.PAGE_FAV_PROJECT_DELETE]: 'Project successfully removed from favorites',
    [TranslationKey.PAGE_FAV_PROJECTS_SUCCESS]: 'Projects successfully added to favorites',
    [TranslationKey.PAGE_DEMO_PROJECT_SUCCESS]: 'Projects successfully added to demo',
    [TranslationKey.PAGE_DEMO_PROJECT_REMOVED]: 'Projects successfully removed from demo',
    [TranslationKey.PAGE_PROJECT_DELETE_FAV]: 'Remove project from favorites',
    [TranslationKey.PAGE_PROJECT_ADD_FAV]: 'Add to Favorite projects',
    [TranslationKey.PAGE_PROJECTS_ADD_FAV]: 'Add selected to Favorite projects',
    [TranslationKey.PAGE_PROJECTS_REMOVE_FAV]: 'Remove selected from Favorite',
    [TranslationKey.PAGE_PROJECTS_REMOVE]: 'Remove selected projects',
    [TranslationKey.PAGE_ACCOUNT_TITLE]: 'Account',
    [TranslationKey.PAGE_ACCOUNT_EDIT_TITLE]: 'Edit account',
    [TranslationKey.PAGE_PASSWORD_TITLE]: 'Change password',
    [TranslationKey.PAGE_COMPANY_TITLE]: 'Company',
    [TranslationKey.PAGE_TARIFF_TITLE]: 'Tariff',
    [TranslationKey.PAGE_SETTINGS_TITLE]: 'Settings',
    [TranslationKey.PAGE_API_KEYS_TITLE]: 'API keys',
    [TranslationKey.PAGE_HELP_TITLE]: 'Help and support',
    [TranslationKey.PAGE_VERSIONS_HISTORY_TITLE]: 'Version platform history',
    [TranslationKey.PAGE_LICENSE_TITLE]: 'License',
    [TranslationKey.PAGE_HELP_SUBTITLE]: 'The REPEAT simulation platform',
    [TranslationKey.PAGE_ENGINEER_TITLE]: 'List of blocks',
    [TranslationKey.ENGINEER_MODE_TITLE]: 'Engineer mode',
    [TranslationKey.ENGINEER_CLEAR_ALL]: 'Delete all',
    [TranslationKey.ENGINEER_COLUMN_NAME]: 'Name',
    [TranslationKey.ENGINEER_COLUMN_TYPE]: 'Type',
    [TranslationKey.ENGINEER_EXPORT_BlOCK]: 'Export into JSON',
    [TranslationKey.LIBRARYITEM_IMPORT_FILE_DESCRIPTION]:
        'To import a block, move the file with the .json extension to the area to load or use the Import button to select the .json file',
    [TranslationKey.PAGE_ABOUT_TITLE]: 'About platform',
    [TranslationKey.PAGE_DASHBOARD_TITLE]: 'Dashboard',
    [TranslationKey.PAGE_BALANCE_TITLE]: 'Crediting/Debiting funds',
    [TranslationKey.PAEGE_DOCUMENTS_TITLE]: 'Documents',
    [TranslationKey.PAGE_USERS_TITLE]: 'Account administration',
    [TranslationKey.PAGE_SUBSCRIPTIONS_TITLE]: 'Newsletter',
    [TranslationKey.PAGE_REFUND_TITLE]: 'Refund funds',
    [TranslationKey.PAGE_LICENSE_PAGE]: 'License management',
    [TranslationKey.USERS_PAGE_ADD_ACOUNT]: 'Add account',
    [TranslationKey.PAGE_LEGAL_DATA_TITLE]: 'Legal Data',
    [TranslationKey.PAGE_CONTACTS_TITLE]: 'Contacts',
    [TranslationKey.TARIFF_WORKPLACE_COUNT]: 'Workplaces',

    [TranslationKey.SOCKET_ERR_MSG]: 'Connection (socket) failed',
    [TranslationKey.NETWORK_ERR_MSG]: 'Network error',
    [TranslationKey.NAME_ERROR_MSG]: 'Only letters, numbers and symbols (- and _) are allowed',
    [TranslationKey.ONLY_DIGITS_ERROR_MSG]: 'Digits only',
    [TranslationKey.ONLY_PHONE_ERROR_MSG]: 'Only digits and +, and three digits between parenthesis',
    [TranslationKey.TOO_MANY_ERROR_MSG]: 'Not greater then {num} symbols',
    [TranslationKey.TOO_FEW_ERROR_MSG]: 'Not shorter when {num} characters',
    [TranslationKey.ERROR_PHONE_NOT_CORRECT]: 'Not correct phone number',
    [TranslationKey.ERROR_PASSWORD_MIN_LENGTH]: 'At least 3 characters',
    [TranslationKey.OBLIGATORY_FIELD_MSG]: 'Required',
    [TranslationKey.MALFORMED_EMAIL_MSG]: 'Should be kind of email',
    [TranslationKey.FLOAT64_MSG]: 'Should be of type FLOAT64 (example: 5.0E-7)',

    [TranslationKey.LOGIN_IN_USE]: 'Login is already in use',
    [TranslationKey.PHONE_IN_USE]: 'Phone number is already in use',
    [TranslationKey.EMAIL_IN_USE]: 'Email is already in use',

    [TranslationKey.MESSAGE_ACCOUNT_UPDATED]: 'Data successfully updated',
    [TranslationKey.MESSAGE_PASSWORD_UPDATED]: 'Password successfully updated',
    [TranslationKey.MESSAGE_REGISTRATION_SUCCESS]: 'You have been successfully registered',
    [TranslationKey.MESSAGE_REGISTRATION_ON_PAGE_LEAVE]: 'Are you sure you wont to leave. All changes will be lost',
    [TranslationKey.MESSAGE_DOWNLOAD_DOCUMENT_ERROR]:
        'There was a problem with the server, the file «{invoice}-{id}.pdf» could not be downloaded. Try again later',

    // profile
    [TranslationKey.USER_ACCOUNT]: 'User Account',
    [TranslationKey.ERROR_ACCOUNT_UPDATE]: 'Error on account update',
    [TranslationKey.ERROR_ACCOUNT_SETTINGS_UPDATE]: 'Error saving account settings',

    //account
    [TranslationKey.SELECT_ACCOUNT_TYPE]: 'Select an account type',
    [TranslationKey.INDIVIDUAL]: 'Individual',
    [TranslationKey.ENTREPRENEUR]: 'Entrepreneur',
    [TranslationKey.COMPANY_RESIDENT]: 'Company (RF Residence)',
    [TranslationKey.COMPANY_NON_RESIDENT]: 'Company (Non RF Residence)',
    [TranslationKey.EDUCATIONAL_UNIT]: 'Educational Unit',

    // account table
    [TranslationKey.TABLE_BALANCE_COLUMN_TRANSACTION_NAME]: 'Transactions',
    [TranslationKey.TABLE_BALANCE_COLUMN_SUM]: 'Sum',
    [TranslationKey.TABLE_BALANCE_COLUMN_DATE]: 'Date',
    [TranslationKey.TABLE_BALANCE_COLUMN_TRANSACTION_ID]: 'Transaction number',
    [TranslationKey.TABLE_BALANCE_SEARCH_PLACEHOLDER]: 'Transaction search...',
    [TranslationKey.TABLE_BALANCE_ERROR_DATA]:
        'Error when receiving data from the server. Failed to load «Crediting/Debiting funds» page',
    [TranslationKey.TABLE_BALANCE_EMPTY_DATA]: "You don't have any transactions available yet",
    [TranslationKey.TABLE_DOCUMENT_COLUMN_TYPE]: 'Type',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_SUM]: 'Sum',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_VAT]: 'Vat',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_TOTAL]: 'Total',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_DOCUMENT_ID]: 'Number',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_DATE]: 'Date',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_STATUS]: 'Status',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_PERSONAL_ACCOUNT]: 'Personal account',
    [TranslationKey.TABLE_DOCUMENT_COLUMN_CLIENT_NAME]: 'Client: Name',
    [TranslationKey.TABLE_DOCUMENT_ERROR_DATA]:
        'Error when receiving data from the server. Failed to load «Documents» page',
    [TranslationKey.TABLE_DOCUMENT_EMPTY_DATA]: "You don't have any documents available yet",
    [TranslationKey.TARIFF_ERROR_DATA]:
        'Error when receiving data from the server. Failed to load the «Tariff» section',
    [TranslationKey.TARIFFS_ERROR_DATA]:
        'Error when receiving data from the server. Failed to load the «Tariffs grid» section',
    [TranslationKey.TARIFF_CHANGE]: 'Сhange tariff',
    [TranslationKey.TABLE_USERS_COLUMN_FULLNAME]: 'Fullname',
    [TranslationKey.TABLE_USERS_COLUMN_ROLE]: 'Role',
    [TranslationKey.TABLE_USERS_COLUMN_DATE]: 'Date of registration',
    [TranslationKey.TABLE_USERS_EMPTY_DATA]: `You don't have any accounts added yet`,
    [TranslationKey.USERS_DATA]: 'Users',

    //student role
    [TranslationKey.DEFAULT]: 'User',
    [TranslationKey.ADMINISTRATOR]: 'Administrator',

    //form
    [TranslationKey.FORM_ADD_ACCOUNT_ID]: 'Account ID',
    [TranslationKey.FORM_ADD_ACCOUNT_ROLE]: 'Сhoose a role',
    // ERRORS
    [TranslationKey.ERROR_SIGIN_DATA]: 'Incorrect login or password',
    [TranslationKey.ERROR_SIGNUP_DATA]: 'Registration error',
    [TranslationKey.ERROR_NEW_PROJECT_NAME]: 'Project name already exists',
    [TranslationKey.ERROR_PROJECT_NAME_IS_EMPTY]: 'The project name cannot contain only spaces',
    [TranslationKey.ERROR_UNKNOWN]: 'Unknown error',
    [TranslationKey.ERROR_BANK_ACCOUNT_FORMAT]: 'Bad formed bank account format',
    [TranslationKey.ERROR_KPP_FORMAT]: 'Bad formed KPP',
    [TranslationKey.ERROR_BIK_FORMAT]: 'Bad formed BIK',
    [TranslationKey.ERROR_ORGN_FORMAT]: 'Bad formed OGRN (rus. ОГРН)',
    [TranslationKey.ERROR_OGRNIP_FORMAT]: 'Bad formed OGRNIP (rus. ОГРНИП)',
    [TranslationKey.ERROR_TAX_NUMBER_FORMAT]: 'Bad formed Individual Tax Number',
    [TranslationKey.ERROR_TAX_NUMBER_BANK_FORMAT]: 'Bad formed Bank Tax Number',
    [TranslationKey.ERROR_TERMS_AND_CONDITIONS]: 'You Should agree to terms of service',
    [TranslationKey.ERROR_PRIVACY_POLICY]: 'You Should agree to privacy policy of service usage',
    [TranslationKey.ERROR_LARGE_FILE]: 'File size is more than accepted',
    [TranslationKey.ERROR_SMALL_FILE]: 'File size is too small',
    [TranslationKey.ERROR_INVALID_TYPE_FILE]: 'The selected file is not in the correct format',
    [TranslationKey.ERROR_TOO_MANY_FILES_TEXT]: 'Please select no more than {count} {file} to move to the add zone',
    [TranslationKey.ERROR_TOO_MANY_FILE]: 'file',
    [TranslationKey.ERROR_TOO_MANY_FILES]: 'files',
    [TranslationKey.ERROR_SEND_MESSAGE_TO_SUPPORT]: 'An error occurred while sending the message',

    [TranslationKey.FULL_TITLE]: 'Full name',
    [TranslationKey.TAX_NUMBER]: 'Tax number',
    [TranslationKey.ORGNIP]: 'ОГРНИП',
    [TranslationKey.ORGN]: 'ОГРН',
    [TranslationKey.KPP]: 'КПП',
    [TranslationKey.KIO]: 'КИО',
    [TranslationKey.ADDR_LEGAL]: 'Legal Address',
    [TranslationKey.ADDR_SAME_AS_LEGAL]: 'Same as the Legal Address',
    [TranslationKey.ADDR_POST]: 'Postal Address',

    [TranslationKey.LICENSE_EDU]: 'Educational license',
    [TranslationKey.LICENSE_INFO]: 'information about license',
    [TranslationKey.LICENSE_INFO_EDU_NUMBER]: 'The number of the license',
    [TranslationKey.LICENSE_INFO_EDU_DATE]: 'The date of the license',

    [TranslationKey.BANK_NAME]: 'Bank name',
    [TranslationKey.ACCOUNT_NUMBER]: 'Account number',
    [TranslationKey.BIC]: 'Bank BIC',
    [TranslationKey.KPP_BANK]: 'Bank KPP',
    [TranslationKey.TAX_NUMBER_BANK]: 'Bank Tax Number',
    [TranslationKey.CORRESPONDENT_ACCOUNT]: 'Bank Correspondent Account',

    [TranslationKey.TERMS_AND_CONDITIONS]: 'Terms of service',
    [TranslationKey.PRIVACY_POLICY]: 'Privacy policy',

    [TranslationKey.AGREE]: 'Read',

    // common
    [TranslationKey.COUNTRY_NAME]: 'Country',
    [TranslationKey.BACK]: 'Back',
    [TranslationKey.GO_NEXT]: 'Next',
    [TranslationKey.RETURN_TO_ENTRANCE]: 'Back to sign in',
    [TranslationKey.INVOICE]: 'Invoice',
    [TranslationKey.DOWNLOAD]: 'Download',
    [TranslationKey.UNLIMITED]: 'Unlimited',

    [TranslationKey.SEC]: 'sec',
    [TranslationKey.MEGABYTE]: 'mb',
    [TranslationKey.PIECE]: 'items',

    [TranslationKey.ELECTROCITY]: 'Electrical engineering',
    [TranslationKey.ELECTROCITY_DC]: 'DC electrical engineering',
    [TranslationKey.AUTO]: 'Automation',
    [TranslationKey.THERMOHYDRAULICS]: 'Thermohydraulics',
    [TranslationKey.SIMULATION]: 'Simulation',
    [TranslationKey.SYSTEM_DESIGN]: 'System design',
    [TranslationKey.EXTERNAL_MODELS]: 'External models',
    [TranslationKey.CODE_GENERATION]: 'Code generation',
    [TranslationKey.COMMON]: 'Common',
    [TranslationKey.DEVELOPMENT]: 'In development',
    [TranslationKey.MECHANICS]: 'Mechanics',
    [TranslationKey.ELECTRIC_DRIVE]: 'Electric drive',
    [TranslationKey.HEAT_EXCHANGE]: 'Heat exchange',
    [TranslationKey.ELECTRONICS]: 'Electronics',
    [TranslationKey.ISOTHERMAL_HYDRAULICS]: 'Isothermal hydraulics',
    [TranslationKey.PNEUMATICS]: 'Pneumatics',
    [TranslationKey.SUBLIBRARY_ROTATIONAL_MOTION]: 'Rotational motion',
    [TranslationKey.SUBLIBRARY_TRANSLATIONAL_MOTION]: 'Translational motion',
    [TranslationKey.SUBLIBRARY_BASE_ELEMENTS]: 'Base elements',
    [TranslationKey.SUBLIBRARY_CONVERTERS]: 'Converters',
    [TranslationKey.SUBLIBRARY_ELECTRIC_MACHINES]: 'Electric machines',

    [TranslationKey.BASE_PERMISSION]: 'Base',
    [TranslationKey.PNEUMO_PERMISSION]: 'Pneumatic systems',
    [TranslationKey.HEATHYDRO_PERMISSION]: 'Thermal Hydraulics and Heat Exchange',
    [TranslationKey.CODEGENERATING_PERMISSION]: 'Codogeneration',
    [TranslationKey.ELECTRODYNAMICS_PERMISSION]: 'Electrodynamics',

    [TranslationKey.SUBLIBRARY_SUPPLIERS]: 'Suppliers',
    [TranslationKey.SUBLIBRARY_FUNCTIONS]: 'Functions',
    [TranslationKey.SUBLIBRARY_OPERATIONS]: 'Operations',
    [TranslationKey.SUBLIBRARY_LOGIC]: 'Logic',
    [TranslationKey.SUBLIBRARY_SWITCH]: 'Switch',
    [TranslationKey.SUBLIBRARY_TRANSFERFUNCTIONS]: 'Transfer functions',
    [TranslationKey.SUBLIBRARY_CONTROLLERS]: 'Controllers',
    [TranslationKey.SUBLIBRARY_DELAYS]: 'Delays',
    [TranslationKey.SUBLIBRARY_RELAY]: 'Relay',
    [TranslationKey.SUBLIBRARY_NONLINEAR]: 'Nonlinear',
    [TranslationKey.SUBLIBRARY_SIGNAL_PROCESSING]: 'Signal processing',

    [TranslationKey.SUBLIBRARY_STRUCTURAL_AND_SUPPORT]: 'Structural and support elements',
    [TranslationKey.SUBLIBRARY_LOADS]: 'Loads',
    [TranslationKey.SUBLIBRARY_TRANSFORMERS]: 'Transformers',
    [TranslationKey.SUBLIBRARY_SOURCES]: 'Sources',
    [TranslationKey.SUBLIBRARY_POWER_CONVERTERS]: 'Power converters',
    [TranslationKey.SUBLIBRARY_LONGITUDINAL_ELEMENTS]: 'Longitudinal elements',

    [TranslationKey.SUBLIBRARY_ELECTRONIC_COMPONENTS_AND_POWER_CONVERTERS]:
        'Electronic components and power converters',
    [TranslationKey.SUBLIBRARY_PASSIVE_ELEMENTS]: 'Passive elements',
    [TranslationKey.SUBLIBRARY_BASIC_COMPONENTS]: 'Basic components',
    [TranslationKey.SUBLIBRARY_MEASURING_ELEMENTS]: 'Measuring elements',

    [TranslationKey.SUBLIBRARY_CMS]: 'Thermohydraulics systems',
    [TranslationKey.SUBLIBRARY_TRANSPORT]: 'Transport systems',
    [TranslationKey.SUBLIBRARY_RADIO_FREQUENCY_ANALYSIS]: 'Radio Frequency Analysis',

    [TranslationKey.ALL_LIBRARY_TYPES]: 'Multidisciplinary library',

    // solvers
    [TranslationKey.SOLVER_USDS]: 'USDS',
    [TranslationKey.SOLVER_JAUTO]: 'JAuto',
    [TranslationKey.SOLVER_MDCORE]: 'MDCore',

    // Tariffs
    [TranslationKey.USER_SET_TARIFF_SUCCESS]: 'Tariff successfully changed',
    [TranslationKey.USER_SET_TARIFF_ERROR]: 'Tariff change error',
    [TranslationKey.TARIFF_ACTIVE]: 'Active',
    [TranslationKey.TARIFF_FUNCTIONAL]: 'Functional',
    [TranslationKey.TARIFF_RESOURCES]: 'Resources',
    [TranslationKey.TARIFF_FREE]: 'Free',
    [TranslationKey.TARIFF_SCHEMAS_AMOUNT]: 'Maximum schemas amount',
    [TranslationKey.TARIFF_BLOCKS_AMOUNT]: 'Maximum blocks per schema amount',
    [TranslationKey.TARIFF_MAX_REAL_TIME]: 'Real time simulation',
    [TranslationKey.TARIFF_MAX_RAM]: 'Maximum amount of RAM',
    [TranslationKey.TARIFF_MAX_ROM]: 'Maximum amount of ROM',
    [TranslationKey.TARIFF_MAX_CPU]: 'Maximum amount of CPU',
    [TranslationKey.TARIFF_MAX_MODELING_TIME]: 'Simulation time',
    [TranslationKey.TARIFF_JUTHON_BLOCK]: 'Jython block',
    [TranslationKey.TARIFF_FMI_BLOCK]: 'Fmi block',
    [TranslationKey.TARIFF_MAX_SIMULATIONS_COUNT]: 'Number of simulations per day',
    [TranslationKey.TARIFF_MAX_DISK_SPACE]: 'Disk space',
    [TranslationKey.TARIFF_UNCONNECTED]: 'The tariff is not connected. You can contact technical support to enable it',
    [TranslationKey.AVAILABLE_MODULES]: 'Available modules',
    [TranslationKey.TARIFF_SUBSCRIPTION_TYPE]: 'Subscription type',
    [TranslationKey.TARIFF_START_DATE]: 'Subscription start date',
    [TranslationKey.TARIFF_END_DATE]: 'Subscription end date',
    [TranslationKey.TARIFF_STATUS]: 'Tariff status',
    [TranslationKey.TARIFF_LIMITED_TYPE]: 'Limited',
    [TranslationKey.TARIFF_UNLIMITED_TYPE]: 'Unlimited',
    [TranslationKey.TARIFF_STATUS_ACTIVE]: 'Available',
    [TranslationKey.TARIFF_STATUS_NOT_RENEWED]: 'Not renewed',
    [TranslationKey.TARIFF_STATUS_CANCELED]: 'Canceled',

    [TranslationKey.SECTION_IN_PROGRESS]: 'In progress',
    [TranslationKey.CONTACT_SUPPORT]: 'Contact to support',
    [TranslationKey.LEAVE_PAGE_MESSAGE]:
        'Are you sure you want to leave the page? All unsaved data will be lost and the calculation will stop. ',
    [TranslationKey.LOG_OUT_MESSAGE]:
        'If you exit the app, unsaved data will be lost and the calculation will stop. Are you sure you want to continue?',

    // library
    [TranslationKey.FILTER_BY_NAME]: 'Filter by name',
    [TranslationKey.ERROR_LIBRARY_ITEMS]: 'Error fetching library items',
    [TranslationKey.ELEMENT_IS_BLOCKED]: 'The element is temporarily unavailable.',
    [TranslationKey.LIBRARIES_ALL]: 'Libraries',
    [TranslationKey.LIBRARIES_AVAILABLE]: 'Available libraries',
    [TranslationKey.LIBRARY_NEW_BLOCK]: 'New block',

    // Navigation menu
    [TranslationKey.SETTINGS_MENU_ITEM]: 'Profile',
    [TranslationKey.API_KEYS_MENU_ITEM]: 'API keys',
    [TranslationKey.EXIT_MENU_ITEM]: 'Exit',
    //workspace
    [TranslationKey.WORKSPACE_PAGE_VISUALIZATION]: 'Visualization',
    [TranslationKey.WORKSPACE_PAGE_VARIABLES]: 'Variables',
    [TranslationKey.WORKSPACE_PAGE_ERROR_NAME_VARIABLES]: 'Enter variable name',
    [TranslationKey.WORKSPACE_PAGE_ERROR_VALUE_VARIABLES]: 'Value must be number',
    [TranslationKey.WORKSPACE_PAGE_ERROR_VARIABLES]: 'Variable ust be unique',
    [TranslationKey.WORKSPACE_PAGE_PRESENTATION]: 'Presentation',
    [TranslationKey.WORKSPACE_PAGE_JOURNAL]: 'Journal',
    // schema
    [TranslationKey.ERROR_LOADING_SCHEMA]: 'Error loading schema',
    [TranslationKey.ERROR_SAVING_SCHEMA]: 'Error saving schema',
    [TranslationKey.ERROR_GETTING_ELEMENT_PROPERTIES_SETS]: 'Error getting element configuration',
    [TranslationKey.ERROR_RUN_PROJECT]: 'Calculation start error',
    [TranslationKey.ERROR_GENERATE_SOURCES]: 'Error during source code generation',
    [TranslationKey.ERROR_GENERATE_NONAME_FILE]: 'Error during repeatCore file generation',
    [TranslationKey.ERROR_FETCH_TREND_DATA]: 'Visualization data receiving error',
    [TranslationKey.WARNING_VM_IS_IN_USE]: 'You already have a project running.',
    [TranslationKey.WARNING_ALL_VMS_ARE_BUSY]: 'There are no available virtual machines.',
    [TranslationKey.WARNING_INTEGRATION_STEP_PRIORITY]:
        'The integration step of the block takes precedence over the integration step specified in the design settings.',
    [TranslationKey.MESSAGE_STOP_BY_USER]: 'Modeling is completely stopped and the data is deleted',
    [TranslationKey.MESSAGE_STOP_FROM_SOLVER]: 'Modeling stopped on the solver side',
    [TranslationKey.MESSAGE_ERROR_FROM_SOLVER]:
        'Modeling stopped on the solver side due to an error. See more in the Journal.',
    [TranslationKey.MESSAGE_BUILD_ERROR_FROM_SOLVER]:
        'Modeling stopped on the solver side due to a build error. See more in the Journal.',
    [TranslationKey.MESSAGE_EXCEEDED_DB_SIZE_LIMIT_FROM_SOLVER]:
        'Modeling stopped on the solver side due to memory limit exceeded (500 Mb).',
    [TranslationKey.MESSAGE_EXCEEDED_TIME_LIMIT_FROM_SOLVER]:
        'Modeling stopped on the solver side due to modeling time limit exceeded.',
    [TranslationKey.MESSAGE_EXCEEDED_MEMORY_HEAP_SIZE_LIMIT_FROM_SOLVER]:
        'Modeling stopped on the solver side due to JVM memory limit exceeded.',
    [TranslationKey.ERROR_STOP_PROJECT]: 'Calculation stop error',
    [TranslationKey.ERROR_FREEZE_PROJECT]: 'Calculation freeze error',
    [TranslationKey.ERROR_CREATION_PROJECT]: 'Error project creation',
    [TranslationKey.WORKSPACE_PAGE_SAVE]: 'Save',
    [TranslationKey.WORKSPACE_PROJECT_TITLE]: 'Project',
    [TranslationKey.WORKSPACE_BLOCK_TITLE]: 'Block',
    [TranslationKey.WORKSPACE_ELEMENT_TITLE]: 'Block',
    [TranslationKey.WORKSPACE_CONNECTION_TITLE]: 'Connection',
    [TranslationKey.WORKSPACE_OBJECTS_TITLE]: 'Objects',
    [TranslationKey.ERROR_NOT_FOUND_CODE]: 'Not found error code',
    [TranslationKey.ERROR_CONNECT_SELECTOR]: 'No connection to get modeling data',
    [TranslationKey.ERROR_CONNECT_LOG]: 'No connection to get journal data',
    [TranslationKey.ERROR_CONNECT_TRENDS]: 'No connection to get trends data',
    [TranslationKey.WORKSPACE_PROPERTIES]: 'Properties',
    [TranslationKey.WORKSPACE_PROPERTY]: 'Property',
    [TranslationKey.WORKSPACE_ADD_PROPERTY]: 'Add property',
    [TranslationKey.WORKSPACE_PARAMETERS]: 'Parameters of block, axis Y',
    [TranslationKey.WORKSPACE_PARAMETERS_X]: 'Time or parameter of block, axis X',
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS]: 'Parameters',
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_INPUTS]: 'Inputs',
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_OUTPUTS]: 'Outputs',
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_WARNING_DELETE_CONNECTED_PORT]:
        'Port is connected. If you delete this port, connection will be deleted.',
    [TranslationKey.WORKSPACE_PAGE_TOOLS]: 'Tools',
    [TranslationKey.WORKSPACE_WIRE]: 'Wire',
    [TranslationKey.WORKSPACE_PROPERTIES_NOT_FOUND]: 'There are no available properties',
    [TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND]: 'There are no available parameters',
    [TranslationKey.WORKSPACE_PARAMETERS_NOT_DEFINED]: 'Parameters are not defined',
    [TranslationKey.WORKSPACE_PROPERTIES_NOT_DEFINED]: 'Properties are not defined',
    [TranslationKey.WORKSPACE_ERROR_CHART]: 'Data for graph is not found',
    [TranslationKey.WORKSPACE_ERROR_CHART_CSV]: 'Error during CSV file generation',
    [TranslationKey.WORKSPACE_WARNING_CHART_START]: 'Graph configured. Need to run project',
    [TranslationKey.WORKSPACE_CHART_ADD]: 'Add graph y(t)',
    [TranslationKey.WORKSPACE_CHART_TYPE_Y_T]: 'y(t)',
    [TranslationKey.WORKSPACE_CHART_TYPE_Y_X]: 'y(x)',
    [TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER]: 'Select parameter of block',
    [TranslationKey.WORKSPACE_CHART_POINT_VALUE]: 'Value',
    [TranslationKey.WORKSPACE_CHART_DOWNLOAD_CSV]: 'Download CSV',
    [TranslationKey.WORKSPACE_CHART_CSV_TIME_TITLE]: 'Time, s',
    [TranslationKey.WORKSPACE_UNDO]: 'Undo',
    [TranslationKey.WORKSPACE_REDO]: 'Redo',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_USDS]: 'Generate sources for USDS',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_COMMON]: 'Generate C sources',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_NOT_AVAILABLE]:
        'It is not possible to generate sources during the modeling process',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_CANCEL]: 'Cancel source generation',
    [TranslationKey.WORKSPACE_GENERATE_SOURCES_NO_LIVE_PERMISSION]:
        'All workplaces of the «Code Generation» module are occupied. Code generation is unavailable.',
    [TranslationKey.WORKSPACE_GET_NONAME_FILE]: 'Generate repeatCore file',
    [TranslationKey.WORKSPACE_GET_NONAME_FILE_CANCEL]: 'Cancel repeatCore file generation',
    [TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST]: 'Error getting projects list',
    [TranslationKey.WORKSPACE_ERROR_GETTING_PROJECT_DATA]: 'Error getting project data',
    [TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_FOR_PROJECT_BLOCKS]:
        'An error occurred while getting projects for one or more Project blocks.',

    [TranslationKey.WORKSPACE_PROJECT_BLOCKS_MODIFIED_CONNECTED_PROJECT]:
        'One or more Project blocks have changes in the associated project',

    [TranslationKey.WORKSPACE_NOTEBOOK_TOOL]: 'Notebook',
    [TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL]: 'Notebook with core',
    [TranslationKey.WORKSPACE_NOTEBOOK_WITH_CORE_TOOL_LINK_ERROR]:
        'No link was retrieved for notebook with core. Please try again later. If the error occurs again, please contact technical support.',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL]: 'Calculation of SC',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_START]: 'Start calculate',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT]: 'The SC type',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE]: 'Case',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MAX]: 'Maximal currents',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MIN]: 'Minimal currents',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT]: 'Tolerance, %',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT_FULL]: 'Voltage tolerance for LV systems',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_DURATION]: 'Duration of SC, s',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_3]: 'Three-phase SC',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_2]: 'Two-phase SC',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_1]: 'Single-phase SC',
    [TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_NO_LIVE_PERMISSION]:
        'All workplaces of the «Electrodynamics» module are occupied. Calculation is unavailable.',
    [TranslationKey.WORKSPACE_HARMONIC_TOOL]: 'Harmonic analyse',
    [TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED]: 'The action is not allowed in view mode',
    [TranslationKey.WORKSPACE_VIEW_MODE]: 'View mode',
    [TranslationKey.WORKSPACE_WARNING_PROJECT_CHANGED]: 'Project has been changed',
    [TranslationKey.WORKSPACE_ELEMENT_ACTIONS]: 'Actions',
    [TranslationKey.WORKSPACE_ELEMENT_POSITIONING]: 'Positioning',
    [TranslationKey.WORKSPACE_ELEMENT_POSITION]: 'position',
    [TranslationKey.WORKSPACE_ELEMENT_SIZE]: 'Size',
    [TranslationKey.RULES_ELEMENT_MAX_COUNT]: 'There can only be one element on the scheme.',
    [TranslationKey.ELEMENT_LIVE_PERMISSIONS_ADD_ERROR]:
        'Block is not added to the model. The module workspace of the block to be added is occupied. Wait until the module workspace is free and try again.',
    [TranslationKey.CHART_ELEMENT_MAX_COUNT]: 'Maximum graphs count.',
    [TranslationKey.ELEMENT_CONFIGURATION]: 'Block configuration',
    [TranslationKey.ELEMENT_CONFIGURATION_NOTICE]:
        'For the block to be added, you can choose one of the presented configurations or choose a custom configuration.',
    [TranslationKey.ELEMENT_CONFIGURATION_IS_NOT_RELEVANT]:
        'The configuration list has been updated. The previously selected configuration "{name}" is no longer relevant.',
    [TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE]: 'Customizable configuration',
    [TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE_SELECT]: 'Select customizable configuration',
    [TranslationKey.ELEMENT_CONFIGURATION_CURRENT]: 'Current configuration',
    [TranslationKey.ELEMENT_INITIALIZATION]: '{name} element initialization',
    [TranslationKey.ELEMENT_FILE_UPDATE]: '{name} element file update',
    [TranslationKey.ELEMENT_FILE_S_PARAMETERS_NAME]: 'S-parameters',
    [TranslationKey.ELEMENT_FILE_TOUCHSTONE_FORMAT]: 'Touchstone ver. 1 format',
    [TranslationKey.PROJECT_BLOCK_INITIALIZATION]: 'Project block initialization',
    [TranslationKey.ELEMENT_INITIALIZATION_NOTICE]:
        'To add a {name} block, load the {extension} file and, after initialization, add it to the diagram.',
    [TranslationKey.ELEMENT_INITIALIZATION_ATTENTION_MESSAGE]: 'FMU blocks version 5.1 for Linux are supported',
    [TranslationKey.ELEMENT_INITIALIZATION_DROPZONE]: "Drag 'n' drop file here, or click to select file",
    [TranslationKey.ELEMENT_RFSPARAMETERS_INITIALIZATION_DROPZONE]:
        "Drag 'n' drop a Touchstone ver. 1 file with the .s1p, .s2p, .s3p, or .s4p extension containing the S-parameters of the RF device being simulated into the upload area.",
    [TranslationKey.ELEMENT_INITIALIZATION_SELECT]: 'Select {extension} file',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_LABEL]: 'File upload:',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_INITIALIZE_LABEL]: 'Initialization:',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_SUCCESS]: 'successfully completed',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_FAILED]: 'completed with error',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_LOADING]: 'in progress',
    [TranslationKey.ELEMENT_INITIALIZATION_STATUS_UPLOAD_WAITING]: 'pending file upload',
    [TranslationKey.ELEMENT_INITIALIZATION_FMU_NOT_VALID]:
        'The uploaded {extension} file does not meet the requirements',
    [TranslationKey.ELEMENT_INITIALIZATION_RUN_AGAIN]: 'Initialize again?',
    [TranslationKey.ELEMENT_TEXT_BLOCK_TYPING]: 'Type your text here by double click',
    [TranslationKey.ERROR_OPEN_SCHEMA]:
        'There was an error opening the schema. Try again. If the schema does not open, please contact the technical support.',
    [TranslationKey.CONTEXT_SEARCH_PLACEHOLDER]: 'Type the block name',
    [TranslationKey.ELEMENT_NOT_SUPPORTED]: 'Replace the block with an updated block from the block library',
    [TranslationKey.ELEMENT_ATTENTION_LABEL]: 'Alert',
    [TranslationKey.ELEMENT_HAS_NO_GOTO_PAIR]: 'The block does not have a paired block (Go To or Go From)',
    [TranslationKey.ELEMENT_HAS_NOT_UNIQUE_GOTO]: 'The name of the GoTo block must be unique. Rename it.',
    [TranslationKey.SCHEMA_HAS_NOT_SUPPORTED_ELEMENT]:
        'A block with backwards incompatible changes after updating the block library has been detected. Replace with an updated block from the library. The block to replace is marked with a red box in the diagram. The operation will be available after replacing the block with the updated block.',
    [TranslationKey.SCHEMA_HAS_NOT_SUPPORTED_ELEMENTS]:
        'Detected blocks with backwards incompatible changes after updating the block library. Replace with updated blocks from the library. Blocks to replace are marked with a red box in the diagram. The operation will be available after replacing the blocks with updated blocks.',
    [TranslationKey.SCHEMA_HAS_INVALID_CONNECTION]: 'An invalid connection has been detected..',
    [TranslationKey.SCHEMA_HAS_INVALID_CONNECTIONS]: 'An invalid connections has been detected.',
    [TranslationKey.SCHEMA_INVALID_CONNECTION]: 'Invalid connection',

    [TranslationKey.SCHEMA_ELEMENT_EDIT_CODE]: 'Edit block code',
    [TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA]: 'Back to the schema',
    [TranslationKey.SCHEMA_ELEMENT_DELETED]: 'The block is deleted',
    [TranslationKey.SCHEMA_ELEMENT_IS_DEPRECATED]: 'The block is deprecated, support will cease after: ',

    [TranslationKey.WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA]: 'Edit',
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING]: 'Go to editing',
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW]: 'View',
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE]:
        'To go to editing the project {subProject} you need to close the current project.',
    [TranslationKey.WORKSPACE_USER_BLOCK_GO_TO_SCHEMA_EDITING_MESSAGE]:
        'To go to editing the block you need to close the current project.',
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_CONFIRM_SAVING_PROJECT]:
        'Would you like to save the changes in project {mainProject}?',
    [TranslationKey.USER_BLOCK_MODAL_WARN]: 'Global variables are in your elements values, please remove it',

    [TranslationKey.WORKSPACE_MEMORY_USAGE]: 'Calculating memory usage',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT]: 'memory maximum - {size} Mb',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_ERROR]: 'The maximum calculated memory usage limit has been exceeded',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC]: 'Synchronization with real time',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_REALTIME_SYNC_TOOLTIP]:
        'When synchronization is enabled, one step of model time will roughly correspond to one step of real time. If the volume of calculations is large, the model time may lag behind the real time. When synchronization is turned off, the calculation will be performed at the maximum possible speed and is usually ahead of real time.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME]: 'Simulation time, s',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING_TIME_TOOLTIP]:
        'Model time after which the calculation will be stopped.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP]: 'Integration step, ms',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_TOOLTIP]:
        'The interval of model time between the nearest calculated model states. If the gap is large, the calculation may become unstable and the results will be incorrect.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP]: 'Sampling step, ms',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_TOOLTIP]:
        'The interval of model time between the nearest entries of calculation results into the database. It is used to regulate the volume of stored data.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE]: 'Compression',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_USE_TOOLTIP]:
        'Compression can be used to reduce the amount of stored calculation data. The Ramer-Douglas-Peucker (RDP) algorithm allows to compress the data in such a way that the maximum deviation does not exceed the specified accuracy.',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_ACCURACY]: 'Compression accuracy',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_COMPRESSION_TYPE]: 'Compression type',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_CALCULATION]: 'Memory, Mb',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MODELLING]: 'Simulation',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_GRAPHS]: 'Graphs',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_CALCULATION]: 'Memory calculation',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MEMORY_POINT]: 'Mb',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_INTEGRATION_STEP_ERROR]: 'The value cannot be larger than the sampling step',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_ERROR]:
        'The value cannot be smaller than the integration step',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_DISCRETISATION_STEP_MULTIPLICITY_ERROR]:
        'The value must be a multiple of the integration step',
    [TranslationKey.WORKSPACE_MEMORY_LIMIT_MORE_THAN_ZERO]: 'The value must be greater than zero',
    [TranslationKey.WORKSPACE_PROJECT_INT_VALUE_ERROR]: 'Value should be integer',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TITLE]: 'External models',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_TASKNAME]: 'Executable file name',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST]: 'Server address',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_HOST_NOT_VALID]: 'The value must be localhost or IPv4',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT]: 'Server port',
    [TranslationKey.WORKSPACE_PROJECT_EXTERNAL_MODELS_PORT_VALUE]:
        'Value should be integer and between {min} and {max}',
    [TranslationKey.WORKSPACE_INVOLVED_MODULES]: 'The modules involved in the schema',

    // model statuses
    [TranslationKey.MODEL_STATUS_STOP_HELP]: 'The simulation is completely stopped. Calculation data is not available.',
    [TranslationKey.MODEL_STATUS_INIT_HELP]: 'Simulation initialization.',
    [TranslationKey.MODEL_STATUS_FREEZE_HELP]:
        'The simulation is paused. You can continue the calculation from the moment of pause.',
    [TranslationKey.MODEL_STATUS_RUN_HELP]:
        'The simulation is paused. You can continue the calculation from the moment of pause.',
    [TranslationKey.MODEL_STATUS_DATA_HELP]:
        'The simulation is stopped. The calculation data is available for plotting.',
    [TranslationKey.MODEL_STATUS_CODE_HELP]: 'Source generation is running.',

    [TranslationKey.MODEL_RUN_HELP]: 'Run simulation',
    [TranslationKey.MODEL_SOFT_STOP_HELP]: 'Stop simulation. After stopping Generation of sources in process.',
    [TranslationKey.MODEL_STOP_HELP]: 'Finish working with the calculation data. The calculation data will be deleted.',
    [TranslationKey.MODEL_FREEZE_HELP]: 'Pause the simulation.',
    [TranslationKey.MODEL_CONTINUE_HELP]: 'Continue the simulation.',
    [TranslationKey.MODEL_TIME_UNIT]: 's',

    // journal
    [TranslationKey.JOURNAL_EMPTY]: 'No messages',
    [TranslationKey.JOURNAL_STACKTRACE_TITLE]: 'Stacktrace',

    // code editor
    [TranslationKey.CODE_EDITOR_NODE_NOT_FOUND]: 'Block not found',
    [TranslationKey.CODE_EDITOR_PROPERTY_NOT_FOUND]: 'Source code block property not found',
    [TranslationKey.JYTHON_CODE_TAB_INIT]: 'Initialization',
    [TranslationKey.JYTHON_CODE_TAB_BODY]: 'Modeling code',
    [TranslationKey.JYTHON_CODE_TAB_UNINIT]: 'Uninitialization',

    // app license
    [TranslationKey.LICENSE_KEY]: 'License key',
    [TranslationKey.LICENSE_ACTIVATION_KEY]: 'License key activation',
    [TranslationKey.LICENSE_ACTIVATION_CODE_SUBTITLE]: 'Get the activation string',
    [TranslationKey.LICENSE_ACTIVATION_CODE_INFO]:
        'To activate, get an activation string using the "Get activation string" button. Send the received activation string to your software vendor to get a license file to activate the REPEAT software.',
    [TranslationKey.LICENSE_ACTIVATION_CODE]: 'Activation string',
    [TranslationKey.LICENSE_ACTIVATION_GET_CODE_BUTTON]: 'Get activation string',
    [TranslationKey.LICENSE_ACTIVATION_USER_HAS_FILE]: 'If you have the license file, proceed to the next step.',
    [TranslationKey.LICENSE_ACTIVATION_FILE_SUBTITLE]: 'Upload the license file',
    [TranslationKey.LICENSE_ACTIVATION_NOTICE]:
        'To activate the entered license key, upload the license file with the .lrpt extension. The activation will start automatically.',
    [TranslationKey.LICENSE_ACTIVATION_UPLOAD_HELP]: "Drag 'n' drop file .lrpt here, or click to select file.",
    [TranslationKey.LICENSE_ACTIVATION_UPLOAD_BUTTON]: 'Select .lrpt file',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_TITLE]: 'Activation status',
    [TranslationKey.LICENSE_KEY_ISSUE_DATE_TITLE]: 'Issue date',
    [TranslationKey.LICENSE_KEY_INPUT_DATE_TITLE]: 'Input date',
    [TranslationKey.LICENSE_KEY_EXPIRE_DATE_TITLE]: 'Expire at',
    [TranslationKey.LICENSE_KEY_TRIAL_DATE_TITLE]: 'Activate until',
    [TranslationKey.LICENSE_KEY_NO_EXPIRE]: 'Indefinitely',
    [TranslationKey.LICENSE_STATUS_ACTIVATION_COMPLETED]: 'Activation completed',
    [TranslationKey.LICENSE_STATUS_ACTIVATION_REQUIRED]: 'Activation required',
    [TranslationKey.LICENSE_KEY_NOT_VALID]: 'Not valid license key',
    [TranslationKey.LICENSE_KEY_NOT_FOUND]: 'License key not found',
    [TranslationKey.LICENSE_ACTIVE_KEY]: 'Active license key',
    [TranslationKey.LICENSE_INPUT_NEW_KEY]: 'Enter new license key',
    [TranslationKey.LICENSE_NEW_KEY]: 'New license key',
    [TranslationKey.LICENSE_ACTIVATE_NEW_KEY]: 'Activate license key',
    [TranslationKey.LICENSE_KEY_ANNOTATION]:
        'To work with the REPEAT software, you must complete the license registration procedure.',
    [TranslationKey.LICENSE_KEY_PATTERN_HELP]: 'enter the 25-digit license key',
    [TranslationKey.LICENSE_HAVE_NO_DATA]:
        'Failed to retrieve license status data. Try refreshing the page. If the error is repeated, contact technical support.',
    [TranslationKey.LICENSE_BACK_TO_LIST]: 'Go to the list of keys',
    [TranslationKey.LICENSE_ACTIVATION_CODE_COPY]: 'Copy',
    [TranslationKey.LICENSE_ACTIVATION_CODE_COPIED]: 'Copied',
    [TranslationKey.LICENSE_EXPIRATION_SOON]: 'License expires',
    [TranslationKey.LICENSE_EXPIRATION_LEFT_DAYS]: 'Days left: {count}',
    [TranslationKey.LICENSE_EXPIRATION_TODAY]: 'Time to expiration: {hours} h. {minutes} min.',
    [TranslationKey.LICENSE_ACTIVE_TRIAL]: 'The key is valid for a trial period. Please activate the key.',
    [TranslationKey.LICENSE_ACTIVATION_SUCCESS]: 'The license has been successfully activated',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_UPLOAD_LABEL]: 'File upload:',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_ACTIVATION_LABEL]: 'Activation:',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_SUCCESS]: 'Successfully completed',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_FAILED]: 'Completed with error',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_LOADING]: 'In progress',
    [TranslationKey.LICENSE_ACTIVATION_STATUS_UPLOAD_WAITING]: 'Pending file upload',
    [TranslationKey.LICENSE_ACTIVATION_RUN_AGAIN]: 'Activate again?',
    [TranslationKey.LICENSE_SERVICE_UNAVAILABLE]: 'Licensing manager is not available',
    [TranslationKey.LICENSE_GATEWAY_TIMEOUT]: 'Timed out waiting for response from licensing manager',

    // about platform
    [TranslationKey.ABOUT_VERSION]: 'Version',
    [TranslationKey.ABOUT_VERSION_BUILD]: 'Build',

    // settings
    [TranslationKey.SETTINGS_INTERFACE_TITLE]: 'Interface',

    // tour
    [TranslationKey.TOUR_ITEM]: 'Page tour',
    [TranslationKey.TOUR_NEXT]: 'Next',
    [TranslationKey.TOUR_PREV]: 'Back',
    [TranslationKey.TOUR_SKIP]: 'Skip',
    [TranslationKey.TOUR_FINISH]: 'Fihish',

    [TranslationKey.TOUR_PROJECTS_NEW]: 'New project',
    [TranslationKey.TOUR_PROJECTS_NEW_DESCRIPTION]: 'This link will open a form for creating a new project.',
    [TranslationKey.TOUR_PROJECTS_LIST]: 'List of projects',
    [TranslationKey.TOUR_PROJECTS_LIST_DESCRIPTION]:
        'A list of already created projects is displayed in the indicated area.',

    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL]: 'Controller',
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_DESCRIPTION]: 'Model calculation controller',
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START]: 'Start button',
    [TranslationKey.TOUR_WORKSPACE_MODEL_CONTROL_START_DESCRIPTION]: 'Starts model calculation.',
    [TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE]: 'Project',
    [TranslationKey.WORKSPACE_SELECT_PROJECT]: 'Select a project',
    [TranslationKey.WORKSPACE_NO_PROJECTS_TO_SELECT]: 'There are no projects to select',
    [TranslationKey.NEW_WINDOW_OPEN]: 'Open in new window',
    [TranslationKey.WORKSPACE_CHART_EDITOR_OPEN]: 'Open graphs editor',
    [TranslationKey.WORKSPACE_CHART_EDITOR]: 'Graphs editor',
    [TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS]: 'Annotations',
    [TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_ADD]: 'New annotation',
    [TranslationKey.WORKSPACE_CHART_EDITOR_ANNOTATIONS_EMPTY]: 'Annotations list is empty',
    [TranslationKey.WORKSPACE_CHART_EDITOR_NOT_EDITABLE]: 'To edit the graph, stop or pause the simulation.',
    [TranslationKey.WORKSPACE_CHART_EDITOR_NO_ANNOTATIONS]: 'There are no annotations',

    [TranslationKey.TOUR_WORKSPACE_PROJECT_TITLE_DESCRIPTION]: 'Current project name.',
    [TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE]: 'Save',
    [TranslationKey.TOUR_WORKSPACE_PROJECT_SAVE_DESCRIPTION]: 'Current project saving button.',
    [TranslationKey.TOUR_WORKSPACE_LIBRARY]: 'Blocks library',
    [TranslationKey.TOUR_WORKSPACE_LIBRARY_DESCRIPTION]: 'Use library blocks for model creation.',
    [TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM]: 'Block',
    [TranslationKey.TOUR_WORKSPACE_LIBRARY_ITEM_DESCRIPTION]:
        'Click on the block you need and, holding the left mouse button, move the block to the canvas.',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER]: 'Visualization',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TRIGGER_DESCRIPTION]:
        'Use this button to open the Visualization panel.',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB]: 'Visualization tab',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_TAB_DESCRIPTION]: 'Use it to open the Visualization panel.',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA]: 'Visualization area',
    [TranslationKey.TOUR_WORKSPACE_VISUALIZATION_AREA_DESCRIPTION]:
        'This area displays a graph of the selected parameter of the diagram block when the model is running for calculation.',
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB]: 'Journal tab',
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_TAB_DESCRIPTION]: 'Use it to open the Journal panel.',
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA]: 'Journal area',
    [TranslationKey.TOUR_WORKSPACE_JOURNAL_AREA_DESCRIPTION]: 'This area displays model calculation events.',
    [TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL]: 'Right panel',
    [TranslationKey.TOUR_WORKSPACE_RIGHT_PANEL_DESCRIPTION]:
        'In this area properties and controls of selected block are displayed.',

    // modal
    [TranslationKey.MODAL_HELP_SECTION_MORE_DETAILED_INFORMATION]:
        'More detailed information can be found in the «Help» section at the',
    [TranslationKey.MODAL_TECH_SUPPORT_CONTACT]:
        'For any questions or suggestions about the Application operation, you can',
    [TranslationKey.US]: 'write to us',
    [TranslationKey.MODAL_RELEASE_INFO_DONT_SHOW_UPDATES]: "Don't show updates anymore",
    [TranslationKey.MODAL_ACCOUNT_DELTE]: 'Confirm deletion',
    [TranslationKey.MODAL_ACCOUNT_EDIT]: 'Edit account',
    [TranslationKey.MODAL_ACCOUNT_ADD]: 'Adding account',
    [TranslationKey.MODAL_ACCOUNT_DELETE_TEXT]: 'Delete account ',

    // COMMON
    [TranslationKey.SAVE]: 'Save',
    [TranslationKey.GENERATE]: 'Generate',
    [TranslationKey.DO_NOT_SAVE]: "Don't save",
    [TranslationKey.SAVE_AS]: 'Save as...',
    [TranslationKey.ADD]: 'Add',
    [TranslationKey.HIDE]: 'Hide',
    [TranslationKey.SAVING]: 'Saving',
    [TranslationKey.SELECT]: 'Select',
    [TranslationKey.DELETE]: 'Delete',
    [TranslationKey.EDIT]: 'Edit',
    [TranslationKey.CANCEL]: 'Cancel',
    [TranslationKey.ROTATE]: 'Rotate',
    [TranslationKey.CHANGE]: 'Change',
    [TranslationKey.EDITING]: 'Editing',
    [TranslationKey.SEND]: 'Send',
    [TranslationKey.TO_APP]: 'Go to the application',
    [TranslationKey.ERROR_DATA]: 'Error when receiving data from the server',
    [TranslationKey.LANGUAGE_RUSSIAN]: 'Russian',
    [TranslationKey.LANGUAGE_ENGLISH]: 'English',
    [TranslationKey.ACCOUNT_MODE_DEFAULT]: 'Default mode',
    [TranslationKey.ACCOUNT_MODE_ENGINEER]: 'Engineer mode',
    [TranslationKey.LANGUAGE_RUSSIAN_SHORT]: 'RU',
    [TranslationKey.LANGUAGE_ENGLISH_SHORT]: 'EN',
    [TranslationKey.THEME]: 'Theme',
    [TranslationKey.THEME_DARK]: 'Dark theme',
    [TranslationKey.THEME_LIGHT]: 'Light theme',
    [TranslationKey.ICON_FORMAT]: 'Icon type',
    [TranslationKey.ICON_DEFAULT]: 'Standart',
    [TranslationKey.ICON_NEW]: 'With background',
    [TranslationKey.ERROR_PAGE_NOT_FOUND]: 'Page is not found',
    [TranslationKey.LANGUAGE_TITLE]: 'Language',
    [TranslationKey.MODE_TITLE]: 'Mode',
    [TranslationKey.WHATS_NEW]: `What's new?`,
    [TranslationKey.UNDERSTAND_THANKS]: 'Got it, thanks',
    [TranslationKey.LINK]: 'link',
    [TranslationKey.TECHNICAL_SUPPORT]: 'Technical support',
    [TranslationKey.CONTACTING_TECHNICAL_SUPPORT]: 'Fill in the information on the request',
    [TranslationKey.CALLING_TECHNICAL_SUPPORT]: 'Contacting technical support',
    [TranslationKey.TECHNICAL_SUPPORT_CAPTION]: 'Fill out the form above or send an email: support@repeatlab.ru',
    [TranslationKey.SALES_DEPARTMENT]: 'Sales Department',

    [TranslationKey.MESSAGE]: 'Message',
    [TranslationKey.ENTER_TEXT]: 'Enter text',
    [TranslationKey.ENTER_MESSAGE]: 'Enter message',
    [TranslationKey.MESSAGE_SENT_SUCCESSFULLY]: 'Message sent successfully!',
    [TranslationKey.OK_THANKS]: 'Ok',
    [TranslationKey.ERROR]: 'Error',
    [TranslationKey.CRITICAL_ERROR]:
        'A critical application error has occurred. If the error occurs again, please contact technical support.',
    [TranslationKey.WRITE_TO_TECHNICAL_SUPPORT]: 'Tech.support',
    [TranslationKey.NEED_HELP]: 'Contact us',
    [TranslationKey.SUPPORT_SUBJECT]: 'The subject of the appeal',
    [TranslationKey.FEATURES_SUPPORT_VALUE]: 'Consultation on the product features',
    [TranslationKey.TARIFF_SUPPORT_VALUE]: 'Consultation on tariffs and product purchase',
    [TranslationKey.AUTH_SUPPORT_VALUE]: 'Need help with authorization/registration',
    [TranslationKey.OTHER_SUPPORT_VALUE]: 'Other',

    [TranslationKey.COPY_TO_CLIPBOARD]: 'Copy to clipboard',
    [TranslationKey.COPIED_TO_CLIPBOARD]: 'Copied',
    [TranslationKey.NOT_COPIED_TO_CLIPBOARD]: "Not copied due to your browser's security settings. Copy it manually.",
    [TranslationKey.NOT_COPIED_TO_CLIPBOARD_PROJECT_ID]:
        "Not copied due to your browser's security settings. Copy it manually in the workspace, in the right bar under the project name",

    [TranslationKey.ERROR_SEND_REQUEST]: 'Error send request',
    [TranslationKey.ERROR_SC_RESPONSE]: 'SC service response error',
    [TranslationKey.WORKSPACE_PROPERTIES_CUSTOM_NAME]: 'Custom block name',
    [TranslationKey.ACCOUNT_ADDED]: 'Account added',
    [TranslationKey.ACCOUNT_UPDATED]: 'Account updated',
    [TranslationKey.ACCOUNT_DELETE]: 'Account delete',
    [TranslationKey.GO_BACK_TO_AUTH_PAGE]: 'Go back to the authorization page',

    // CONTROLS
    [TranslationKey.ZOOM_IN]: 'Zoom in',
    [TranslationKey.ZOOM_OUT]: 'Zoom out',
    [TranslationKey.FIT_VIEW]: 'Fit schema',
    [TranslationKey.LOCK_CANVAS]: 'Block schema',
    [TranslationKey.UNLOCK_CANVAS]: 'Unblock schema',
    [TranslationKey.MINI_MAP]: 'Schemas minimap',
    [TranslationKey.CLOSE]: 'Close',
    [TranslationKey.CLOSE_PROJECT]: 'Close project',
    [TranslationKey.PROJECT_OPEN_ERROR]: 'Project open error',
    [TranslationKey.PROJECT_BUSY_MODULES]: 'Project busy modules',
    [TranslationKey.BACK_TO_PROJECTS]: 'Back to projects list',
    [TranslationKey.SCHEMA_SCREENSHOT]: 'Download schema screenshot',

    // UNITS
    [TranslationKey.UNIT_SECOND]: 's',

    // File manager
    [TranslationKey.FILE_MANAGER_FILES_COUNT]: 'Elements',
    [TranslationKey.FILE_MANAGER_SELECTED_COUNT]: 'Selected',
    [TranslationKey.FILE_MANAGER_OPEN_FILE]: 'Open',
    [TranslationKey.FILE_MANAGER_REMOVE_DIALOG]: 'Are you sure you want to remove',
    [TranslationKey.FILE_MANAGER_NEW_FOLDER]: 'New folder',
    [TranslationKey.FILE_MANAGER_SORT_ASC_TOOLTIP]: 'Sort by ASC',
    [TranslationKey.FILE_MANAGER_SORT_DESC_TOOLTIP]: 'Sort by DESC',
    [TranslationKey.FILE_MANAGER_SORT_DEFAULT_TOOLTIP]: 'Sort by type',
    [TranslationKey.FILE_MANAGER_SORT_DATE]: 'Sort by date',
    [TranslationKey.FILE_MANAGER_SELECT_ALL_TOOLTIP]: 'Select all',
    [TranslationKey.FILE_MANAGER_CREATE_FOLDER_TOOLTIP]: 'New Folder',
    [TranslationKey.FILE_MANAGER_PREVIEW_TOOLTIP]: 'Preview show',
    [TranslationKey.FILE_MANAGER_MANAGER_VIEW_TOOLTIP]: 'Change view mode',
    [TranslationKey.FILE_MANAGER_MANAGER_VIEW_UPDATE]: 'Update',
    [TranslationKey.USER_BLOCK_EMPTY_MESSAGE]: 'No more user blocks founds',

    [TranslationKey.SESSION_CONNECTION_LOST_TITLE]: 'Connection lost',
    [TranslationKey.SESSION_CONNECTION_LOST_DESCRIPTION]:
        'Lost connection to the server, check your internet connection and try connecting again.',
    [TranslationKey.SESSION_CONNECTION_LOST_BUTTON]: 'Reconnect',
    [TranslationKey.WORKSPACE_LIVE_PERMISSION_MODULES]: 'Connection state and available modules',

    [TranslationKey.FSM_IN_VARIABLE]: 'Inner',
    [TranslationKey.FSM_OUT_VARIABLE]: 'Outer',
    [TranslationKey.FSM_INTERNAL_VARIABLE]: 'Internal',
    [TranslationKey.FSM_EXTERNAL_VARIABLE]: 'External',
    [TranslationKey.FSM_WARNING_START_POINT]: 'Only one start point at the scheme',
    [TranslationKey.FSM_STATE_CONDITION]: 'Condition',
    [TranslationKey.FSM_STATE_ACTION]: 'Action',
    [TranslationKey.FSM_STATE_TITLE]: 'Title',

    [TranslationKey.PROJECT_VERSIONS_HISTORY]: 'Version history',
    [TranslationKey.ERROR_PROJECT_HISTORY]: 'Error requesting version history',
    [TranslationKey.EMPTY_PROJECT_HISTORY]: 'Project history is empty',
    [TranslationKey.EMPTY_COMMENT_PROJECT_HISTORY]: 'Empty comment',
    [TranslationKey.PROJECT_HISTORY_LOAD_VERSION]: 'Load version',
    [TranslationKey.PROJECT_HISTORY_PREVIEW_VERSION]: 'Preview version',

    [CODE_LIST[7006]]: 'Successful launch of the project for calculation.',
    [CODE_LIST[7007]]: 'Successful launch of the Selector.',
    [CODE_LIST[7008]]: 'Successful launch of Trends.',
    [CODE_LIST[7009]]: 'Successful closing of the Selector channel.',
    [CODE_LIST[7010]]: 'Successful closing of the Trend channel.',
    [CODE_LIST[7011]]: 'Successful setting of trend parameters.',
    [CODE_LIST[7012]]: 'Successfully setting a new value to the parameter.',
    [CODE_LIST[7014]]: 'Successfully setting a new variable value.',
    [CODE_LIST[7015]]: 'Successful provisioning of the virtual machine.',
    [CODE_LIST[7050]]: 'Successful shutdown of the project.',
    [CODE_LIST[7051]]: 'Successfully saving the project.',
    [CODE_LIST[7052]]: 'Successfully retrieved the last save.',
    [CODE_LIST[7053]]: 'Successful deletion of the project.',
    [CODE_LIST[7054]]: 'Successful stop of the task.',
    [CODE_LIST[7055]]: 'Successful launch of the task.',
    [CODE_LIST[1001]]: 'Error start task in MdCore.',
    [CODE_LIST[1057]]: 'Successful closing connections',
    [CODE_LIST[6001]]: 'There is no free virtual machine',
    [CODE_LIST[6501]]: 'The maximum number of running projects has been reached.',
    [CODE_LIST[1085]]: 'Incorrect project schema',
    [CODE_LIST[1101]]: 'The projectId parameter is empty.',
    [CODE_LIST[1102]]: 'The userId parameter is empty.',
    [CODE_LIST[1103]]: 'The userId header is empty.',
    [CODE_LIST[1104]]: 'The serviceKey parameter is empty.',
    [CODE_LIST[1105]]: 'Project items are empty.',
    [CODE_LIST[1106]]: 'The schema has an unsupported element type.',
    [CODE_LIST[1107]]: 'At the input port, the output information',
    [CODE_LIST[1108]]: 'The link refers to a non-existent element.',
    [CODE_LIST[1109]]: 'The link accesses a non-existent element port.',
    [CODE_LIST[1110]]: 'An input port has an output element.',
    [CODE_LIST[1111]]: 'An unsupported library type was specified.',
    [CODE_LIST[1112]]: 'Unknown solver type.',
    [CODE_LIST[1113]]: 'Error converting string to lower case.',
    [CODE_LIST[1114]]: 'Solver type is empty',
    [CODE_LIST[1115]]: 'Library type is empty',
    [CODE_LIST[1206]]: 'Phone is already in use',
    [CODE_LIST[1207]]: 'Email is already in use',
    [CODE_LIST[1214]]: 'Phone is not correct',
    [CODE_LIST[1227]]: 'The password is not valid',
    [CODE_LIST[1238]]: 'Email is not correct',
    [CODE_LIST[1356]]: 'Critical calculation flow error',
    [CODE_LIST[1451]]: 'The sampling step must be a multiple of the integration step. Check the project settings.',
    [CODE_LIST[1500]]: 'The scheme contains blocks that are not available for use under the tariff:',
    [CODE_LIST[6502]]: 'MDCore solver errors',
    [CODE_LIST[8002]]: 'Successful Selector closing',
    [CODE_LIST[3004]]: 'Error inserting example. Example already exists',
    [CODE_LIST[1503]]: 'The project uses modules that are currently occupied.',
};
