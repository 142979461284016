import { FC, memo, useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Handle } from 'reactflow';

import { sanitizeHTML, unicodeToHtml } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { ElemParams, TElement, WorkspaceModes } from '@repeat/models';
import { elementsSelectors, workspaceSelectors } from '@repeat/store';
import { uiColors } from '@repeat/ui-kit';

import { IHandleViewData } from '../../hooks/useInitNodeViewData';
import { useValidConnection } from '../../hooks/useValidConnection';
import { SHandleFakeTooltip, SHandleTooltip, SHandleWrapper } from '../SComponents';

interface IHandle {
    elemParams: ElemParams[];
    index: number;
    handleViewData: IHandleViewData;
    data: TElement;
    changedValues: { rotation: number };
}

interface IHandleTooltip {
    children: React.ReactNode;
    rects: DOMRect | null;
    type: string;
    position: string;
}

export const HandleTooltip: FC<IHandleTooltip> = memo(({ children, rects, type, position }) => {
    const ref = useRef(null);

    if (rects !== null) {
        const { top, left, width } = rects;
        const topStyles = {
            left: `${left + 8}px`,
            ...(position === 'right' && { left: `${left + 10}px` }),
            ...(position === 'left' && { left: `${left - width}px` }),
            ...(type === 'input' && { top: `${top - 16}px` }),
            ...(type === 'output' && { top: `${top + 16}px` }),
            ...(type === 'input' && position === 'bottom' && { top: `${top + 16}px` }),
            ...(type === 'input' && position === 'left' && { left: `${left - width / 2 - 8}px` }),
            ...(type === 'output' && position === 'right' && { top: `${top - 16}px` }),
            ...(type === 'output' && position === 'bottom' && { top: `${top + 8}px` }),
        };

        return createPortal(
            <SHandleTooltip
                ref={ref}
                style={{ ...topStyles }}
                onMouseLeave={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
                onMouseOut={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {children}
            </SHandleTooltip>,
            document.body
        );
    }
    return null;
});

export const ElementHandle: FC<IHandle> = memo(({ elemParams, index, handleViewData, data, changedValues }) => {
    const elementsMain = useAppSelector(elementsSelectors.getElements);

    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const elementsGroup = useAppSelector(workspaceSelectors.elementsGroup);
    const elements = workspaceMode === WorkspaceModes.GROUP ? elementsGroup : elementsMain;
    const [rects, setRects] = useState<DOMRect | null>(null);
    const handleHover = (rects: DOMRect | null) => setRects(rects);

    const currentParameter = elemParams.find((item) => item.name === handleViewData.meta.name);

    const tooltipText = currentParameter
        ? `
        ${Object.keys(currentParameter).includes('description') ? `${currentParameter.description}` : ''}${
              currentParameter.unit !== '' ? ', ' : ''
          }
        ${Object.keys(currentParameter).includes('unit') ? `${currentParameter.unit}` : ''}
    `
        : '';
    const id = `${data.id}-${handleViewData.id}-${handleViewData.meta.name}`;
    const connectedHandleStyle = {
        backgroundColor: `${uiColors.dark}`,
    };
    const isValidConnection = useValidConnection(elements);
    const isConnectable = useCallback(
        (id: string, name: string) => {
            const sourceElement = elements.find((el) => el.data.id === data.id);
            const sourceElementCurrentHandleIsConnected = sourceElement?.data.availablePorts.find(
                (handle) => handle.name === name
            )?.isConnected;
            return !sourceElementCurrentHandleIsConnected;
        },
        [elements]
    );
    const isHandleConnectable = isConnectable(handleViewData.id, handleViewData.meta.name);

    return (
        <SHandleWrapper devMode={false} rotation={0} key={`${data.picId}-${data.id}-${index}-vars`}>
            <Handle
                onMouseEnter={(event) => {
                    const target = event.target as HTMLElement;
                    const fakeTooltip = target.querySelector('[data-fake="true"]');
                    if (target) {
                        const options = Object.assign({ left: event.clientX, top: event.clientY });
                        if (fakeTooltip !== null) {
                            options.width = fakeTooltip.getBoundingClientRect().width;
                        }
                        handleHover(options);
                    }
                }}
                onMouseLeave={() => {
                    handleHover(null);
                }}
                data-position={handleViewData.position}
                data-rotation={changedValues.rotation}
                data-type={handleViewData.meta.type}
                data-name={'connection'}
                type={handleViewData.type}
                position={handleViewData.position}
                style={
                    handleViewData.meta.isConnected
                        ? { ...handleViewData.style, ...connectedHandleStyle }
                        : { ...handleViewData.style }
                }
                id={id}
                isValidConnection={(connection) => {
                    return isValidConnection(connection, {
                        ...handleViewData,
                        id: `${data.id}-${handleViewData.id}-${handleViewData.meta.name}`,
                    });
                }}
                isConnectable={isHandleConnectable}
                isConnectableStart={
                    isHandleConnectable &&
                    workspaceMode !== WorkspaceModes.SUBMODEL &&
                    workspaceMode !== WorkspaceModes.PREVIEW &&
                    workspaceMode !== WorkspaceModes.DEMO
                }
                isConnectableEnd={isHandleConnectable}
            >
                <SHandleFakeTooltip data-fake={true}>
                    {tooltipText && tooltipText.replace(/\s/, '').trim()}
                </SHandleFakeTooltip>
            </Handle>
            <HandleTooltip type={handleViewData.meta.type} position={handleViewData.position} rects={rects}>
                {tooltipText && (
                    <span dangerouslySetInnerHTML={sanitizeHTML(unicodeToHtml(tooltipText.replace(/\s/, '').trim()))} />
                )}
            </HandleTooltip>
        </SHandleWrapper>
    );
});
