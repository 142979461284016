import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Tab, TabList, Tabs } from 'react-tabs';

import { Editor, loader } from '@monaco-editor/react';
import { editor } from 'monaco-editor';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ElemProps, TSchemaNode } from '@repeat/models';
import { appUserSelectors, workspaceActions, workspaceSelectors } from '@repeat/store';
import { Locales, TranslationKey } from '@repeat/translations';
import { uiColors } from '@repeat/ui-kit';

import { SCodeEditorError, SCodeEditorWrapper } from './SCodeEditor';
import { messages } from './translation';

interface ICodeEditorConfig {
    paths?: {
        vs: string;
    };
    'vs/nls'?: {
        availableLanguages?: object;
    };
}

export enum JythonScriptTypes {
    INIT = 'jython_precode',
    BODY = 'jython_code',
    UNINIT = 'jython_postcode',
}

export type TJythonScriptType = JythonScriptTypes.INIT | JythonScriptTypes.BODY | JythonScriptTypes.UNINIT;
type TJythonScript = {
    id: string;
    name: TJythonScriptType;
    language: string;
    value: string;
};
type TJythonScripts = {
    [key in JythonScriptTypes]: TJythonScript;
};

interface ICodeEditorProps {
    elementId: string;
}

export const CodeEditor: FC<ICodeEditorProps> = ({ elementId }) => {
    const { formatMessage, locale } = useIntl();
    const theme = useAppSelector(appUserSelectors.currentUserTheme);
    const dispatch = useAppDispatch();

    const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);

    const [scriptName, setScriptName] = useState<TJythonScriptType>(JythonScriptTypes.INIT);

    const node: TSchemaNode | null | undefined = useAppSelector(workspaceSelectors.getSchemaNodeById(elementId));

    const getJythonScripts = useCallback(() => {
        return {
            [JythonScriptTypes.INIT]: {
                id: `${elementId}-${JythonScriptTypes.INIT}`,
                name: JythonScriptTypes.INIT,
                language: 'python',
                value: node
                    ? (node.data.elemProps.find((property: ElemProps) => property.name === JythonScriptTypes.INIT)
                          ?.value as string) || ''
                    : '',
            },
            [JythonScriptTypes.BODY]: {
                id: `${elementId}-${JythonScriptTypes.BODY}`,
                name: JythonScriptTypes.BODY,
                language: 'python',
                value: node
                    ? (node.data.elemProps.find((property: ElemProps) => property.name === JythonScriptTypes.BODY)
                          ?.value as string) || ''
                    : '',
            },
            [JythonScriptTypes.UNINIT]: {
                id: `${elementId}-${JythonScriptTypes.UNINIT}`,
                name: JythonScriptTypes.UNINIT,
                language: 'python',
                value: node
                    ? (node.data.elemProps.find((property: ElemProps) => property.name === JythonScriptTypes.UNINIT)
                          ?.value as string) || ''
                    : '',
            },
        };
    }, [elementId]);

    const [scripts, setScripts] = useState<TJythonScripts>(getJythonScripts());

    const script = scripts[scriptName];

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    }, [script.name, elementId]);

    let config: ICodeEditorConfig = {
        paths: {
            vs: '/assets/vs',
        },
    };
    if (locale !== Locales.ENGLISH) {
        config = {
            ...config,
            'vs/nls': {
                availableLanguages: {
                    '*': locale,
                },
            },
        };
    }

    loader.config(config);

    const handleChange = useCallback((name: string, value: string | undefined) => {
        const updatedProperty = {
            [name]: value ?? '',
        };

        dispatch(workspaceActions.setElementPropertiesValues({ id: Number(elementId), elemProps: updatedProperty }));
    }, []);

    return (
        <SCodeEditorWrapper>
            <Tabs>
                <TabList style={{ display: 'flex', margin: 0 }}>
                    <Tab
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            color: `${script.name === JythonScriptTypes.INIT ? uiColors.mainBlue : uiColors.darkGrey}`,
                        }}
                        onClick={() => setScriptName(JythonScriptTypes.INIT)}
                    >
                        {formatMessage(messages[TranslationKey.JYTHON_CODE_TAB_INIT])}
                    </Tab>
                    <Tab
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            color: `${script.name === JythonScriptTypes.BODY ? uiColors.mainBlue : uiColors.darkGrey}`,
                        }}
                        onClick={() => setScriptName(JythonScriptTypes.BODY)}
                    >
                        {formatMessage(messages[TranslationKey.JYTHON_CODE_TAB_BODY])}
                    </Tab>
                    <Tab
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            color: `${
                                script.name === JythonScriptTypes.UNINIT ? uiColors.mainBlue : uiColors.darkGrey
                            }`,
                        }}
                        onClick={() => setScriptName(JythonScriptTypes.UNINIT)}
                    >
                        {formatMessage(messages[TranslationKey.JYTHON_CODE_TAB_UNINIT])}
                    </Tab>
                </TabList>
            </Tabs>
            <Editor
                path={script.id}
                defaultLanguage={script.language}
                defaultValue={script.value}
                theme={`vs-${theme}`}
                onChange={(value) => handleChange(script.name, value)}
                onMount={(editor) => (editorRef.current = editor)}
            />
            {!node && (
                <SCodeEditorError>
                    {formatMessage(messages[TranslationKey.CODE_EDITOR_NODE_NOT_FOUND])}
                </SCodeEditorError>
            )}
        </SCodeEditorWrapper>
    );
};
