import { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ProjectHistoryEmpty } from '@components/ProjectHistory/ProjectHistoryEmpty';
import { ProjectHistoryList } from '@components/ProjectHistory/ProjectHistoryList';
import { messages } from '@components/ProjectHistory/translation';
import { IProjectHistoryItem } from '@components/ProjectHistory/types';
import { v4 as uuidv4 } from 'uuid';

import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { Statuses, WorkspaceModes } from '@repeat/models';
import {
    changeProjectVersionsComment,
    getProjectVersions,
    previewProjectVersion,
    setProjectVersion,
    workspaceSelectors,
} from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { LoaderRepeat } from '@repeat/ui-kit';

const prepareData = (versions: IProjectHistoryItem[]) =>
    versions.map((version) => ({
        ...version,
        uuid: uuidv4(),
        active: false,
    }));

export const ProjectHistory: FC<{ scrollRef: MutableRefObject<any> }> = ({ scrollRef }) => {
    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);

    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();

    const [isInitialized, setIsInitialized] = useState(false);

    const { projectId } = useProjectId();

    const { items, status, total } = useAppSelector((state) => {
        return state.projects.versions;
    });
    const [updatedData, setUpdatedData] = useState(prepareData(items as IProjectHistoryItem[]));

    useEffect(() => {
        if (status === Statuses.IDLE || !isInitialized) {
            handleGetProjectVersions();
        }
    }, []);

    useEffect(() => {
        setUpdatedData(prepareData(items as IProjectHistoryItem[]));
    }, [items]);

    const handleGetProjectVersions = useCallback(async (offset?: number, count?: number) => {
        if (projectId) {
            const newItems = await dispatch(getProjectVersions(projectId, offset, count));
            if (newItems && !isInitialized) {
                setIsInitialized(true);
            }
            return newItems;
        }
        return [];
    }, []);

    const handleOpenVersion = useCallback(async (id: number, date: string) => {
        if (workspaceMode !== WorkspaceModes.DEMO) {
            await dispatch(setProjectVersion(projectId as number, date, id));
            await handleGetProjectVersions();
        }
    }, []);

    const handlePreviewVersion = useCallback(async (id: number, date: string, uuid: string) => {
        if (workspaceMode !== WorkspaceModes.DEMO) {
            await dispatch(previewProjectVersion(projectId as number, date, id));
            setUpdatedData((state) => state.map((item) => ({ ...item, active: item.uuid === uuid })));
        }
    }, []);

    const handleDeleteVersion = useCallback((id: number, date: string) => {
        // eslint-disable-next-line
        console.log('delete ', id)
    }, []);

    const handleEditComment = useCallback(async (id: number, date: string, comment: string) => {
        if (workspaceMode !== WorkspaceModes.DEMO) {
            await dispatch(changeProjectVersionsComment(projectId as number, id, comment));
            await handleGetProjectVersions();
        }
    }, []);

    if (status === Statuses.LOADING || status === Statuses.IDLE) {
        return <LoaderRepeat />;
    }

    if (status === Statuses.SUCCEEDED && items.length < 1) {
        return <ProjectHistoryEmpty text={formatMessage(messages[TranslationKey.EMPTY_PROJECT_HISTORY])} />;
    }

    if (status === Statuses.FAILED && items.length < 1) {
        return <ProjectHistoryEmpty text={formatMessage(messages[TranslationKey.ERROR_PROJECT_HISTORY])} />;
    }

    return (
        <ProjectHistoryList
            data={updatedData}
            total={total || 10}
            scrollRef={scrollRef}
            onGetData={handleGetProjectVersions}
            onOpen={handleOpenVersion}
            onDelete={handleDeleteVersion}
            onPreview={handlePreviewVersion}
            onEditComment={handleEditComment}
        />
    );
};
