import styled from 'styled-components';

import { border, pxToRem, radius, uiColors } from '@repeat/ui-kit';

export const SProjectHistoryItemComment = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: ${pxToRem(14)};
    width: 100%;
    overflow: hidden;
`;

export const SProjectHistoryItemBadge = styled.div`
    align-items: center;
    background: var(--ui-border-hover);
    border-radius: 15px;
    color: var(--ui-text);
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    margin-right: ${pxToRem(8)};
    padding: ${pxToRem(4)} ${pxToRem(8)};
`;

export const SProjectHistoryItemDate = styled.span`
    font-size: ${pxToRem(14)};
    color: var(--ui-heading);
`;

export const SProjectHistoryItemTitle = styled.div`
    align-items: center;
    display: flex;
    font-size: ${pxToRem(12)};
    margin-bottom: ${pxToRem(8)};
`;

export const SProjectHistoryItemBody = styled.div`
    > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    textarea {
        margin: 0 !important;
        overflow: hidden;
        word-break: break-all;
    }

    > div:first-child {
        margin-bottom: ${pxToRem(8)};

        > div {
            margin: 0 !important;
        }
    }

    p {
        color: var(--ui-text);
    }

    [data-name='project-history-item-comment-empty'] {
        color: var(--ui-label);
    }
`;

export const SProjectHistoryItemControl = styled.div`
    position: absolute;
    top: 0;
    right: ${pxToRem(4)};
`;

export const SProjectHistoryItem = styled.div<{ active: boolean }>`
    border: ${border.default};
    border-radius: ${radius.default};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: ${pxToRem(8)};
    padding: ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)};

    ${({ active }) => active && `border-color: ${uiColors.mainBlue}`};

    i svg {
        min-width: ${pxToRem(20)};
    }
`;

export const SProjectHistoryList = styled.div`\
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: calc(100vh - 277px);
`;
