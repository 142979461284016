import { RootState } from '../store';

export const isAuth = (state: RootState) => state.appUser.isAuth;
export const isOnline = (state: RootState) => state.appUser.isOnline;
export const currentUser = (state: RootState) => state.appUser.currentUser;
export const currentUserLogin = (state: RootState) => currentUser(state)?.login;
export const currentUserEmail = (state: RootState) => currentUser(state)?.email;
export const currentUserName = (state: RootState) => currentUser(state)?.userName;
export const currentUserMode = (state: RootState) => currentUser(state)?.settings?.isEngineer;
export const currentUserTheme = (state: RootState) => currentUser(state)?.settings?.theme;
export const currentUserIconMode = (state: RootState) => currentUser(state)?.settings?.iconType ?? 'new';
export const currentUserLocale = (state: RootState) => currentUser(state)?.settings?.locale;
export const currentUserPermissions = (state: RootState) => currentUser(state)?.permissions;
