export const libraryElementImageSrc = (
    assetsPath: string,
    solverType: string,
    libraryType: string,
    name: string,
    iconType: string,
    hash?: string
): string => {
    const extension = 'svg';

    if (solverType === 'usds') {
        return `${assetsPath}/${libraryType.toLowerCase()}/images/${name}.${extension}?${hash ? `?${hash}` : ''}`;
    }
    if (iconType === 'new') {
        return `${assetsPath}/library/images/new-icons/${name}.${extension}?${hash ? `?${hash}` : ''}`;
    }

    return `${assetsPath}/library/images/${name}.${extension}?${hash}?${hash ? `?${hash}` : ''}`;
};
