import { Connection, Node } from 'reactflow';

import { abilitiesRulesMap as ABILITIES_RULES_MAP } from '@repeat/common-ability';
import { ELEMENTS_TYPE, METER_ELEMENTS_TYPES, extractNumbers } from '@repeat/constants';
import {
    ILibraryItem,
    ILibraryItemValidationRule,
    TLivePermissions,
    TSchemaConnection,
    TSchemaNode,
    TUserPermission,
} from '@repeat/models';
import { TranslationKey } from '@repeat/translations';

// @todo make separate function for item validation and improve type of rules
// @todo pass element name to error
export const validateElement = (item: ILibraryItem, nodes: TSchemaNode[]): void => {
    if (!item?.rules || item.rules.length === 0) {
        return;
    }

    item.rules.forEach((rule: ILibraryItemValidationRule) => {
        if (rule.type === 'maxCount' && rule?.value && rule.value > 0) {
            const sameNodes = nodes.filter((node: TSchemaNode) => node.data.type === item.type);
            if (sameNodes.length >= rule.value) {
                throw new Error(TranslationKey.RULES_ELEMENT_MAX_COUNT);
            }
        }
    });
};

export const determineType = (draggableLibraryItemType: string) => {
    if (['image', 'text', 'fsm-state', 'fsm-start'].includes(draggableLibraryItemType)) {
        return ELEMENTS_TYPE[draggableLibraryItemType];
    }
    return 'element';
};

export const findMissedNumbers = (arr: number[]) => {
    const missedNumbers = [];
    const sortedArr = arr.sort((a, b) => a - b);

    for (let i = 1; i <= sortedArr[sortedArr.length - 1]; i++) {
        if (!arr.includes(i)) {
            missedNumbers.push(i);
        }
    }

    return missedNumbers;
};

export const calculateIndex = (elements: TSchemaNode[], item: ILibraryItem) => {
    const allIndexes = elements.map((el) => extractNumbers(el.data.index));
    const missedIndexes = findMissedNumbers(allIndexes);
    const itemIndex = item?.index ?? '';

    if (missedIndexes.length !== 0) {
        const numberIndex = missedIndexes[0];
        return `${itemIndex}${numberIndex}`;
    } else {
        return `${itemIndex}${elements.length + 1}`;
    }
};

export const mapElementsToNodes = (elements: TSchemaNode[], defaultSelectedNodes: string[]): Node[] => {
    return elements.map((element: TSchemaNode) => ({
        id: element.id,
        data: { ...element.data },
        position: element.position,
        rotation: element.rotation,
        width: element.width,
        height: element.height,
        type: element.type,
        selected: defaultSelectedNodes.includes(element.id),
    }));
};

export const mapWiresToConnections = (
    wires: TSchemaConnection[],
    defaultSelectedConnections: string[]
): Connection[] => {
    return wires.map((wire: TSchemaConnection) => ({
        id: wire.id,
        source: wire.source,
        sourceHandle: wire.sourceHandle,
        target: wire.target,
        targetHandle: wire.targetHandle,
        type: wire.type,
        selected: defaultSelectedConnections.includes(wire.id),
        data: wire.data,
    }));
};

const getLibraryBlocksPermissions = (items: ILibraryItem[]) => {
    let permissions: TUserPermission[] = [];
    const permissionKeys = Object.keys(ABILITIES_RULES_MAP) as TUserPermission[];

    items.forEach((item) => {
        const nodePermission = permissionKeys.find((key) =>
            ABILITIES_RULES_MAP[key as keyof typeof ABILITIES_RULES_MAP].libraries.includes(item.library)
        );

        if (nodePermission) {
            permissions = [...permissions, nodePermission];
        }

        if (item.modules) {
            const modules = item.modules;
            Object.keys(modules).forEach((permission: TUserPermission) => {
                const moduleBlocksCount = modules[permission];
                if (moduleBlocksCount > 0 && !permissions.includes(permission)) {
                    permissions = [...permissions, permission];
                }
            });
        }
    });

    return permissions;
};

const canUseBlocks = (items: ILibraryItem[], livePermissions: TLivePermissions) => {
    const permissions = getLibraryBlocksPermissions(items);

    let availablePermissions: string[] = [];
    Object.keys(livePermissions).forEach((permission: TUserPermission) => {
        if (livePermissions[permission]) {
            availablePermissions = [...availablePermissions, permission];
        }
    });

    return permissions.every((p) => availablePermissions.includes(p));
};

export const validateElementPermissions = (items: ILibraryItem[], livePermissions: TLivePermissions) => {
    const canUse = canUseBlocks(items, livePermissions);
    if (!canUse) {
        throw new Error(TranslationKey.ELEMENT_LIVE_PERMISSIONS_ADD_ERROR);
    }
};

export const findConnectedMeters = (
    itemsForSubmodel: { elements: TSchemaNode[]; wires: TSchemaConnection[] },
    elements: TSchemaNode[]
) => {
    const metersTypes = METER_ELEMENTS_TYPES;
    const meterElements = elements.filter((el) => metersTypes.includes(el.data.type));
    const elementsForSubmodelIds = itemsForSubmodel.elements.map((el) => el.id);

    return meterElements.filter((el) => {
        const parentIDValue = el.data.elemProps.find((prop) => prop.name === 'parentID')?.value.toString() || '';
        return elementsForSubmodelIds.includes(parentIDValue) && !elementsForSubmodelIds.includes(el.id);
    });
};
