import React, { startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { IVariables, WorkspaceModes } from '@repeat/models';
import { workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Icon } from '@repeat/ui-kit';

import { SVariableAddRow, SVariableAddRowButton, SVariables, SVariablesWrapper, SVariableTable } from './SVariables';
import VariableAddItem from './VariableAddItem';
import { VariableItem } from './VariablesItem';
import { messages } from './translation';

interface IVariablesItem {
    index: number;
    name: string;
    value: string;
}
export const Variables = () => {
    const intl = useIntl();

    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);

    const dispatch = useAppDispatch();

    const { globalVariables } = useAppSelector((state) => state.workspace.settings);

    const initialState: IVariablesItem[] = useMemo(() => {
        const keys = globalVariables ? Object.keys(globalVariables) : [];
        return keys.map((key, index) => ({ index, name: key, value: globalVariables ? globalVariables[key] : '' }));
    }, [globalVariables]);

    const [variables, setVariables] = useState<IVariablesItem[]>(initialState);

    const handleAddVariable = useCallback(() => {
        handleScroll();
        startTransition(() => {
            setVariables([...variables, { index: variables.length + 1, name: '', value: '' }]);
        });
    }, [variables, globalVariables]);

    const handleChangeKey = useCallback(
        (index: number, name: string) => {
            startTransition(() => {
                const newVariables = variables.map((variable) => {
                    if (variable.index === index) {
                        return { ...variable, name };
                    }
                    return variable;
                });
                setVariables([...newVariables]);
            });
        },
        [variables]
    );

    const handleChangeValue = useCallback(
        (index: number, value: string) => {
            startTransition(() => {
                const newVariables = variables.map((variable) => {
                    if (variable.index === index) {
                        return { ...variable, value };
                    }
                    return variable;
                });
                setVariables([...newVariables]);
            });
        },
        [variables]
    );

    const handleDeleteVariable = useCallback(
        (index: number) => {
            const newVariables = variables.filter((variable) => variable.index !== index);
            startTransition(() => {
                setVariables(newVariables);
            });
        },
        [variables]
    );

    const handleSaveVariables = (variables: IVariablesItem[]) => {
        const newVariables: IVariables = {};
        variables.map((variable) => {
            newVariables[variable.name] = variable.value;
        });
        startTransition(() => {
            dispatch(workspaceActions.setGlobalVariables(newVariables));
        });
    };

    const handleScroll = useCallback(() => {
        const target = document.querySelector('#variable-items-table') as HTMLDivElement;
        target.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }, []);

    const handleAddVariableByLink = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            handleAddVariable();
        },
        [variables]
    );

    useEffect(() => {
        handleSaveVariables(variables);
    }, [variables]);

    return (
        <SVariablesWrapper id={'variable-items-table'}>
            <SVariables>
                <SVariableTable>
                    <thead>
                        <tr>
                            <th>{intl.formatMessage(messages[TranslationKey.VARIABLE])}</th>
                            <th>{intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_POINT_VALUE])}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {variables.map((variable, index) => {
                            return (
                                <VariableItem
                                    {...variable}
                                    key={`variable-item-row-${index}`}
                                    onChangeKey={handleChangeKey}
                                    onChangeValue={handleChangeValue}
                                    onDelete={handleDeleteVariable}
                                    variables={globalVariables || {}}
                                />
                            );
                        })}
                        {variables.length === 0 && (
                            <SVariableAddRow>
                                <td rowSpan={3}>
                                    {workspaceMode !== WorkspaceModes.PREVIEW &&
                                        workspaceMode !== WorkspaceModes.DEMO && (
                                            <SVariableAddRowButton>
                                                <Icon fill={'var(--ui-link-secondary)'} name={'plus'} />
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <a role='button' href='' onClick={handleAddVariableByLink}>
                                                    {intl.formatMessage(messages[TranslationKey.ADD])}
                                                </a>
                                            </SVariableAddRowButton>
                                        )}
                                </td>
                            </SVariableAddRow>
                        )}
                    </tbody>
                </SVariableTable>
                {workspaceMode !== WorkspaceModes.PREVIEW &&
                    workspaceMode !== WorkspaceModes.DEMO &&
                    variables.length > 0 && (
                        <VariableAddItem
                            appendText={intl.formatMessage(messages[TranslationKey.ADD])}
                            onClick={handleAddVariable}
                        />
                    )}
            </SVariables>
        </SVariablesWrapper>
    );
};
